import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideDomainAdminOffcanvas } from '../../../features/modal/offcanvasSlice'

const DomainsAdminOffcanvas = ({
    isDomainAdminVisible,
    hideDomainAdminOffcanvas
}) => {
    return (
        <Offcanvas show={isDomainAdminVisible} placement={'end'} backdrop={true} scroll={true} id="appCapsule">
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0 p-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Domain Admin</strong>
                    </div>
                    <a href="#!" className="close-sidebar-button" onClick={hideDomainAdminOffcanvas}>
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div className="section full mb-2">
                    Domain Admin
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isDomainAdminVisible
    } = offcanvas
    return {
        isDomainAdminVisible
    }
}
  
const mapDispatchToProps = {
    hideDomainAdminOffcanvas
}

export default connect(mapStateToProps,mapDispatchToProps)(DomainsAdminOffcanvas)