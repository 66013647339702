import React, { useEffect, useState } from 'react'
import {
  setWebsiteData
} from '../../../features/websiteData/websiteDataSlice'
import axios from '../../../AxiosConfig'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../../features/auth/authSlice'
import jwt_decode from 'jwt-decode'
import config from '../../../config'
import Preview from '../../Elements/Modals/Preview'
import AppToast from '../../Elements/Notification/AppToast'
import DeleteDialogueBox from '../../Elements/Delete/DeleteDialogueBox'
import ConnectDomainModal from '../../Elements/Modals/connectDomainModal'
// import Logo from '../../images/webweb.svg'
import { updateDeviceSize } from '../../../features/utilities/utilitiesSlice'
import { setShowJSONModal, setSideBarMenuShow } from '../../../features/modal/offcanvasSlice'
import { showAppearance, showSiteSettingOffcanvas, showDeleteModal, showPageSetting, setResponsiveIframe, showNavigationOffcanvas, showDomainSettingOffcanvas, showEmailSettingOffcanvas, showSidebarToggle } from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import ConfirmationModal from '../../Elements/Modals/ConfirmationModal'
import HtmlEditor from '../../Elements/Modals/HtmlEditor'
import CategoryManage from '../Rightpanel/CategoryManage'
import DomainsAdminOffcanvas from '../Rightpanel/DomainsAdminOffcanvas'
import Ogoffcanvas from '../Rightpanel/ogoffcanvas'
import { Button } from 'react-bootstrap'

const Header = ({
  setWebsiteData,
  selectedDomain,
  appToastVisible,
  appToastMessage,
  appToastBackground,
  appToastIcon,
  appToastPosition,
  isDeleteVisible,
  deleteMessage,
  triggerMessage,
  deleteID,
  logout,
  updateDeviceSize,
  showAppearance,
  showSiteSettingOffcanvas,
  showDeleteModal,
  showPageSetting,
  domains,
  showAppToast,
  showNavigationOffcanvas,
  setResponsiveIframe,
  showDomainSettingOffcanvas,
  showEmailSettingOffcanvas,
  setShowJSONModal,
  showSidebarToggle,
  sidebarToggle,
  setSideBarMenuShow
}) => {
  const navigate = useNavigate()
  const [sitemapList, setSitemapList] = useState([])
  const [device, setDevice] = useState('')
  const [currentPageName, setCurrentPageName] = useState('')
  const [boostDom, setBoostDom] = useState(false)
  const [buildDom, setBuildDom] = useState(false)
  const [searchDomainDom, setSearchDomainDom] = useState(false)
  const [analyticsDom, setAnalyticsDom] = useState(false)
  const [supportDom, setSupportDom] = useState(false)
  const [homeDom, setHomeDom] = useState(false)
  const [manageEmailDom, setManageEmailDom] = useState(false)
  const [formDom, setFormDom] = useState(false)
  const [websitesDom, setWebsitesDom] = useState(false)
  const [domainsDom, setDomainsDom] = useState(false)
  const [dnsDom, setDnsDom] = useState(false)
  const [categoriesDom, setCategoriesDom] = useState(false)
  const [dsettingDom, setDSettingDom] = useState(false)
  const [mediaGalleryDom, setMediaGalleryDom] = useState(false)
  const [chatBotDom, setChatBotDom] = useState(false)
  const [emailMarketingDom, setEmailMarketingDom] = useState(false)
  const [searchEngineOptimizationDom, setSearchEngineOptimizationDom] = useState(false)
  const [pluginsDom, setPluginsDom] = useState(false)
  const [socialMediaDom, setSocialMediaDom] = useState(false)
  const [webmailDom, setWebmailDom] = useState(false)
  const [liveChatDom, setLiveChatDom] = useState(false)
  const [addTemplateDom, setAddTemplateDom] = useState(false)
  const [blockDetailDom, setBlockDetailDom] = useState(false)
  const [emailsControlDom, setEmailsControlDom] = useState(false)
  const [defaultDom, setDefaultDom] = useState(false)
  const [domainList, setDomainList] = useState([])
  const [launchValue, setLaunchValue] = useState()
  const [role, setRole] = useState()
  const [overviewDom, setOverviewDom] = useState(false)
  const domainName = selectedDomain

  const search = useLocation().search
  const type = new URLSearchParams(search).get('type')
  const report = new URLSearchParams(search).get('report')
  let url = ''
  url = new URLSearchParams(search).get('url')
  if (url === null) {
    url = localStorage.getItem('previousPage')
  } else {
    url = new URLSearchParams(search).get('url')
    localStorage.setItem('previousPage', url)
  }

  const token = localStorage.getItem('token')
  const tokenData = (token) ? jwt_decode(token) : false
  // console.log(tokenData)
  const roleID = tokenData.userInfo.RoleID
  const selectedCategoryID = tokenData.SelectedDomain.ID

  const pageUrl = window.location.href
  useEffect(() => {
    setRole(roleID)
    if (pageUrl.includes('boost')) {
      setBoostDom(true)
    } else if (pageUrl.includes('build')) {
      setBuildDom(true)
    } else if (pageUrl.includes('visitorReport')) {
      setAnalyticsDom(true)
    } else if (pageUrl.includes('support')) {
      setSupportDom(true)
    } else if (pageUrl.includes('home')) {
      setHomeDom(true)
    } else if (pageUrl.includes('manageEmail')) {
      setManageEmailDom(true)
    } else if (pageUrl.includes('forms') || pageUrl.includes('formDetail')) {
      setFormDom(true)
      setDefaultDom(false)
    } else if (pageUrl.includes('searchDomain')) {
      setSearchDomainDom(true)
    } else if (pageUrl.includes('overview')) {
      setOverviewDom(true)
    } else if (pageUrl.includes('websites')) {
      setWebsitesDom(true)
    } else if (pageUrl.includes('domains')) {
      setDomainsDom(true)
    } else if (pageUrl.includes('dns')) {
      setDnsDom(true)
    } else if (pageUrl.includes('EControl')) {
      setEmailsControlDom(true)
    } else if (pageUrl.includes('categories')) {
      setCategoriesDom(true)
    } else if (pageUrl.includes('DSetting')) {
      setDSettingDom(true)
    } else if (pageUrl.includes('mediaGallery')) {
      setMediaGalleryDom(true)
    } else if (pageUrl.includes('chatBot')) {
      setChatBotDom(true)
    } else if (pageUrl.includes('socialMedia')) {
      setSocialMediaDom(true)
    } else if (pageUrl.includes('webmail')) {
      setWebmailDom(true)
    } else if (pageUrl.includes('liveChat')) {
      setLiveChatDom(true)
    } else if (pageUrl.includes('addTemplate')) {
      setAddTemplateDom(true)
    } else if (pageUrl.includes('blockDetail')) {
      setBlockDetailDom(true)
    } else if (pageUrl.includes('appinfo')) {
      setSupportDom(true)
    } else if (pageUrl.includes('emailMarketing')) {
      setEmailMarketingDom(true);
    } else if (pageUrl.includes('searchEngineOptimization')) {
      setSearchEngineOptimizationDom(true);
    } else if (pageUrl.includes('plugins')) {
      setPluginsDom(true);
    } else {
      setDefaultDom(true)
    }

  }, [search])

  const handleLogout = () => {
    logout()
    setTimeout(() => {
      navigate('/')
    }, 1000)
  }

  const fetchWebsiteJSON = () => {
    const webDoc = localStorage.getItem('website')
    const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
    if (res) {
      return res
    } else {
      return false
    }
  }

  const websiteJSON = fetchWebsiteJSON()

  // console.log(websiteJSON)
  let sitemapData;
  let activePage;
  let websiteDataAppearance;
  if (websiteJSON !== undefined && websiteJSON !== false) {
    sitemapData = websiteJSON.appearance.navigation.header
    activePage = websiteJSON.appearance.properties.defaultPage
    websiteDataAppearance = websiteJSON.appearance
  }

  const getSitemapList = (sitemap) => {
    let list = []
    if (tokenData.userInfo.RoleID != 'Moderator') {
      sitemap && sitemap.map((value) => {
        if (!value.hasOwnProperty('children')) {
          list.push(value)
        } else {
          value.children.map(val => {
            list.push(val)
          })
        }
      })
      setSitemapList(list)
    } else {
      axios.get('/page/smartPages/' + selectedCategoryID).then(response => {
        // console.log(response)
        if (response.data.data.length == 0) {
        } else {
          for (let i = 0; i < response.data.data.length; i++) {
            // console.log(response.data.data[i])
            response.data.data[i].StructureConfig = JSON.parse(response.data.data[i].StructureConfig)
            // console.log(response.data.data[i].StructureConfig)
            list[i] = {}
            list[i]['page'] = response.data.data[i].StructureConfig.appearance.navigation.header[0].page
            list[i]['title'] = response.data.data[i].StructureConfig.appearance.navigation.header[0].title
            list[i]['id'] = response.data.data[i].ID
          }
          setSitemapList(list)
        }
      })
    }
  }

  let pageId = ''

  const [logo, setAppLogo] = useState("")

  const getPageName = () => {
    websiteDataAppearance && websiteDataAppearance.pages && Object.keys(websiteDataAppearance.pages).map((value, key) => {
      if (websiteDataAppearance.pages[value].url === url) {
        pageId = value
        websiteDataAppearance.navigation.header.map((v, k) => {
          if (v.hasOwnProperty('children')) {
            websiteDataAppearance.navigation.header[k].children.map((v1, k1) => {
              if (v1.page === pageId) {
                setCurrentPageName(v1.title)
              }
            })
          } else {
            if (v.page === pageId) {
              setCurrentPageName(v.title)
            }
          }
        })
      }
    })
  }

  useEffect(() => {
    setAppLogo(localStorage.getItem('AppLogo'))
    getPageName()
    getSitemapList(sitemapData)
    if (url !== undefined && url !== null) {
      if (sitemapList.length > 0) {
        {
          sitemapList.map(value => {
            if (value.url === url) {
              setCurrentPageName(value.title)
            }
          })
        }
      } else {
        const sitemapData = websiteJSON.appearance ? websiteJSON.appearance.navigation.header : []
        let list = []
        sitemapData.map((value) => {
          if (!value.hasOwnProperty('children')) {
            list.push(value)
          } else {
            value.children.map(val => {
              list.push(val)
            })
          }
        })
        {
          list.map(value => {
            if (value.url === url) {
              setCurrentPageName(value.title)
            }
          })
        }
      }
      // document.getElementById('pageList').value = url
    }
  }, [url])

  const handlePageSwitch = (page, printName, id) => {
    // console.log(page, id)
    if (roleID == 'Moderator') {
      axios.get('/page/smartPages/' + selectedCategoryID).then(response => {
        // console.log(response)
        if (response.data.data.length == 0) {
        } else {
          for (let i = 0; i < response.data.data.length; i++) {
            // console.log(response.data.data[i])
            if (id === response.data.data[i].ID) {
              let categoryPageJson = {}
              let categoryJson = {}
              categoryJson = JSON.parse(response.data.data[i].StructureConfig)
              const pageUrl = categoryJson.appearance.pages[page].url
              categoryPageJson = btoa(unescape(encodeURIComponent(response.data.data[i].StructureConfig)))
              localStorage.setItem('website', categoryPageJson)
              localStorage.setItem('selectedPageID', id)
              setWebsiteData(categoryJson)
              navigate(
                '/build?url=' + pageUrl
              )
            }
          }
        }
      })
    } else {
      const pageUrl = websiteJSON.appearance.pages[page].url
      navigate('/build?url=' + pageUrl)
      setCurrentPageName(printName)
    }
  }

  useEffect(() => {
    updateDeviceSize({ device: device })
  }, [device])

  useEffect(() => {
    if (domains !== undefined && domains.length > 0) {
      setDomainList(domains)
    }
  }, [domains])

  const handleApparancePanel = () => {
    showAppearance({ isAppearanceVisible: true })
  }

  const handleDeletePage = () => {
    showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure you want to delete ' + url + ' page', triggerMessage: 'page', deleteID: url })
  }

  const handlePageSetting = (activeTabName) => {
    showPageSetting({ isPageSettingVisible: true, defaultActiveTab: activeTabName })
  }

  const switchWebsiteDomain = (switchTo, type) => {
    navigate('/load?domain=' + switchTo + '&type=' + type)
  }

  const handleLaunchSite = (e) => {
    e.preventDefault()
    // console.log('Site Launched')
    setLaunchValue(true)
    axios.get('/website/publishWebsite/' + selectedDomain).then(res => {
      // setResponsiveIframe({runPartyParticals: true})
      showAppToast({ toastMessage: 'Your changes updated to your live website', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
      setLaunchValue(false)
    })
  }

  const handleNavigation = () => {
    showNavigationOffcanvas({ isNavigationVisible: true })
  }

  const handleSiteSetting = () => {
    // console.log('logout')
    showSiteSettingOffcanvas({ isSiteSettingVisible: true })
  }

  const handleDomainSetting = () => {
    showDomainSettingOffcanvas({ isDomainSettingVisible: true })
  }

  const handleEmailSetting = () => {
    showEmailSettingOffcanvas({ isEmailSettingVisible: true })
  }

  const showJSONMOdal = () => {
    console.log('sss');
    setShowJSONModal({ JSONModal: true });
  }
  
  const handleSidebarToggle = () => {
    showSidebarToggle({sidebarToggle: !sidebarToggle})
}

const handleManageSite = () => {
  navigate("/build?url=" + websiteDataAppearance.properties.defaultPage)
}

  return (
    <div>
      <AppToast
        message={appToastMessage}
        show={appToastVisible}
        background={appToastBackground}
        icon={appToastIcon}
        position={appToastPosition}
      />
      <div className="appHeader">
        <div className="row w-100 d-flex align-items-center">
          <div className="w-25 d-flex justify-content-start">
            <Button variant="link" className='text-dark' onClick={() => setSideBarMenuShow(true)}>
              <ion-icon name="menu-outline"></ion-icon>
            </Button>
            <a href="#!" className="headerButton">
              <img src='https://webweb.ams3.cdn.digitaloceanspaces.com/webweb.svg' className="img-fluid" alt="Brand" width="45x45" />
            </a>
          </div>
          <div className="w-50 dropdown text-center">
            <div className="form-group boxed">
              <div className="input-wrapper d-flex  justify-content-center">
                <div class="d-none d-sm-flex">
                  <div class="dropdown">
                    <Link to={'/overview'} class="btn btn-outline-primary rounded shadowed me-1 btn-sm">
                      <ion-icon name="home-outline" class="fs-6"></ion-icon>
                      HOME
                    </Link>
                  </div>
                  <div class="dropdown">
                    <a class="btn btn-outline-primary rounded shadowed me-1 btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                      <ion-icon name="earth-outline" class="fs-6"></ion-icon>
                      WEBSITE&nbsp;
                      <ion-icon name="chevron-down-outline" class="m-auto" style={{fontSize:'14px'}}></ion-icon>
                    </a>
                    <div class="dropdown-menu" style={{top: '10px'}}>
                        <a class="dropdown-item" href="javascript:void(0)" onClick={handleManageSite}>Manage Website</a>
                        <Link to="/visitorReport?report=hits" class="dropdown-item">Analytics</Link>
                        <Link to="/DSetting" class="dropdown-item">Domain Setting</Link>
                        <Link to="/manageEmail" class="dropdown-item">Email Accounts</Link>
                        <Link to="/plugins" class="dropdown-item">Plugins</Link>
                    </div>
                  </div>
                  <div class="dropdown">
                    <a class="btn btn-outline-primary rounded shadowed me-1 btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                      <ion-icon name="radio-outline" class="fs-6"></ion-icon>
                      DIGITAL MARKETING&nbsp;
                      <ion-icon name="chevron-down-outline" class="m-auto" style={{fontSize:'14px'}}></ion-icon>
                    </a>
                    <div class="dropdown-menu" style={{top: '10px'}}>
                        <Link to="/searchEngineOptimization" class="dropdown-item">Search Engine Optimization</Link>
                        <Link to="/socialMedia" class="dropdown-item">Social Media Marketing</Link>
                        <Link to="/emailMarketing" class="dropdown-item">Email Marketing</Link>
                    </div>
                  </div>
                  <div class="dropdown">
                    <a class="btn btn-outline-primary rounded shadowed me-1 btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                      <ion-icon name="person-outline" class="fs-6"></ion-icon>
                      CUSTOMERS&nbsp;
                      <ion-icon name="chevron-down-outline" class="m-auto" style={{fontSize:'14px'}}></ion-icon>
                    </a>
                    <div class="dropdown-menu" style={{top: '10px'}}>
                        <Link to="/forms" class="dropdown-item">Form Submissions</Link>
                        <Link to="/chatBot" class="dropdown-item">ChatBot</Link>
                        <Link to="/webmail" class="dropdown-item">Webmail</Link>
                        <Link to="/liveChat" class="dropdown-item">Live Chat</Link>
                    </div>
                  </div>
                </div>
                <div class="d-block d-sm-none">
                  {buildDom &&
                    <h4 className="mb-0 d-flex justify-content-center align-items-center" onClick={handleSidebarToggle}>
                      Edit Site
                      <span className='d-block d-lg-none my-auto pt-1 ms-1'><ion-icon name="menu-outline"></ion-icon></span>
                    </h4>
                  }
                  {boostDom &&
                    <div className="dropdown d-flex flex-column">
                      <button className="btn btn-text-dark btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown">
                        {type === 'share' &&
                          'Share'
                        }
                        {type === 'getBoost' &&
                          'Get Boost'
                        }
                        {type === 'emailCampaign' &&
                          'Email Campaign'
                        } <ion-icon name="chevron-down-outline" style={{ fontSize: '10px', paddingTop: '5px', paddingLeft: '2px' }}></ion-icon>
                      </button>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/boost?type=share">Share Block</Link>
                        <Link className="dropdown-item" to="/boost?type=getBoost">Get Boost</Link>
                        <Link className="dropdown-item" to="/boost?type=emailCampaign">Email Campaign</Link>
                      </div>
                    </div>
                  }
                  {analyticsDom &&
                    <h4 className="mb-0">Analytics</h4>
                  }
                  {supportDom &&
                    <h4 className="mb-0">Support</h4>
                  }
                  {homeDom &&
                    <div className="dropdown d-flex flex-column">
                      {/* <span style={{fontSize: '10px', height: '13px'}} className="text-secondary">{domainName}</span> */}
                      <button className="btn btn-text-dark btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown">
                        Switch Website <ion-icon name="chevron-down-outline" style={{ fontSize: '10px', paddingTop: '5px', paddingLeft: '2px' }}></ion-icon>
                      </button>
                      <div className="dropdown-menu" style={{ width: 'auto' }}>
                        {domainList.map((value, key) => {
                          return <a className="dropdown-item" href="#!" onClick={() => { switchWebsiteDomain(value.Domain, 'website') }}>{value.Domain}</a>
                        })}
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="https://webweb.ai/" target="_blank" rel="noreferrer">Create Website</a>
                      </div>
                    </div>
                  }
                  {manageEmailDom &&
                    <h4 className="mb-0">Email Accounts</h4>
                  }
                  {formDom &&
                    <h4 className="mb-0">Form Submissions</h4>
                  }
                  {searchDomainDom &&
                    <h4 className="mb-0">Search Domain</h4>
                  }
                  {overviewDom &&
                    <h4 className="mb-0">Home</h4>
                  }
                  {websitesDom &&
                    <h4 className="mb-0">Websites</h4>
                  }
                  {domainsDom &&
                    <h4 className="mb-0">Domains</h4>
                  }
                  {dnsDom &&
                    <h4 className="mb-0">DNS</h4>
                  }
                  {emailsControlDom &&
                    <h4 className="mb-0">Emails</h4>
                  }
                  {categoriesDom &&
                    <h4 className="mb-0">Categories</h4>
                  }
                  {dsettingDom &&
                    <h4 className="mb-0">Domain Setting</h4>
                  }
                  {mediaGalleryDom &&
                    <h4 className="mb-0">Media Gallery</h4>
                  }
                  {chatBotDom &&
                    <h4 className="mb-0">ChatBot</h4>
                  }
                  {
                    pluginsDom &&
                    <h4 className="mb-0">Plugins</h4>
                  }
                  {searchEngineOptimizationDom &&
                    <h4 className="mb-0">Search Engine Optimization</h4>
                  }
                  {emailMarketingDom &&
                    <h4 className="mb-0">Email Marketing</h4>
                  }
                  {socialMediaDom &&
                    <h4 className="mb-0">Social Media</h4>
                  }
                  {webmailDom &&
                    <h4 className="mb-0">Webmail</h4>
                  }
                  {liveChatDom &&
                    <h4 className="mb-0">Live Chat</h4>
                  }
                  {addTemplateDom &&
                    <h4 className="mb-0">Add Template</h4>
                  }
                  {blockDetailDom &&
                    <h4 className="mb-0">Block Detail</h4>
                  }
                  {defaultDom &&
                    <div className="dropdown d-flex flex-column">
                      <button className="btn btn-text-dark" type="button">
                        {domainName} <ion-icon name="chevron-down-outline" style={{ fontSize: '10px', paddingTop: '5px', paddingLeft: '2px' }}></ion-icon>
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="w-25 dropdown d-flex justify-content-end text-secondary fs-3 align-items-center">
            {/* <Link to="/notifications" className="text-secondary">
              <ion-icon name="notifications-outline"></ion-icon>
              <span className="badge badge-danger badge-empty" style={{position: 'absolute', right: '40px', top: '0px'}}></span>
            </Link> */}
            {/* {launchValue &&
              <button className="btn btn-primary me-2" type="button" disabled>
                <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                Publishing...
              </button>
            }
            {!launchValue &&
              <div>
              {role === 'Customer' &&
                <button className="btn btn-primary me-2" onClick={handleLaunchSite}>Publish</button>
              }
              </div>
            } */}
            {!buildDom &&
              <span className="dropdown">
                <span
                  className="headerButton"
                  data-bs-toggle="dropdown"
                  style={{ cursor: 'pointer' }}
                >
                  <ion-icon name="ellipsis-vertical" className="fs-4"></ion-icon>
                </span>
                <div className="webweb-dropdown-menu dropdown-menu dropdown-menu-end" style={{ zIndex: 1 }}>
                  {/* <a className="dropdown-item" href="#!" onClick={() => {showAppearance({isAppearanceVisible: true})}}>
                    <ion-icon name="color-palette-outline"></ion-icon> Theme Style
                  </a> */}
                  {/* <Link className="dropdown-item" to="/notifications">
                    <ion-icon name="notifications-outline"></ion-icon>{' '}
                    Notifications
                  </Link>
                  <div className="dropdown-divider"></div> */}
                  {/* <a className="dropdown-item" href="#!" onClick={handleSiteSetting}>
                    <ion-icon name="earth-outline"></ion-icon> Site Setting
                  </a>
                  <a className="dropdown-item" href="#!" onClick={handleDomainSetting}>
                    <ion-icon name="globe-outline"></ion-icon> Domain Setting
                  </a>
                  <a className="dropdown-item" href="#!" onClick={handleEmailSetting}>
                    <ion-icon name="mail-outline"></ion-icon> Email Setting
                  </a> */}
                  {
                    (tokenData.userInfo.RoleID === 'Admin' || localStorage.getItem('nnj') !== null) &&
                    <a className="dropdown-item" href="#!" onClick={showJSONMOdal}>
                      <ion-icon name="code-slash-outline"></ion-icon> JSON
                    </a>
                  }
                  {/* <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="/searchDomain">
                    <ion-icon name="search-outline"></ion-icon> Search Domain
                  </Link> */}
                  {/* <div className="dropdown-divider"></div> */}
                  <Link className="dropdown-item" to="/appInfo">
                    <ion-icon name="headset-outline"></ion-icon> Support
                  </Link>
                  <a className="dropdown-item" href="#!" onClick={handleLogout}>
                    <ion-icon name="log-out-outline"></ion-icon> Logout
                  </a>
                </div>
              </span>
            }
            {buildDom &&
              <span className="dropdown">
                <span
                  className="headerButton"
                  data-bs-toggle="dropdown"
                  style={{ cursor: 'pointer' }}
                >
                  <ion-icon name="ellipsis-vertical" className="fs-4"></ion-icon>
                </span>
                <div className="webweb-dropdown-menu dropdown-menu dropdown-menu-end">
                  {/* <a className="dropdown-item p-1" href="#!" onClick={() => {showAppearance({isAppearanceVisible: true})}}>
                    <ion-icon name="color-palette-outline" className="fs-5 text-secondary"></ion-icon> Theme Style
                  </a>
                  <a className="dropdown-item p-1" href="#!" onClick={() => {handlePageSetting('seo')}}>
                    <ion-icon name="construct-outline" className="fs-5 text-secondary"></ion-icon> Page Settings 
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item p-1" href="#!" onClick={() => {handleDeletePage()}}>
                    <ion-icon name="trash-outline" className="fs-5 text-danger"></ion-icon> Delete Page
                  </a> */}
                  {/* <Link className="dropdown-item" to="/notifications">
                    <ion-icon name="notifications-outline"></ion-icon>{' '}
                    Notifications
                  </Link>
                  <div className="dropdown-divider"></div> */}
                  {/* <a className="dropdown-item" href="#!" onClick={handleSiteSetting}>
                    <ion-icon name="earth-outline"></ion-icon> Site Setting
                  </a>
                  <a className="dropdown-item" href="#!" onClick={handleDomainSetting}>
                    <ion-icon name="globe-outline"></ion-icon> Domain Setting
                  </a>
                  <a className="dropdown-item" href="#!" onClick={handleEmailSetting}>
                    <ion-icon name="mail-outline"></ion-icon> Email Setting
                  </a> */}
                  {
                    (tokenData.userInfo.RoleID === 'Admin' || localStorage.getItem('nnj') !== null) &&
                    <a className="dropdown-item" href="#!" onClick={showJSONMOdal}>
                      <ion-icon name="code-slash-outline"></ion-icon> JSON
                    </a>
                  }
                  {/* <div className="dropdown-divider"></div> */}
                  {/* <Link className="dropdown-item" to="/searchDomain">
                    <ion-icon name="search-outline"></ion-icon> Search Domain
                  </Link> */}
                  {/* <div className="dropdown-divider"></div> */}
                  <Link className="dropdown-item" to="/appInfo">
                    <ion-icon name="headset-outline"></ion-icon> Support
                  </Link>
                  <a className="dropdown-item" href="#!" onClick={handleLogout}>
                    <ion-icon name="log-out-outline"></ion-icon> Logout
                  </a>
                </div>
              </span>
            }
          </div>
        </div>
      </div>
      <Preview selectedDomain={selectedDomain} />
      <DeleteDialogueBox
        show={isDeleteVisible}
        deleteMessage={deleteMessage}
        triggerMessage={triggerMessage}
        deleteID={deleteID}
      ></DeleteDialogueBox>
      <ConnectDomainModal />
      <ConfirmationModal></ConfirmationModal>
      <HtmlEditor></HtmlEditor>
      <CategoryManage></CategoryManage>
      <DomainsAdminOffcanvas></DomainsAdminOffcanvas>
      <Ogoffcanvas></Ogoffcanvas>
    </div>
  )
}

const mapStateToProps = ({ appToast, offcanvas, utilities }) => {
  const { isVisible, toastMessage, background, icon, position } = appToast
  const { isDeleteVisible, deleteMessage, triggerMessage, deleteID, sidebarToggle } = offcanvas
  const { domains } = utilities
  return {
    appToastVisible: isVisible,
    appToastMessage: toastMessage,
    appToastBackground: background,
    appToastIcon: icon,
    appToastPosition: position,
    isDeleteVisible,
    deleteMessage,
    triggerMessage,
    deleteID,
    domains,
    sidebarToggle
  }
}

const mapDispatchToProps = {
  setWebsiteData,
  logout,
  updateDeviceSize,
  showAppearance,
  showDeleteModal,
  showPageSetting,
  setResponsiveIframe,
  showAppToast,
  showNavigationOffcanvas,
  showSiteSettingOffcanvas,
  showDomainSettingOffcanvas,
  showEmailSettingOffcanvas,
  setShowJSONModal,
  showSidebarToggle,
  setSideBarMenuShow
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
