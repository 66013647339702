import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideFooterActionSheet } from '../../../features/modal/offcanvasSlice'
import { useNavigate } from 'react-router-dom'

const FooterActionSheet = ({
    isFooterActionVisible,
    actionSheetOf,
    hideFooterActionSheet,
    websiteDataAppearance
}) => {
    let navigate = useNavigate()
    const handlePageSwitchFooter = (url) => {
        hideFooterActionSheet()
        navigate(url)
    }
    return (
        <Offcanvas show={isFooterActionVisible} className={'offcanvas-bottom-height action-sheet inset'} placement={'bottom'} backdrop={true} scroll={true}>
            <div class="offcanvas-header">
                <div class="row">
                    <div class="col-6 col-md-6 d-flex align-items-center">
                        <h5 class="offcanvas-title">{actionSheetOf}</h5>
                    </div>
                    <div class="col-6 col-md-6 d-flex align-items-center justify-content-end cursor-pointer" onClick={() => hideFooterActionSheet()}>
                        <ion-icon name="close-outline" ></ion-icon>
                    </div>
                </div>
            </div>
            <Offcanvas.Body>
                {(actionSheetOf !== '' && actionSheetOf !== null && actionSheetOf !== undefined && actionSheetOf === 'Website') &&
                    <ul class="action-button-list">
                        <li>
                            <a href="javscript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/build?url=" + websiteDataAppearance.properties.defaultPage)}>
                                <span>Manage Website</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/analytics?report=hits")}>
                                <span>Analytics</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/DSetting")}>
                                <span>Domain Setting</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/manageEmail")}>
                                <span>Email Accounts</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/plugins")}>
                                <span>Plugins</span>
                            </a>
                        </li>
                    </ul>
                }
                {(actionSheetOf !== '' && actionSheetOf !== null && actionSheetOf !== undefined && actionSheetOf === 'Digital Marketing') &&
                    <ul class="action-button-list">
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/searchEngineOptimization")}>
                                <span>Search Engine Optimization</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/socialMedia")}>
                                <span>Social Media Marketing</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/emailMarketing")}>
                                <span>Email Marketing</span>
                            </a>
                        </li>
                    </ul>
                }
                {(actionSheetOf !== '' && actionSheetOf !== null && actionSheetOf !== undefined && actionSheetOf === 'Customer') &&
                    <ul class="action-button-list">
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/forms")}>
                                <span>Form Submissions</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/chatBot")}>
                                <span>ChatBot</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/webmail")}>
                                <span>Webmail</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="btn btn-list" onClick={() => handlePageSwitchFooter("/liveChat")}>
                                <span>Live Chat</span>
                            </a>
                        </li>
                    </ul>
                }
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => { 
    const {
        isFooterActionVisible,
        actionSheetOf
    } = offcanvas
    const {
        appearance: websiteDataAppearance,
    } = websiteData
    return {
        isFooterActionVisible,
        actionSheetOf,
        websiteDataAppearance
    }
  }
  
  const mapDispatchToProps = {
    hideFooterActionSheet
  }

export default connect(mapStateToProps, mapDispatchToProps)(FooterActionSheet)
