import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useLocation } from 'react-router-dom'
import { hideButtonModal, showOffcanvas, newButtonData } from '../../../features/modal/offcanvasSlice'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Tabs from 'react-bootstrap/Tabs'
import jwt_decode from 'jwt-decode'
import axios from '../../../AxiosConfig'
import Paypal from '../../images/paypal.svg'
import Rayzorpay from '../../images/razorpay.svg'
import { Button } from 'react-bootstrap'
import { setShowUploadFilesModal } from '../../../features/modal/fileUploadSlice'

const ButtonModal = ({
    show,
    buttonData,
    buttonDataObj,
    buttonID,
    hideButtonModal,
    newButtonData,
    appearance,
    encrypt,
    setShowUploadFilesModal,
    FileUpload,
    authToken
}) => {
    const [buttonType, setButtonType] = useState('')
    const [sitemapList, setSitemapList] = useState([{}])
    const [blockList, setBlockList] = useState(null)
    const [buttonStyle, setButtonStyle] = useState(null)
    const [buttonStyleClass, setButtonStyleClass] = useState('')
    const [buttonText, setButtonText] = useState('');

    const [PageBlockID, setPageBlockID] = useState(null);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const [currency, setCurrency] = useState('₹');
    const [amount, setAmount] = useState(0);
    const [payementGateway, setPaymentGateway] = useState('Razorpay');
    const [taxAmount, setTaxAmount] = useState(0);
    const [taxPercent, setTaxPercent] = useState(0);
    const [taxBehaviour, setTaxBehaviour] = useState('Include');

    const [particularItems, setParticularItems] = useState('');
    const [reservedFor, setReservedFor] = useState('');
    const [Pay, setPay] = useState(0);

    const [existingCTA, setExistingCTA] = useState(null);
    const [selectingCTA, setSelectingCTA] = useState(false);

    const search = useLocation().search
    let url = ''
    url = new URLSearchParams(search).get('url')
    if (url === null) {
        url = localStorage.getItem('previousPage')
    } else {
        url = new URLSearchParams(search).get('url')
        localStorage.setItem('previousPage', url)
    }

    const fetchWebsiteJSON = () => {
        const webDoc = localStorage.getItem('website')
        const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
            return res
        } else {
            return false
        }
    }

    const websiteJSON = fetchWebsiteJSON()

    // console.log(websiteJSON)

    const token = authToken
    const tokenData = (token) ? jwt_decode(token) : false;
    const userRole = tokenData.userInfo.RoleID

    let sitemapData = []

    let selectedDomain = "";
    let selectedCategoryID = "";
    if (websiteJSON !== undefined && websiteJSON !== false) {
        if (tokenData.userInfo.RoleID != 'Moderator') {
            selectedDomain = tokenData.SelectedDomain.Domain
            sitemapData = websiteJSON.appearance.navigation.header
        } else {
            sitemapData = websiteJSON.appearance.navigation.header
            selectedDomain = tokenData.SelectedDomain.CategoryName
            selectedCategoryID = tokenData.SelectedDomain.ID
        }
    }

    const getSitemapList = (sitemap) => {
        let list = []
        sitemap.map((value) => {
            if (!value.hasOwnProperty('children')) {
                list.push(value)
            } else {
                value.children.map(val => {
                    list.push(val)
                })
            }
        })
        list.map((value, key) => {
            Object.keys(websiteJSON.appearance.pages).map((v, k) => {
                if (value.page === v) {
                    list[key].url = websiteJSON.appearance.pages[v].url
                }
            })
        })
        setSitemapList(list)
    }

    useEffect(() => {
        getSitemapList(sitemapData)
        if (url !== undefined && url !== null) {
            //   document.getElementById('pageList').value = url
        }
    }, [url])

    const setInputValues = () => {

        if (show) {
            // document.getElementById('buttonTypeDropdown').value = (buttonData[buttonID].type === undefined) ? 'page' : buttonData[buttonID].type;
            setButtonText((buttonData[buttonID].title) ? buttonData[buttonID].title : '');
            if(buttonData[buttonID].url.includes('#')) {
                const split = buttonData[buttonID].url.split('#');
                console.log(split)
                if(split.length > 1) {
                    setPageBlockID(split[1]);
                }
            }
            console.log(buttonData[buttonID])
            setButtonStyleClass((buttonData[buttonID].style) ? buttonData[buttonID].style : '');
            document.getElementById('buttonStyleDropdown').value = (buttonData[buttonID].type === undefined) ? 'btn-primary' : buttonData[buttonID].style;
            // document.getElementsByName("buttonTitleInput")[0].value = (buttonData[buttonID].title) ? buttonData[buttonID].title : ''
            setButtonType((buttonData[buttonID].type === undefined) ? 'page' : buttonData[buttonID].type)

            if (buttonData[buttonID].type !== 'none') {
                // document.getElementsByName(buttonType1 + "urlInput")[0].value = (buttonData[buttonID].url) ? buttonData[buttonID].url : ''
                setButtonType(buttonData[buttonID].type);
            }

            if (buttonData[buttonID].type === 'registration' || buttonData[buttonID].type === 'buyNow') {
                const btnData = buttonData[buttonID]
                if (btnData.buttonDetails !== undefined) {
                    if (btnData.buttonDetails.Period !== undefined) {
                        const { StartDate, EndDate, ExpiryDate, StartTime, EndTime } = btnData.buttonDetails.Period;
                        setStartDate(StartDate ? StartDate : '');
                        setEndDate(EndDate ? EndDate : '');
                        setExpiryDate(ExpiryDate ? ExpiryDate : '');
                        setStartTime(StartTime ? StartTime : '');
                        setEndTime(EndTime ? EndTime : '');
                    }
                    if (btnData.buttonDetails.Pricing !== undefined) {
                        const { PaymentGateway, Currency, Amount, TaxAmount, TaxPercent, TaxBehaviour } = btnData.buttonDetails.Pricing;
                        setPaymentGateway(PaymentGateway ? PaymentGateway : 'Razorpay');
                        setCurrency(Currency ? Currency : '₹');
                        setAmount(Amount ? Amount : 0);
                        setTaxAmount(TaxAmount ? TaxAmount : 0);
                        setTaxPercent(TaxPercent ? TaxPercent : 0);
                        setTaxBehaviour(TaxBehaviour ? TaxBehaviour : 'Include');
                    }
                }
            }

            if (buttonData[buttonID].type === 'page') {
                if (document.getElementsByName(buttonData[buttonID].type + "urlInput")[0]) {
                    document.getElementsByName(buttonData[buttonID].type + "urlInput")[0].value = buttonData[buttonID].url.split('#')[0];
                }
                console.log(buttonData, document.getElementsByName("targetBlockInput")[0])
                document.getElementsByName("targetBlockInput")[0].value = buttonData[buttonID].url.split('#')[1]
                setButtonType(buttonData[buttonID].type);
            } else if (buttonData[buttonID].type === 'whatsapp') {
                document.getElementsByName(buttonData[buttonID].type + "urlInput")[0].value = buttonData[buttonID].url.replace('+91', '');
                // console.log(buttonData[buttonID].type, show);
            } else {
                if (document.getElementsByName(buttonData[buttonID].type + "urlInput")[0]) document.getElementsByName(buttonData[buttonID].type + "urlInput")[0].value = buttonData[buttonID].url;
            }
        }
        // const buttonType1 = (buttonData[buttonID].type === undefined) ? 'page' : buttonData[buttonID].type
    }

    useEffect(() => {
        if (typeof (buttonData[buttonID]) === 'undefined') {
            setButtonType('page')
        } else {
            if (buttonID + 1 <= buttonData.length) {
                buttonData[buttonID].cta && setExistingCTA(buttonData[buttonID].cta.replace('ctas.', ''));
                setInputValues();
            }
        }
    }, [buttonData, buttonID])

    const handleButtonType = (buttonTypeStr) => {
        setButtonType(buttonTypeStr);
        if(buttonType !== buttonTypeStr && !(buttonID + 1 <= buttonData.length)) {
            setExistingCTA(null);
            setSelectingCTA(false);
        }
    }

    const submitBlockStyle = (className) => {
        setButtonStyleClass(className)
    }

    const resetValuesandClose = () => {
        hideButtonModal();
        setButtonText('');
        setButtonStyleClass('');
        setStartDate('');
        setEndDate('');
        setExpiryDate('');
        setStartTime('');
        setEndTime('');
        setCurrency('₹');
        setAmount(0);
        setTaxAmount(0);
        setTaxPercent(0);
        setPageBlockID(null);
    }

    const handleButtonSubmit = (e) => {
        e.preventDefault()
        let inputs = {
            "title": buttonText,
            "type": buttonType,
            "style": buttonStyleClass,
            "icon": '',
        };
        console.log(buttonType)
        if (buttonType === 'page') {
            inputs.url = document.getElementsByName(buttonType + "urlInput")[0].value;
            if(PageBlockID) {
                inputs.url = inputs.url + "#" + PageBlockID;
            }
            // document.getElementsByName("targetBlockInput")[0].value;
            // if (inputs.url && inputs.url.length > 0 && inputs.url.includes('#')) {
            //     const urlArr = inputs.url.split("#");
            //     // console.log(urlArr)
            //     if (urlArr.length === 2 && urlArr[1] === '') {
            //         inputs.url = urlArr[0];
            //     }
            // }
        } else if (buttonType === 'whatsapp') {
            inputs.url = document.getElementById('CountryCode').value + document.getElementsByName(buttonType + "urlInput")[0].value;
        } else if (buttonType === 'none' || buttonType === 'external' || buttonType === 'call' || buttonType === 'whatsapp' || buttonType === 'email' || buttonType === 'socialMedia' || buttonType === 'image' || buttonType === 'location') {
            inputs.url = document.getElementsByName(buttonType + "urlInput")[0].value;
        } else if (buttonType === 'registration' || buttonType === 'buyNow' || buttonType === 'reservation') {
            if (buttonData && buttonData[buttonID] && buttonData[buttonID].cta) {
                inputs.cta = buttonData[buttonID].cta;
            }
            if (buttonData && buttonData[buttonID] && buttonData[buttonID].url) {
                inputs.url = buttonData[buttonID].url;
            }
            if(selectingCTA) {
                inputs.cta = `ctas.${existingCTA}`;
            } else {
                inputs.buttonDetails = {
                    "Period": {
                        "StartDate": startDate,
                        "EndDate": endDate,
                        "ExpiryDate": expiryDate,
                        "StartTime": startTime,
                        "EndTime": endTime
                    },
                    "Pricing": {
                        "PaymentGateway": payementGateway,
                        "Currency": currency,
                        "Amount": amount,
                        "TaxAmount": taxAmount,
                        "TaxPercent": taxPercent,
                        "TaxBehaviour": taxBehaviour
                    },
                    "type": buttonType,
                    "title": buttonText,
                    "style": buttonStyleClass
                }
            }
            // inputs.url = (document.getElementsByName(buttonType + "urlInput")[0] && document.getElementsByName("targetBlockInput")[0]) ? document.getElementsByName(buttonType + "urlInput")[0].value + document.getElementsByName("targetBlockInput")[0].value : '';

        }
        // console.log(inputs);

        let newButtonDataArr = []
        if (buttonData.length > 0) {
            buttonData.map((value, key) => {
                if (buttonID === key) {
                    newButtonDataArr[key] = inputs
                } else {
                    newButtonDataArr[key] = value
                }
            })
            if (typeof buttonData[buttonID] === 'undefined') {
                newButtonDataArr.push(inputs)
            }
        } else {
            newButtonDataArr.push(inputs)
        }
        newButtonData({ buttonDataObj: newButtonDataArr })
        resetValuesandClose();
    }

    function findKey2ByUrl(pages, url) {
        for (const page in pages) {
            if (pages[page].url === url) {
                return pages[page].blocks;
            }
        }
        return null; // return null if no match is found
    }

    const getPageBlocks = (currentPageName) => {
        let pageId = ''
        websiteJSON.appearance.navigation.header.map((value) => {
            if (value.hasOwnProperty('children')) {
                value.children.map((v, k) => {
                    if (v.text === currentPageName) {
                        pageId = v.page
                    }
                })
            } else {
                if (value.text === currentPageName) {
                    pageId = value.page
                }
            }
        })
        const pageItem = findKey2ByUrl(websiteJSON.appearance.pages, currentPageName);
        console.log(pageId, currentPageName, pageItem)
        if(pageItem && pageItem.length > 0) {
            setBlockList(pageItem)
        }
        // axios.get('/block/getBlockList/' + selectedDomain + '/' + pageId).then(res => {
        //     if (res.data.message === 'Success') {
        //         setBlockList(res.data.data)
        //     } else {
        //         console.log('SOMETHING WENT WRONG')
        //     }
        // })
    }

    const handleStyleList = () => {
        axios.get('/appearance/getButtonStyles').then(res => {
            if (res.data.message === 'Success') {
                setButtonStyle(res.data.data)
            } else {
                console.log('SOMETHING WENT WRONG')
            }
        })
    }

    const closeButtonModal = () => {
        newButtonData({ buttonDataObj: null })
        resetValuesandClose();
    }

    const changeStartDate = (selecteddate) => {
        setStartDate(selecteddate);
    }

    const changeEndDate = (selecteddate) => {
        setEndDate(selecteddate);
    }

    const changeExpiryDate = (selecteddate) => {
        setExpiryDate(selecteddate);
    }


    const changeStartTime = (selectedtime) => {
        setStartTime(selectedtime);
    }

    const changeEndTime = (selectedtime) => {
        setEndTime(selectedtime);
    }
    useEffect(() => {
        if (!show) {
            setExistingCTA(null);
            setSelectingCTA(false);
        }
    }, [show])

    const getExistingCTA = (val) => {
        setExistingCTA(val);
        if(val === '') {
            setSelectingCTA(false);
            setButtonText('');
            setButtonStyleClass('');
            setStartDate('');
            setEndDate('');
            setExpiryDate('');
            setStartTime('');
            setEndTime('');
            setPaymentGateway('Razorpay');
            setCurrency('₹');
            setAmount(0);
            setTaxAmount(0);
            setTaxPercent(0);
            setTaxBehaviour('Include');
            setPageBlockID(null);
        } else {
            setSelectingCTA(true);
            // encrypt.ctas.val
            const btnData = encrypt.ctas[val];
            // console.log(btnData, encrypt, val);
            setButtonText((btnData.properties.title) ? btnData.properties.title : '');
            setButtonStyleClass((btnData.properties.style) ? btnData.properties.style : '');
            // document.getElementById('buttonStyleDropdown').value = (btnData.properties.type === undefined) ? 'btn-primary' : btnData.properties.style;
            if (btnData.properties !== undefined) {
                if (btnData.properties.Period !== undefined) {
                    const { StartDate, EndDate, ExpiryDate, StartTime, EndTime } = btnData.properties.Period;
                    setStartDate(StartDate ? StartDate : '');
                    setEndDate(EndDate ? EndDate : '');
                    setExpiryDate(ExpiryDate ? ExpiryDate : '');
                    setStartTime(StartTime ? StartTime : '');
                    setEndTime(EndTime ? EndTime : '');
                }
                if (btnData.properties.Pricing !== undefined) {
                    const { PaymentGateway, Currency, Amount, TaxAmount, TaxPercent, TaxBehaviour } = btnData.properties.Pricing;
                    setPaymentGateway(PaymentGateway ? PaymentGateway : 'Razorpay');
                    setCurrency(Currency ? Currency : '₹');
                    setAmount(Amount ? Amount : 0);
                    setTaxAmount(TaxAmount ? TaxAmount : 0);
                    setTaxPercent(TaxPercent ? TaxPercent : 0);
                    setTaxBehaviour(TaxBehaviour ? TaxBehaviour : 'Include');
                }
            }
        }
    }


    useEffect(() => {
        handleStyleList()
    }, []);
    let disableRegistration = false;
    let disableReservation = false;
    let disableBuyNow = false;
    if (buttonData !== undefined && buttonData !== null) {
        const lengthBtn = buttonData.filter(item => item.type === 'registration')
        if (lengthBtn.length > 0) {
            disableRegistration = true;
        }

        const lengthBtn2 = buttonData.filter(item => item.type === 'reservation')
        if (lengthBtn2.length > 0) {
            disableReservation = true;
        }

        const lengthBtn3 = buttonData.filter(item => item.type === 'buyNow')
        if (lengthBtn3.length > 0) {
            disableBuyNow = true;
        }
    }

    return (
        <Offcanvas show={show} placement={'end'} backdrop={true} scroll={true} onEntered={handleStyleList} id="appCapsule">
            {/* <Offcanvas.Header className="offcanvas-header pe-0 ps-1" style={{display:'inline'}}>
                <div className="row">
                    <div className="col-4 col-md-4 d-flex align-items-center">
                        <h5 className="modal-title text-start">Edit Button</h5>
                    </div>
                    <div className="col-8 col-md-8 text-end">
                        <button className="btn btn-text-danger btn-sm"><ion-icon name="close-circle-outline"></ion-icon> Remove Button</button>&nbsp;
                        <button className="btn btn-text-secondary btn-sm" onClick={hideButtonModal}><ion-icon name="close-outline" className="m-0 fs-4"></ion-icon></button>
                    </div>
                </div>
            </Offcanvas.Header> */}
            <Offcanvas.Body className="offcanvas-body p-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Edit Button / Link</strong>
                    </div>
                    <span className="close-sidebar-button" style={{ cursor: 'pointer' }} onClick={() => closeButtonModal()}>
                        <ion-icon name="close"></ion-icon>
                    </span>
                    {/* <div className="in">
                        <strong>Edit Button</strong>
                    </div> */}
                    {/* <button className="btn btn-text-danger btn-sm"><ion-icon name="close-circle-outline"></ion-icon> Remove Button</button>&nbsp; */}
                    {/* <button className="btn btn-text-secondary btn-sm" onClick={hideButtonModal}><ion-icon name="close-outline" className="m-0 fs-4"></ion-icon></button> */}
                </div>
                <div className="section full mb-2">
                    {/* <div className="custom-extraHeader position-absolute p-0">
                        <ul className="nav nav-tabs lined" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#noneButton" role="tab" style={{ height: '46px' }}>
                                    None
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#linkButton" role="tab" style={{ height: '46px' }}>
                                    Link
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#actionButton" role="tab" style={{ height: '46px' }}>
                                    Action
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    <div className="row ms-1 me-1">

                        <div className="col-md-12 col-12">
                            <div className="form-group boxed pb-0 mb-0">
                                <div className="input-wrapper">
                                    <label className="form-label">Button Preview</label>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-center py-2 rounded' style={{ 'backgroundColor': '#f2f4f4' }}>
                                <button className={`rounded previewbtn ${buttonStyleClass}`}>{buttonText}</button>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <label className="form-label">Select Button Type</label>
                                    <div className="btn-group w-100 d-flex flex-wrap" role="group" aria-label="Basic radio toggle button group">
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="page" id="LinkPage" defaultChecked checked={buttonType === 'page'} />
                                            <label onClick={() => { handleButtonType("page") }} className="btn btn-icon btn-outline-primary btn-sm position-relative" title='Link Page' htmlFor="LinkPage">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Link Page" />
                                                <ion-icon name="document-text-outline"></ion-icon>
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="external" id="ExternalLink" checked={buttonType === 'external'} />
                                            <label onClick={() => { handleButtonType("external") }} className="btn btn-icon btn-outline-primary btn-sm position-relative" title='External Link' htmlFor="ExternalLink">
                                                <div className='position-absolute w-100 h-100 hover-text' title="External Link" />
                                                <ion-icon name="link-outline"></ion-icon>
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="call" id="callPage" checked={buttonType === 'call'} />
                                            <label onClick={() => { handleButtonType("call") }} className="btn btn-icon btn-outline-primary btn-sm position-relative" title='Phone' htmlFor="callPage">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Phone" />
                                                <ion-icon name="call-outline"></ion-icon>
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="whatsapp" id="LinkWhatsapp" checked={buttonType === 'whatsapp'} />
                                            <label onClick={() => { handleButtonType("whatsapp") }} className="btn btn-icon btn-outline-primary btn-sm position-relative" title='Link Whatsapp' htmlFor="LinkWhatsapp">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Link Whatsapp" />
                                                <ion-icon name="logo-whatsapp"></ion-icon>
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="email" id="Email" checked={buttonType === 'email'} />
                                            <label onClick={() => { handleButtonType("email") }} className="btn btn-icon btn-outline-primary btn-sm position-relative" title='Email' htmlFor="Email">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Email" />
                                                <ion-icon name="mail-outline"></ion-icon>
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="socialMedia" id="socialMedia" checked={buttonType === 'socialMedia'} />
                                            <label onClick={() => { handleButtonType("socialMedia") }} className="btn btn-icon btn-outline-primary btn-sm position-relative" title='Social Media' htmlFor="socialMedia">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Social Media" />
                                                <ion-icon name="share-social-outline"></ion-icon>
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="image" id="LinkImage" checked={buttonType === 'image'} />
                                            <label onClick={() => { handleButtonType("image") }} className="btn btn-icon btn-outline-primary btn-sm position-relative" title='Link Image' htmlFor="LinkImage">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Link Image" />
                                                <ion-icon name="image-outline"></ion-icon>
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="location" id="Location" checked={buttonType === 'location'} />
                                            <label onClick={() => { handleButtonType("location") }} className="btn btn-icon btn-outline-primary btn-sm position-relative" title='Location' htmlFor="Location">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Location" />
                                                <ion-icon name="location-outline"></ion-icon>
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="none" id="none" checked={buttonType === 'none'} />
                                            <label onClick={() => { handleButtonType("none") }} className="btn btn-outline-primary btn-sm position-relative" title='None' htmlFor="none">None</label>
                                            <div className='position-absolute w-100 h-100 hover-text' title="None" />
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="registration" id="Registration" checked={buttonType === 'registration'} />
                                            {/* {
                                                disableRegistration ?
                                                    <label className="btn btn-outline-primary btn-sm position-relative" title='Registration' htmlFor="Registration">
                                                        <div className='position-absolute w-100 h-100 hover-text' title="Registration" />
                                                        <ion-icon name="person-add-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                        Registration
                                                    </label>
                                                    :
                                                    <label onClick={() => { handleButtonType("registration") }} className="btn btn-outline-primary btn-sm position-relative" title='Registration' htmlFor="Registration">
                                                        <div className='position-absolute w-100 h-100 hover-text' title="Registration" />
                                                        <ion-icon name="person-add-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                        Registration
                                                    </label>
                                            } */}
                                            <label onClick={() => { handleButtonType("registration") }} className="btn btn-outline-primary btn-sm position-relative" title='Registration' htmlFor="Registration">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Registration" />
                                                <ion-icon name="person-add-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                Registration
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="buyNow" id="buyNow" checked={buttonType === 'buyNow'} />
                                            {/* {
                                                disableBuyNow ?
                                                    <label className="btn btn-outline-primary btn-sm position-relative" title='Buy Now' htmlFor="buyNow">
                                                        <div className='position-absolute w-100 h-100 hover-text' title="Buy Now" />
                                                        <ion-icon name="bag-check-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                        Buy Now
                                                    </label>
                                                    :
                                                    <label onClick={() => { handleButtonType("buyNow") }} className="btn btn-outline-primary btn-sm position-relative" title='Buy Now' htmlFor="buyNow">
                                                        <div className='position-absolute w-100 h-100 hover-text' title="Buy Now" />
                                                        <ion-icon name="bag-check-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                        Buy Now
                                                    </label>
                                            } */}
                                            <label onClick={() => { handleButtonType("buyNow") }} className="btn btn-outline-primary btn-sm position-relative" title='Buy Now' htmlFor="buyNow">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Buy Now" />
                                                <ion-icon name="bag-check-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                Buy Now
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' value="reservation" id="Reservation" checked={buttonType === 'reservation'} />
                                            {/* {
                                                disableReservation ?
                                                    <label className="btn btn-outline-primary btn-sm position-relative" title='Reservation' htmlFor="Reservation">
                                                        <div className='position-absolute w-100 h-100 hover-text' title="Reservation" />
                                                        <ion-icon name="bookmark-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                        Reservation
                                                    </label>
                                                    :
                                                    <label onClick={() => { handleButtonType("reservation") }} className="btn btn-outline-primary btn-sm position-relative" title='Reservation' htmlFor="Reservation">
                                                        <div className='position-absolute w-100 h-100 hover-text' title="Reservation" />
                                                        <ion-icon name="bookmark-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                        Reservation
                                                    </label>
                                            } */}
                                            <label onClick={() => { handleButtonType("reservation") }} className="btn btn-outline-primary btn-sm position-relative" title='Reservation' htmlFor="Reservation">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Reservation" />
                                                <ion-icon name="bookmark-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                Reservation
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' disabled value="payment" id="payment" checked={buttonType === 'payment'} />
                                            <label onClick={() => { handleButtonType("payment") }} className="btn btn-outline-primary btn-sm position-relative" title='Payment' htmlFor="payment">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Payment" />
                                                <ion-icon name="cash-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                Payment
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' disabled value="popup" id="Popup" checked={buttonType === 'popup'} />
                                            <label onClick={() => { handleButtonType("popup") }} className="btn btn-outline-primary btn-sm position-relative" title='Popup' htmlFor="Popup">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Popup" />
                                                <ion-icon name="terminal-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                Popup
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' disabled value="addToCart" id="addToCart" checked={buttonType === 'addToCart'} />
                                            <label onClick={() => { handleButtonType("addToCart") }} className="btn btn-outline-primary btn-sm position-relative" title='Add to Cart' htmlFor="addToCart">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Add to Cart" />
                                                <ion-icon name="cart-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                Add to Cart
                                            </label>
                                        </div>
                                        <div className='px-1 pb-1 position-relative'>
                                            <input type="radio" className="btn-check" name='buttonType' disabled value="appointment" id="Appointment" checked={buttonType === 'appointment'} />
                                            <label onClick={() => { handleButtonType("appointment") }} className="btn btn-outline-primary btn-sm position-relative" title='Appointment' htmlFor="Appointment">
                                                <div className='position-absolute w-100 h-100 hover-text' title="Appointment" />
                                                <ion-icon name="calendar-outline" style={{ 'fontSize': '14px' }}></ion-icon>
                                                Appointment
                                            </label>
                                        </div>
                                    </div>
                                    {/* <select className="form-control form-select" id="buttonTypeDropdown" onChange={(e) => { handleButtonType(e.target.value) }}>
                                        <option value="page">&#128196; Link Page</option>
                                        <option value="external">&#x1F517; External Link</option>
                                        <option value="call">&#128383; Phone</option>
                                        <option value="whatsapp">&#128488; Link Whatsapp</option>
                                        <option value="email">&#9993; Email</option>
                                        <option value="socialMedia"> Social Media</option>
                                        <option value="image">&#128461; Link Image</option>
                                        <option value="document">&#128195; Link Document</option>
                                        <option value="payment">&#x24; Payment Gateway</option>
                                        <option value="none"> None</option>
                                    </select> */}
                                </div>
                            </div>
                            {
                                ((['payment', 'registration', 'buyNow', 'reservation'].includes(buttonType) && (existingCTA === null || selectingCTA))) &&
                                <>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="page5">Add Existing CTA</label>
                                            <select className="form-control form-select" id="buttonTypeDropdown" onChange={(e) => getExistingCTA(e.target.value)}>
                                                <option value='' disabled selected>Select CTA</option>
                                                {encrypt && encrypt.ctas &&
                                                    <>
                                                        {
                                                            Object.keys(encrypt.ctas).map((key, i) => {
                                                                return <option key={i} value={key}>{encrypt.ctas[key].properties.title}</option>
                                                            })
                                                        }
                                                    </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <button className="btn btn-secondary" onClick={() => getExistingCTA('')}>Add New CTA</button>
                                        </div>
                                    </div>
                                </>
                            }
                            {/* <ul className="nav nav-tabs capsuled flex-column border-end rounded-0" role="tablist">
                                <li className="nav-item">
                                    <a className={(buttonType === 'page') ? "nav-link justify-content-start text-start active" : "nav-link justify-content-start text-start"} data-bs-toggle="tab" href="#pageTab" role="tab">
                                        <span className="fa fa-file-o" style={{marginRight: '8px', marginLeft: '8px'}}></span>
                                        Page
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={(buttonType === 'external') ? "nav-link justify-content-start text-start active" : "nav-link justify-content-start text-start"} data-bs-toggle="tab" href="#externalLinkTab" role="tab">
                                        <span className="fa fa-external-link" style={{marginRight: '8px', marginLeft: '8px'}}></span>
                                        External Link
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={(buttonType === 'call') ? "nav-link justify-content-start text-start active" : "nav-link justify-content-start text-start"} data-bs-toggle="tab" href="#phoneTab" role="tab" onClick={() => {handleButtonType('call')}}>
                                        <span className="fa fa-phone" style={{marginRight: '8px', marginLeft: '8px'}}></span>
                                        Call
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={(buttonType === 'whatsapp') ? "nav-link justify-content-start text-start active" : "nav-link justify-content-start text-start"} data-bs-toggle="tab" href="#whatsappTab" role="tab" onClick={() => {handleButtonType('whatsapp')}}>
                                        <span className="fa fa-whatsapp" style={{marginRight: '8px', marginLeft: '8px'}}></span>
                                        Whatsapp
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={(buttonType === 'email') ? "nav-link justify-content-start text-start active" : "nav-link justify-content-start text-start"} data-bs-toggle="tab" href="#emailTab" role="tab" onClick={() => {handleButtonType('email')}}>
                                        <span className="fa fa-envelope-o" style={{marginRight: '8px', marginLeft: '8px'}}></span>
                                        Email
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={(buttonType === 'image') ? "nav-link justify-content-start text-start active" : "nav-link justify-content-start text-start"} data-bs-toggle="tab" href="#imageTab" role="tab" onClick={() => {handleButtonType('image')}}>
                                        <span className="fa fa-picture-o" style={{marginRight: '8px', marginLeft: '8px'}}></span>
                                        Image
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={(buttonType === 'document') ? "nav-link justify-content-start text-start active" : "nav-link justify-content-start text-start"} data-bs-toggle="tab" href="#documentTab" role="tab" onClick={() => {handleButtonType('document')}}>
                                        <span className="fa fa-file-text-o" style={{marginRight: '8px', marginLeft: '8px'}}></span>
                                        Document
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={(buttonType === 'payment') ? "nav-link justify-content-start text-start active" : "nav-link justify-content-start text-start"} data-bs-toggle="tab" href="#paymentTab" role="tab" onClick={() => {handleButtonType('payment')}}>
                                        <span className="fa fa-credit-card" style={{marginRight: '8px', marginLeft: '8px'}}></span>
                                        Collect Payment
                                    </a>
                                </li>
                            </ul> */}
                            {
                                ((['payment', 'registration', 'buyNow', 'reservation'].includes(buttonType) && existingCTA !== null) || !['payment', 'registration', 'buyNow', 'reservation'].includes(buttonType)) &&
                                <>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="page5">Button Text</label>
                                            <input type="text" value={buttonText} onChange={(e) => setButtonText(e.target.value)} className="form-control" id="buttonText" placeholder="Enter Button Text" name="buttonTitleInput"
                                                autoComplete="off" disabled={selectingCTA} />
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="buttonStyleDropdown">Select Button Style</label>
                                            <select className="form-control form-select" id="buttonStyleDropdown" onChange={(e) => { submitBlockStyle(e.target.value) }} disabled={selectingCTA}>
                                                {(buttonStyle !== undefined && buttonStyle !== null) &&
                                                    <>
                                                        {Object.keys(buttonStyle).map((key, i) => {
                                                            return <option key={i} value={key}>{buttonStyle[key]}</option>
                                                        })}
                                                    </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="tab-content mt-1">

                                <div className={(buttonType === 'page') ? "tab-pane fade show active" : "tab-pane fade"} id="pageTab" role="tabpanel">
                                    <form onSubmit={handleButtonSubmit}>
                                        <div className="sidebar-buttons webweb-sidebar-buttons">
                                            <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} type="submit" style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                        </div>
                                        {/* <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className="form-group boxed">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="page5">Button Text</label>
                                                    <input type="text" className="form-control" id="buttonText" placeholder="Enter Button Text" name="pagetitleInput"
                                                        autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="pageList">Pages</label>
                                                <select className="form-control form-select" id="pageList" name="pageurlInput" onChange={(e) => { getPageBlocks(e.target.value) }}>
                                                    {/* {sitemapList.map(value => {
                                                    return <option value={value.url}>{value.title}</option>
                                                })} */}
                                                    {appearance && appearance.pages &&
                                                        Object.keys(appearance.pages).map((item, index) => {
                                                            return <option value={appearance.pages[item].url} key={index}>{appearance.pages[item].url}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="page5">Block ID</label>
                                                <input type="text" className="form-control" value={PageBlockID} onChange={(e) => setPageBlockID(e.target.value)} id="blockID" placeholder="Enter Block ID" name="buttonBlockInput"
                                                    autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="form-group boxed d-none">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="page5">Block</label>
                                                <select className="form-control form-select" id="page5" name="targetBlockInput">
                                                    {blockList !== null &&
                                                        <>
                                                            {Object.keys(blockList).map((key, i) => {
                                                                return <option value={`${blockList[key]}${i}`} key={i}>{blockList[key]}</option>
                                                            })}
                                                        </>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control" value="page" name="pagetypeInput" />
                                        <input type="hidden" className="form-control" value="fa fa-file-o" name="pageiconName" />
                                    </form>
                                </div>
                                <div className={(buttonType === 'image') ? "tab-pane fade show active" : "tab-pane fade"} id="imageTab" role="tabpanel">
                                    {/* <div className="row">
                                    <div className="col-md-12 col-12">
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="page5">Button Text</label>
                                                <input type="text" className="form-control" id="buttonText" placeholder="Enter Button Text"
                                                    autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="page5">Image Title</label>
                                            <input type="text" className="form-control" id="imageTitle" placeholder="Enter Image Title"
                                                autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="page5">Image Description</label>
                                            <textarea id="description" rows="2" className="form-control" placeholder="Enter Description"></textarea>
                                        </div>
                                    </div>
                                    <small>Upload File</small>
                                    <div className="pb-2 border-0">
                                        <form>
                                            <div className="custom-file-upload" id="fileUpload1" style={{ height: '95px' }}>
                                                <input type="file" id="fileuploadInput" />
                                                <label htmlFor="fileuploadInput">
                                                    <span>
                                                        <strong>
                                                            <ion-icon name="cloud-upload-outline"></ion-icon>
                                                            <i>Tap to Upload</i>
                                                        </strong>
                                                    </span>
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                    {/* <div className="text-end mb-1">
                                    <button className="btn btn-success btn-sm" style={{bottom:'10px',right:'10px'}}><ion-icon name="checkmark-outline"></ion-icon> Update</button>
                                </div> */}
                                    <div className="sidebar-buttons webweb-sidebar-buttons">
                                        <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                    </div>
                                </div>
                                <div className={(buttonType === 'external') ? "tab-pane fade show active" : "tab-pane fade"} id="externalLinkTab" role="tabpanel">
                                    <form onSubmit={handleButtonSubmit}>
                                        <div className="sidebar-buttons webweb-sidebar-buttons">
                                            <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} type="submit" style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                        </div>
                                        {/* <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className="form-group boxed">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="page5">Button Text</label>
                                                    <input type="text" className="form-control" id="buttonText" placeholder="Enter Button Text" name="externaltitleInput"
                                                        autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <label className="form-label mb-0" htmlFor="externalUrlInput">URL</label>
                                                    {
                                                        (FileUpload === 't' || userRole === 'Admin') &&
                                                            <Button variant='link' size='sm' onClick={() => setShowUploadFilesModal(true)}>
                                                            <img src="assets/img/icon/documents-storage-files-svgrepo-com.svg" style={{'width' : '20px'}} />
                                                            Document
                                                            </Button>
                                                    }
                                                </div>
                                                <input type="url" className="form-control" id="externalUrlInput" placeholder="https://example.com" name="externalurlInput"
                                                    autoComplete="off" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control" value="external" name="externaltypeInput" />
                                        <input type="hidden" className="form-control" value="fa fa-external-link" name="externaliconName" />
                                    </form>
                                </div>
                                <div className={(buttonType === 'location') ? "tab-pane fade show active" : "tab-pane fade"} id="externalLinkTab" role="tabpanel">
                                    <form onSubmit={handleButtonSubmit}>
                                        <div className="sidebar-buttons webweb-sidebar-buttons">
                                            <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} type="submit" style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                        </div>
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="locationUrlInput">Location URL</label>
                                                <input type="url" className="form-control" id="locationUrlInput" placeholder="https://www.google.com/maps" name="locationurlInput"
                                                    autoComplete="off" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control" value="location" name="locationtypeInput" />
                                        <input type="hidden" className="form-control" value="fa fa-external-link" name="locationiconName" />
                                    </form>
                                </div>
                                <div className={(buttonType === 'socialMedia') ? "tab-pane fade show active" : "tab-pane fade"} id="externalLinkTab" role="tabpanel">
                                    <form onSubmit={handleButtonSubmit}>
                                        <div className="sidebar-buttons webweb-sidebar-buttons">
                                            <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} type="submit" style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                        </div>
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="socialMediaurlInput">URL</label>
                                                <input type="url" className="form-control" id="socialMediaurlInput" placeholder="https://example.com" name="socialMediaurlInput"
                                                    autoComplete="off" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control" value="socialMedia" name="socialMediatypeInput" />
                                        <input type="hidden" className="form-control" value="" name="socialMediaiconName" />
                                    </form>
                                </div>
                                <div className={(buttonType === 'none') ? "tab-pane fade show active" : "tab-pane fade"} id="externalLinkTab" role="tabpanel">
                                    <form onSubmit={handleButtonSubmit}>
                                        <div className="sidebar-buttons webweb-sidebar-buttons">
                                            <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} type="submit" style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                        </div>
                                        <input type="hidden" className="form-control" value="none" name="nonetypeInput" />
                                        <input type="hidden" className="form-control" value="" name="noneiconName" />
                                    </form>
                                </div>
                                <div className={(buttonType === 'document') ? "tab-pane fade show active" : "tab-pane fade"} id="documentTab" role="tabpanel">
                                    {/* <div className="row">
                                    <div className="col-md-12 col-12">
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="page5">Button Text</label>
                                                <input type="text" className="form-control" id="buttonText" placeholder="Enter Button Text"
                                                    autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="page5">Title</label>
                                            <input type="text" className="form-control" id="docTitle" placeholder="Enter Title"
                                                autoComplete="off" />
                                        </div>
                                    </div>
                                    <small>Upload File</small>
                                    <div className="pb-2 pt-1 border-0">
                                        <form>
                                            <div className="custom-file-upload" style={{ height: '95px' }} id="fileUpload1">
                                                <input type="file" id="fileuploadInput" />
                                                <label htmlFor="fileuploadInput">
                                                    <span>
                                                        <strong>
                                                            <ion-icon name="cloud-upload-outline"></ion-icon>
                                                            <i>Tap to Upload</i>
                                                        </strong>
                                                    </span>
                                                </label>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="sidebar-buttons webweb-sidebar-buttons">
                                        <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                    </div>
                                </div>
                                <div className={(buttonType === 'email') ? "tab-pane fade show active" : "tab-pane fade"} id="emailTab" role="tabpanel">
                                    <form onSubmit={handleButtonSubmit}>
                                        <div className="sidebar-buttons webweb-sidebar-buttons">
                                            <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} type="submit" style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                        </div>
                                        {/* <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className="form-group boxed">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="page5">Button Text</label>
                                                    <input type="text" className="form-control" id="buttonText" placeholder="Enter Button Text" name="emailtitleInput"
                                                        autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="email5">E-mail</label>
                                                <input type="email" className="form-control" id="email5" placeholder="E-mail address" name="emailurlInput"
                                                    autoComplete="off" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control" value="email" name="emailtypeInput" />
                                        <input type="hidden" className="form-control" value="fa fa-envelope" name="emailiconName" />
                                    </form>
                                </div>
                                <div className={(buttonType === 'call') ? "tab-pane fade show active" : "tab-pane fade"} id="phoneTab" role="tabpanel">
                                    <form onSubmit={handleButtonSubmit}>
                                        <div className="sidebar-buttons webweb-sidebar-buttons">
                                            <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} type="submit" style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                        </div>
                                        {/* <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className="form-group boxed">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="page5">Button Text</label>
                                                    <input type="text" className="form-control" id="buttonText" name="calltitleInput" placeholder="Enter Button Text"
                                                        autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="call">Phone</label>
                                                <input type="tel" className="form-control" id="call" placeholder="Enter your phone number" name="callurlInput" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control" value="call" name="calltypeInput" />
                                        <input type="hidden" className="form-control" value="fa fa-phone" name="calliconName" />
                                    </form>
                                </div>
                                <div className={(buttonType === 'whatsapp') ? "tab-pane fade show active" : "tab-pane fade"} id="whatsappTab" role="tabpanel">
                                    <form onSubmit={handleButtonSubmit}>
                                        <div className="sidebar-buttons webweb-sidebar-buttons">
                                            <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} type="submit" style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                        </div>
                                        {/* <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className="form-group boxed">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="page5">Button Text</label>
                                                    <input type="text" className="form-control" id="buttonText" placeholder="Enter Button Text" name="whatsapptitleInput"
                                                        autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="row">
                                            <div className="col-md-4 col-12">
                                                <div className="form-group boxed">
                                                    <div className="input-wrapper">
                                                        <label className="form-label" htmlFor="CountryCode">Country Code</label>
                                                        <input type="tel" className="form-control px-1" id="CountryCode" placeholder="Enter Country Code" value="+91" />
                                                        <i className="clear-input" style={{ padding: '0 20px 0 16px' }}>
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12 d-flex align-items-center">
                                                <div className="form-group boxed">
                                                    <div className="input-wrapper">
                                                        <label className="form-label" htmlFor="phone5">Whatsapp Number</label>
                                                        <input type="tel" maxLength="10" className="form-control" id="phone5" name="whatsappurlInput" placeholder="Enter Whatsapp Number" />
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="hidden" className="form-control" value="whatsapp" name="whatsapptypeInput" />
                                        <input type="hidden" className="form-control" value="fa fa-whatsapp" name="whatsappiconName" />
                                    </form>
                                </div>


                                <div className={(buttonType === 'payment' && existingCTA !== null) ? "tab-pane fade show active" : "tab-pane fade"} id="paymentTab" role="tabpanel">
                                    {/* <div className="row">
                                    <div className="col-md-12 col-12">
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="page5">Button Text</label>
                                                <input type="text" className="form-control" id="buttonText" placeholder="Enter Button Text"
                                                    autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-12 col-md-6 mb-1">
                                            <div className="card">
                                                <img src={Paypal} className="card-img-top p-1" alt="Payment gateway" />
                                                <div className="card-body text-center p-1">
                                                    <div className="form-group boxed p-0">
                                                        <div className="input-wrapper">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" name="checkboxInline" id="paypal" />&nbsp;
                                                                <label className="form-check-label" htmlFor="paypal"><h3 className="text-center">Add to Website</h3></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mb-1">
                                            <div className="card">
                                                <img src={Rayzorpay} className="card-img-top p-1" alt="Payment gateway" />
                                                <div className="card-body text-center p-1">
                                                    <div className="form-group boxed p-0">
                                                        <div className="input-wrapper">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" name="checkboxInline" id="rayzorpay" />&nbsp;
                                                                <label className="form-check-label" htmlFor="rayzorpay"><h3 className="text-center">Add to Website</h3></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="apikey">API Key</label>
                                            <input type="text" className="form-control" id="apikey" placeholder="Enter ID" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="apikey">Secret Key</label>
                                            <input type="text" className="form-control" id="secretkey" placeholder="Enter Secret Key" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="amount5">Amount (₹)</label>
                                            <input type="number" className="form-control" id="amount5" placeholder="₹" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="sidebar-buttons webweb-sidebar-buttons">
                                        <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                    </div>
                                </div>
                                <div className={((buttonType === 'registration' || buttonType === 'buyNow') && existingCTA !== null) ? "tab-pane fade show active" : "tab-pane fade"} id="registrationTab" role="tabpanel">
                                    {/* <form onSubmit={handleButtonSubmit}> */}
                                    <div className="form-group boxed border p-1 rounded">
                                        <div className="input-wrapper">
                                            <label className="form-label h5" htmlFor="buttonStyleDropdown"><h5>Period</h5></label>
                                            <div className='outline-primary'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" htmlFor="buttonStyleDropdown">Start Date</label>
                                                        <input type="date" placeholder="dd-mm-yyyy" max={endDate} value={startDate} onChange={(e) => changeStartDate(e.target.value)} className="form-control px-1" title="Start Date" disabled={selectingCTA} />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" htmlFor="buttonStyleDropdown">End Date</label>
                                                        <input type="date" placeholder="dd-mm-yyyy" disabled={startDate === null || selectingCTA} min={startDate} value={endDate} onChange={(e) => changeEndDate(e.target.value)} className="form-control px-1" title="End Date" />
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className='col-md-12'>
                                                        <label className="form-label" htmlFor="buttonStyleDropdown">Expiry Date</label>
                                                        <input type="date" placeholder="dd-mm-yyyy" value={expiryDate} onChange={(e) => changeExpiryDate(e.target.value)} className="form-control px-1" title="Expiry Date" disabled={selectingCTA} />
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className='col-md-12'>
                                                        <label className="form-label" htmlFor="buttonStyleDropdown">Availibility Time</label>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" htmlFor="buttonStyleDropdown">Start Time</label>
                                                        <input type="time" placeholder="dd-mm-yyyy" value={startTime} onChange={(e) => changeStartTime(e.target.value)} className="form-control px-1" title="Start Time" disabled={selectingCTA} />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" htmlFor="buttonStyleDropdown">End Time</label>
                                                        <input type="time" placeholder="dd-mm-yyyy" disabled={startTime === null || selectingCTA} min={startTime} value={endTime} onChange={(e) => changeEndTime(e.target.value)} className="form-control px-1" title="End Time" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group boxed border p-1 mt-1 rounded">
                                        <div className="input-wrapper">
                                            <label className="form-label h5"><h5>Pricing</h5></label>
                                            <div className='outline-primary'>
                                                <div className='row mt-1'>
                                                    <div className='col-md-12'>
                                                        <label className="form-label" htmlFor="payementGateway">Payment Gateway</label>
                                                        <select className="form-control form-select px-1" defaultValue="Razorpay" id="payementGateway" onChange={(e) => setPaymentGateway(e.target.value)} disabled={selectingCTA}>
                                                            <option value="Razorpay" selected={payementGateway === 'Razorpay'}>Razorpay</option>
                                                            <option value="Paytm" selected={payementGateway === 'Paytm'}>Paytm</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className='col-md-3'>
                                                        <label className="form-label" htmlFor="currency">Currency</label>
                                                        <select className="form-control form-select px-1" defaultValue="₹" id="currency" onChange={(e) => setCurrency(e.target.value)} disabled={selectingCTA}>
                                                            <option value="₹" selected={currency === '₹'}>₹</option>
                                                            <option value="$" selected={currency === '$'} disabled>$</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <label className="form-label" htmlFor="amount">Amount</label>
                                                        <input type="number" id="amount" value={amount} min='0' onChange={(e) => setAmount(e.target.value)} className="form-control px-1" title="Amount" disabled={selectingCTA} />
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" htmlFor="taxAmount">Tax Amount</label>
                                                        <input type="number" id="taxAmount" value={taxAmount} min='0' onChange={(e) => setTaxAmount(e.target.value)} className="form-control px-1" title="Tax Amount" disabled={selectingCTA} />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" htmlFor="taxPercent">Tax Percent</label>
                                                        <input type="number" id="taxPercent" value={taxPercent} min='0' onChange={(e) => setTaxPercent(e.target.value)} className="form-control px-1" title="Tax Percent" disabled={selectingCTA} />
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className='col-md-12'>
                                                        <label className="form-label" htmlFor="taxBehaviour">Tax Behaviour</label>
                                                        <select className="form-control form-select px-1" defaultValue="Include" id="taxBehaviour" onChange={(e) => setTaxBehaviour(e.target.value)} disabled={selectingCTA}>
                                                            <option value="Include" selected={taxBehaviour === 'Include'}>Include</option>
                                                            <option value="Exclude" selected={taxBehaviour === 'Exclude'}>Exclude</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidebar-buttons webweb-sidebar-buttons">
                                        <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} onClick={(e) => handleButtonSubmit(e)} style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                    </div>

                                    {/* </form> */}
                                </div>
                                <div className={(buttonType === 'reservation' && existingCTA !== null) ? "tab-pane fade show active" : "tab-pane fade"} id="reservationTab" role="tabpanel">
                                    {/* <form onSubmit={handleButtonSubmit}> */}

                                    <div className="form-group boxed border p-1 rounded">
                                        <div className="input-wrapper">
                                            {/* <label className="form-label h5" htmlFor="buttonStyleDropdown"><h5>Reservation</h5></label> */}
                                            <div className='outline-primary'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <label className="form-label" htmlFor="particularItems">Particular Items</label>
                                                        <input type="text" className="form-control" id="particularItems" value={particularItems} onChange={(e) => setParticularItems(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-12 mt-1'>
                                                        <label className="form-label" htmlFor="reservedFor">Reserved For</label>
                                                        <input type="text" className="form-control" id="reservedFor" value={reservedFor} onChange={(e) => setReservedFor(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-12 mt-1'>
                                                        <label className="form-label">Particular Period</label>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="form-label" htmlFor="startDate1">Start Date</label>
                                                                <input type="date" placeholder="dd-mm-yyyy" id="startDate1" max={endDate} value={startDate} onChange={(e) => changeStartDate(e.target.value)} className="form-control px-1" title="Start Date" />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label" htmlFor="endDate1">End Date</label>
                                                                <input type="date" placeholder="dd-mm-yyyy" id='endDate1' disabled={startDate === null} min={startDate} value={endDate} onChange={(e) => changeEndDate(e.target.value)} className="form-control px-1" title="End Date" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className='col-md-3'>
                                                        <label className="form-label" htmlFor="currency">Currency</label>
                                                        <select className="form-control form-select px-1" defaultValue="₹" id="currency" onChange={(e) => setCurrency(e.target.value)}>
                                                            <option value="₹" selected={currency === '₹'}>₹</option>
                                                            <option value="$" selected={currency === '$'} disabled>$</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <label className="form-label" htmlFor="Pay">Pay</label>
                                                        <input type="number" className="form-control pe-1" id="Pay" value={Pay} onChange={(e) => setPay(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidebar-buttons webweb-sidebar-buttons">
                                        <button className="btn btn-success btn-block" disabled={buttonText.trim().length === 0} onClick={(e) => handleButtonSubmit(e)} style={{ bottom: '10px', right: '10px', borderRadius: 'initial' }}><ion-icon name="checkmark-outline" className="m-0"></ion-icon>&nbsp; Update</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas, websiteData, auth }) => {
    const {
        newButton,
        buttonDataObj,
    } = offcanvas
    const {
        appearance,
        encrypt
    } = websiteData
    const {
        FileUpload,
        authToken
    } = auth
    return {
        newButton,
        buttonDataObj,
        appearance,
        encrypt,
        FileUpload,
        authToken
    }
}

const mapDispatchToProps = {
    hideButtonModal,
    showOffcanvas,
    newButtonData,
    setShowUploadFilesModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonModal)