import React, { useState } from 'react';
import axios from '../AxiosConfig';
import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { setFileList } from '../features/modal/fileUploadSlice';
import { ProgressBar } from 'react-bootstrap';
import { showAppToast } from '../features/utilities/appToastSlice';

export function useFileUpload() {
    const dispatch = useDispatch();
    const { authToken } = useSelector((state) => state.auth);
    const { FileList } = useSelector((state) => state.FileUploadModals);
    const tokenData = jwt_decode(authToken);
    const selectedDomain = tokenData.SelectedDomain.Domain

    const [GetFilesLoading, setGetFilesLoading] = useState(false);
    const [FileUploadProgress, setFileUploadProgress] = useState(0);
    const [FileUploading, setFileUploading] = useState(false);

    function getFileNameFromUrl(url) {
        const parsedUrl = new URL(url);
        const pathname = parsedUrl.pathname;
        const fileName = pathname.split('/').pop();
        return fileName;
    }

    function ListAllFiles() {
        setGetFilesLoading(true);
        axios.get('/ai/listFiles/'+ selectedDomain)
        .then(res => {
            console.log(res);
            if(res.data && res.data.data){
                const tempAllFiles = res.data.data.reverse().map((itm) => {
                    itm.name = getFileNameFromUrl(itm.URL)
                    return itm
                });

                dispatch(setFileList(tempAllFiles))
            }
            setGetFilesLoading(false);
        })
    }

    function replaceAndReduceDashes(str) {
        // Replace spaces with dashes
        let result = str.replace(/\s+/g, '-');
        
        // Reduce multiple consecutive dashes to a single dash
        result = result.replace(/-+/g, '-');
        
        return result;
      }

    const PDFFileLimit = 5;
    const IMGFileLimit = 1;
    const IMGAllowed = ['image/jpeg', 'image/png', 'image/jpg'];
    
    function ChangeFileUpload(e) {
        // const fileData = document.getElementById('documentUpload');
        const fileData = e.target.files[0]
        console.log(fileData);
        
        if (!fileData) {
            document.getElementById('fileUpload1').reset();
            dispatch(showAppToast({ toastMessage: `Please select a file`, background: 'danger', timeout: '10000', icon: 'close-circle-outline', position: 'toast-center' }));
            // alert('Please select a file');
            return;
        }
    
        
        const FileName = replaceAndReduceDashes(fileData.name)
        const FileAlreadyExists = FileList.filter(item => item.name === FileName );
        console.log(FileAlreadyExists);
        let getConfirm;
        if(FileAlreadyExists.length > 0) {
            getConfirm = window.confirm("File with this name already exists, do you want to replace it?");
            console.log(getConfirm);
            if(!getConfirm) {
                return;
            }
        }
        

        // Validate file type
        const allowedTypes = [...IMGAllowed, 'application/pdf', 'video/mp4'];
        if (!allowedTypes.includes(fileData.type)) {
            document.getElementById('fileUpload1').reset();
            dispatch(showAppToast({ toastMessage: `Only .png, .jpg, .jpeg, .pdf, .mp4 files are allowed`, background: 'danger', timeout: '10000', icon: 'close-circle-outline', position: 'toast-center' }));
            // alert('Only .png, .jpg, .jpeg, .pdf files are allowed');
            return;
        }
    
        // Validate file size
        const maxSizePDf = PDFFileLimit * 1024 * 1024; // 5 MB in bytes
        const maxSizeImg = IMGFileLimit * 1024 * 1024; // 5 MB in bytes
        console.log(fileData);
        if (fileData.size > maxSizePDf && ('application/pdf' === fileData.type || 'video/mp4' === fileData.type)) {
            document.getElementById('fileUpload1').reset();
            dispatch(showAppToast({ toastMessage: `Max file size should be ${PDFFileLimit} MB, selected file size is ${fileData.size}`, background: 'danger', timeout: '10000', icon: 'close-circle-outline', position: 'toast-center' }));
            return;
        } else if (fileData.size > maxSizeImg && IMGAllowed.includes(fileData.type)) {
            document.getElementById('fileUpload1').reset();
            dispatch(showAppToast({ toastMessage: `Max file size should be ${IMGFileLimit} MB, selected file size is ${fileData.size}`, background: 'danger', timeout: '10000', icon: 'close-circle-outline', position: 'toast-center' }));
            return;
        }

        const formData2 = new FormData();
        formData2.append('inputFile', fileData);
        setFileUploading(true);
        axios.post('/website/uploadFiles/' + selectedDomain + '/other', formData2, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setFileUploadProgress(progress);
            }
        }).then(res => {
            console.log(res);
            if(res.data && res.data.data) {
                const tempAllFiles = [{ "URL": res.data.data.ActualPath, "name": getFileNameFromUrl(res.data.data.ActualPath), "FileName" : res.data.data.FileName}, ...FileList ]
                dispatch(setFileList(tempAllFiles));
            }
            if(getConfirm) {
                ListAllFiles();
            }
            document.getElementById('fileUpload1').reset();
            setFileUploading(false);
        }).catch(err => {
            console.log(err);
            setFileUploading(false);
            document.getElementById('fileUpload1').reset();
            alert('Something went wrong, try again');
        });
    }

    const ProgressPDFJSX = () => {
        return <>
            {FileUploading === true &&
                <ProgressBar variant="success" animated striped now={FileUploadProgress} />
            }
        </>
    }

    function replaceSpacesWithPercent20(str) {
        return str.replace(/\s/g, '%20');
      }
    return { ListAllFiles, FileList, GetFilesLoading, FileUploading, selectedDomain, ChangeFileUpload, ProgressPDFJSX, replaceSpacesWithPercent20 }
}