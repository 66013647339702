import React from 'react'

const ConnectDomainModal = ({
    
}) => {
    return (
        <div>
            <div className="modal fade dialogbox" id="DialogIconedButtonInline" data-bs-backdrop="static" tabindex="-1"
            role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Connect Domain</h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="domainName5">Domain Name</label>
                                        <input type="text" className="form-control" id="domainName5" placeholder="Enter your domain name"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="connectWith5">Connect with ?</label>
                                        <select className="form-control form-select" id="connectWith5">
                                            <option value="0">New Domain</option>
                                            <option value="1">Existing Domain</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <span className="btn btn-text-secondary" data-bs-dismiss="modal">
                                    <ion-icon name="close-outline"></ion-icon>
                                    Cancel
                                </span>
                                <span className="btn btn-text-success" data-bs-dismiss="modal">
                                    <ion-icon name="link-outline"></ion-icon>
                                    Connect
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectDomainModal
