import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
import jwt_decode from 'jwt-decode'
import { hideNavigationOffcanvas, showDeleteModal, setReorderItems } from '../../../features/modal/offcanvasSlice';
import { showAppToast } from '../../../features/utilities/appToastSlice';
import { setLoading, setWebsiteData } from '../../../features/websiteData/websiteDataSlice';
import axios from '../../../AxiosConfig';
import { extractTitles } from '../../Elements/Modals/SwitchModal';

const NavigationOffcanvas = ({
    isNavigationVisible,
    blockID,
    hideNavigationOffcanvas,
    showDeleteModal,
    showAppToast,
    setWebsiteData,
    websiteDataAppearnace,
    triggerMessage,
    setReorderItems,
    reorderItems,
    clonningblock,
    CloneBlockProps,
    appearance
}) => {
    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)
    const [contentItems, setContentItems] = useState([])
    const [renderPageFlag, setRenderPageFlag] = useState(false)
    const [showAllPagesList, setShowAllPagesList] = useState([]);

    const [searchIn, setSearchIn] = useState('');

    const [disableBtn, setDisableBtn] = useState(false);

    let blockType = ''
    if (blockID !== undefined) {
        if (blockID.includes('SM_HEAD') || clonningblock) {
            blockType = 'header'
        } else if (blockID.includes('SM_FOOT')) {
            blockType = 'footer'
        }
    }

    const fetchWebsiteJSON = () => {
        const webDoc = localStorage.getItem('website')
        const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
            return res
        } else {
            return false
        }
    }
    const websiteJSON = fetchWebsiteJSON()
    let sitemapData = []
    if(websiteJSON.appearance) {
        if (triggerMessage === '') {
            sitemapData = websiteJSON.appearance.navigation[blockType]
        } else if (triggerMessage === 'socialMedia') {
            sitemapData = websiteJSON.appearance.navigation['socialMedia']
        } else if (triggerMessage === 'ButtonReorder' || triggerMessage === 'ImageReorder') {
            sitemapData = reorderItems;
        }
    }

    // let contentItems = sitemapData
    useEffect(() => {
        if (isNavigationVisible && websiteJSON.appearance) {
            // console.log(sitemapData, websiteJSON.appearance, blockID);
            setContentItems(sitemapData)
        }
    }, [isNavigationVisible])

    const setDefaultSocialUrl = () => {
        {
            websiteJSON.appearance.navigation['socialMedia'].map((value, key) => {
                document.getElementById('socialMediaUrl' + key).value = value.url
            })
        }
    }

    const collectData = () => {
        if (triggerMessage === 'socialMedia') {
            setDefaultSocialUrl()
        }
    }

    const handleCloneBlock = (pageID) => {
        setDisableBtn(true);
        if(CloneBlockProps !== null) {
            axios.post('block/duplicateBlock/' + CloneBlockProps.DomainName + '/' + pageID + '/' + CloneBlockProps.BlockID).then(res => {
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    hideNavigationOffcanvas()
                    setDisableBtn(false);
                    showAppToast({ toastMessage: 'BLOCK CLONE SUCCESSFULL', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                } else {
                    hideNavigationOffcanvas()
                    setDisableBtn(false);
                    showAppToast({ toastMessage: 'BLOCK CLONE FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                }
            })
        }
        
    }

    const handleReuseBlock = (pageID) => {
        setDisableBtn(true);
        if(CloneBlockProps !== null) {
            axios.post('block/cloneBlock/' + CloneBlockProps.DomainName + '/' + pageID + '/' + CloneBlockProps.BlockID).then(res => {
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data);
                    hideNavigationOffcanvas();
                    setDisableBtn(false);
                    showAppToast({ toastMessage: 'BLOCK ADDED ON PAGE', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' });
                } else {
                    hideNavigationOffcanvas();
                    setDisableBtn(false);
                    showAppToast({ toastMessage: 'BLOCK ADD FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' });
                }
            })
        }
    }

    // if (isNavigationVisible) {
    const renderSitemap = ({ item, index, depth }) => {
        return (
            <ul className="listview image-listview">
                <li className="custom-line">
                    <div className="item ps-0 pt-0 pb-0" style={{ minHeight: '44px' }}>
                        <div className="icon-box bg-light me-1">
                            <ion-icon name="pause-outline"></ion-icon>
                        </div>
                        <div className="in">
                            {triggerMessage === '' &&
                                <div>
                                    {item.title}
                                    <span className='d-block' style={{'fontSize': '12px', 'lineHeight' : '14px'}}>/{appearance?.pages[item.page]?.url}</span>

                                </div>
                            }
                            {triggerMessage === 'socialMedia' &&
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control" id={'socialMediaUrl' + index} placeholder="Enter your name"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            }
                            {
                                triggerMessage === 'ButtonReorder' &&
                                <div className='w-100'>
                                    <button className={`btn btn-sm fs-10 ${item.style}`}>{item.title}</button>
                                </div>
                            }
                            {
                                triggerMessage === 'ImageReorder' &&
                                <div className='w-100'>
                                    <img
                                        src={item.src}
                                        alt={item.alt}
                                        className={`imaged w-100 border border-primary border-2 cursor-pointer fit-image ${item.style !== undefined ? item.style : ''}`}
                                    />
                                </div>
                            }
                            <div className='d-flex align-items-center'>
                                {
                                    clonningblock &&
                                    <>
                                        <button className='btn btn-sm btn-warning' title='Make a copy of block on this page' onClick={() => handleCloneBlock(item.page)}>Duplicate</button>
                                        <button className='btn btn-sm btn-primary ms-1' title='Reuse block on this page' onClick={() => handleReuseBlock(item.page)}>Reuse</button>
                                    </>
                                }
                                {
                                    !clonningblock &&
                                    <span className="text-muted icon-span" style={{ cursor: 'pointer' }} onClick={() => { handlePageHide(item.title, index, depth) }}>
                                        <ion-icon name="eye-off-outline" className="text-danger fs-6"></ion-icon>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        )
    }
    // }

    let updatedItems = []

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    useEffect(() => {
        if (isNavigationVisible && appearance.pages && contentItems.length > 0) {
            const allpageList = Object.keys(appearance.pages).map((page, i) => {
                return {
                    "id": `key${i}`,
                    "title": appearance.pages[page].properties.title,
                    "page": page,
                    "type": "",
                    "url": appearance.pages[page].url,
                    "icon": "",
                    "children": []
                }
            })
            const allHeadersPages = extractTitles(contentItems, 'page');
            const filteredAllPageList = allpageList.filter((page) => !allHeadersPages.includes(page.page))
            console.log({filteredAllPageList, contentItems});
            setShowAllPagesList(filteredAllPageList)
        }
    }, [isNavigationVisible, appearance, contentItems]);

    const submitNavigationReorder = () => {
        setSubmitButtonDisable(true)
        // console.log(contentItems)
        if (triggerMessage === '') {
            setLoading(true);
            axios.post('/appearance/navigationUpdate/' + selectedDomain + '/' + blockType, contentItems).then(res => {
                // console.log(res)
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    localStorage.setItem(
                        'website',
                        btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data))))
                    )
                    setSubmitButtonDisable(false)
                    hideNavigationOffcanvas()
                    showAppToast({
                        toastMessage: 'Reordered Successfull ',
                        background: 'success',
                        timeout: '1000',
                        icon: 'checkmark-circle-outline',
                        position: 'toast-center'
                    })
                } else {
                    setSubmitButtonDisable(false)
                    showAppToast({
                        toastMessage: 'Reordered Fail ',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                }
                setLoading(false);
            })
        } else if (triggerMessage === 'ButtonReorder' || triggerMessage === 'ImageReorder') {
            setReorderItems(contentItems);
            hideNavigationOffcanvas();
            setSubmitButtonDisable(false);
        }
    }

    const handlePageHide = (pageName, index, depth) => {
        console.log(pageName, index, depth, contentItems)
        // console.log(contentItems)
        let tempArr = [...contentItems]
        if (depth === 0) {
            tempArr.splice(index, 1)
            setContentItems(tempArr)
        } else if (depth === 1) {
            tempArr.map((value, key) => {
                if (value.hasOwnProperty('children')) {
                    if (value.children.length > 0) {
                        value.children.map((v, k) => {
                            if (k === index && v.title === pageName) {
                                tempArr[key].children.splice(index, 1)
                                setContentItems(tempArr)
                            }
                        })
                    }
                }
            })
        } else if (depth === 2) {
            tempArr.map((value, key) => {
                if (value.hasOwnProperty('children') && value.children.length > 0) {
                    value.children.map((v, k) => {
                        if (v.hasOwnProperty('children') && v.children.length > 0) {
                            v.children.map((j, i) => {
                                if (i === index && j.title === pageName) {
                                    tempArr[key].children[k].children.splice(index, 1)
                                    setContentItems(tempArr)
                                }
                            })
                        }
                    })
                }
            })
        }
    }
    
    const onPageAdd = (obj) => {
        const extractIds = extractTitles(contentItems, 'id').sort((a, b) => a - b);
        const newObj = JSON.parse(JSON.stringify(obj));
        newObj.id = extractIds[extractIds.length - 1] + 1;
        console.log(extractIds, newObj);
        setContentItems([...contentItems, newObj]);
    }
    return (
        <Modal show={isNavigationVisible} placement={'end'} backdrop={true} onEntered={collectData} id="appCapsule" data-bs-backdrop="false" tabindex="-1" role="dialog">
            <Modal.Body className="offcanvas-body ps-0 pe-0 py-0">
                <div className="profileBox webweb-profileBox position-relative w-100">
                    {triggerMessage === '' && !clonningblock &&
                        <div className="in">
                            <strong>Manage Navigation</strong>
                        </div>
                    }
                    {
                        clonningblock &&
                        <div className="in">
                            <strong>Clone / Reuse Block</strong>
                        </div>
                    }
                    {triggerMessage === 'socialMedia' &&
                        <div className="in">
                            <strong>Manage Social Media</strong>
                        </div>
                    }
                    {triggerMessage === 'ButtonReorder' &&
                        <div className="in">
                            <strong>Buttons / Links Reorder</strong>
                        </div>
                    }
                    {triggerMessage === 'ImageReorder' &&
                        <div className="in">
                            <strong>Images Reorder</strong>
                        </div>
                    }
                    <span className="close-sidebar-button" style={{ cursor: 'pointer' }} onClick={() => { hideNavigationOffcanvas() }}>
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
                <div className="section full mb-0">
                    {triggerMessage !== 'ButtonReorder' && triggerMessage !== 'ImageReorder' && !clonningblock &&
                        <div className="custom-extraHeader position-absolute p-0">
                            <ul className="nav nav-tabs lined" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#manageNavigation" role="tab" style={{ height: '46px' }}>
                                        Navigation
                                    </a>
                                </li>
                                {triggerMessage === '' &&
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#addMenu" role="tab" style={{ height: '46px' }}>
                                            Add Menu
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                    <div className="tab-content" style={{ marginTop: clonningblock ? '20px' : '50px', marginBottom: clonningblock ? '20px' : '0px' }}>
                        <div className="tab-pane fade show active" id="manageNavigation" role="tabpanel">
                            {triggerMessage === '' && !clonningblock &&
                                <div className="listview-title">
                                    Sitemap Reorder
                                </div>
                            }
                            {triggerMessage === 'socialMedia' &&
                                <div className="listview-title">
                                    Social Media Reorder
                                </div>
                            }
                            <Nestable
                                items={contentItems}
                                renderItem={renderSitemap}
                                maxDepth={triggerMessage === '' ? 3 : 1}
                                handler={clonningblock ? true : false}
                                onChange={newItems => {
                                    updatedItems = newItems.items
                                    // console.log(updatedItems)
                                    setContentItems(updatedItems)
                                }}
                            />
                            {!clonningblock && 
                                <div className="sidebar-buttons webweb-sidebar-buttons position-relative w-100 mt-2">
                                    <button
                                        className="btn btn-block btn-success"
                                        type="button"
                                        style={{ 'borderRadius': 'initial' }}
                                        disabled={submitButtonDisable}
                                        onClick={submitNavigationReorder}
                                    >
                                        <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                        {
                                            (triggerMessage === 'ButtonReorder') ?
                                                'Save' :
                                                'Update'
                                        }
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="tab-pane fade" id="addMenu" role="tabpanel">
                            <div className="listview-title row">
                                <div className='col-md-4'>
                                    <h4 className='my-0'><b>Hidden Pages</b></h4>
                                </div>
                                <div className='col-md-8'>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control ps-5" style={{height: '37px'}} id="name5" value={searchIn} placeholder="Search pages" onChange={(e) => setSearchIn(e.target.value)} autocomplete="off" />
                                            <i className="input-icon1 position-absolute t-0 mt-1 ms-1 fs-20">
                                                <ion-icon name="search-outline"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=' maxListHieght'>
                                <ul className="listview image-listview">
                                    {
                                        showAllPagesList.map((page, i) => {
                                            return <>
                                                <li key={i} className={(page?.title?.toLowerCase().includes(searchIn?.trim().toLowerCase()) || page?.url?.toLowerCase().includes(searchIn?.trim().toLowerCase()) || searchIn.trim().length === 0) ? 'custom-line' : 'd-none'} style={{ backgroundColor: '#e5e5e5' }}>
                                                    <div className="item">
                                                        <div className="in">
                                                            <div>
                                                                {page.title}
                                                                <span className='d-block' style={{'fontSize': '12px', 'lineHeight' : '14px'}}>/{appearance?.pages[page.page]?.url}</span>
                                                            </div>

                                                            <span className="text-primary" style={{ cursor: 'pointer' }} onClick={(e) => onPageAdd(page)}>
                                                                <ion-icon name="add-circle-outline" className="fs-5"></ion-icon>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isNavigationVisible,
        blockID,
        triggerMessage,
        reorderItems,
        clonningblock,
        CloneBlockProps
    } = offcanvas
    const {
        appearance
    } = websiteData
    return {
        isNavigationVisible,
        blockID,
        reorderItems,
        triggerMessage,
        clonningblock,
        CloneBlockProps,
        appearance
    }
}

const mapDispatchToProps = {
    hideNavigationOffcanvas,
    showDeleteModal,
    showAppToast,
    setWebsiteData,
    setReorderItems
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationOffcanvas)
