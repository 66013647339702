import React from 'react';
import { connect } from "react-redux"
import jwt_decode from 'jwt-decode'
import { Offcanvas } from 'react-bootstrap';
import { setSideBarMenuShow, setShowJSONModal } from '../../../features/modal/offcanvasSlice'
import { logout } from '../../../features/auth/authSlice'
import { setShowUploadFilesModal } from '../../../features/modal/fileUploadSlice'
import { Link, useNavigate } from 'react-router-dom';


function SideBarMenu({
    setSideBarMenuShow,
    sideBarMenuShow,
    setShowJSONModal,
    logout,
    setShowUploadFilesModal,
    authToken,
    FileUpload
}) {
    const token = authToken
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    const userName = tokenData.userInfo.FirstName + ' ' + tokenData.userInfo.LastName
    const userRole = tokenData.userInfo.RoleID
    console.log(FileUpload);
    const navigate = useNavigate();

    function goToPage(pageUrl) {
        navigate(pageUrl)
        
        setSideBarMenuShow(false);
    }

    const showJSONMOdal = () => {
        setSideBarMenuShow(false);
        setShowJSONModal({ JSONModal: true });
    }

    const handleLogout = () => {
        setSideBarMenuShow(false);
        logout()
        setTimeout(() => {
          navigate('/')
        }, 1000)
    }
    return (
        <Offcanvas show={sideBarMenuShow} onHide={() => setSideBarMenuShow(false)}>
            <Offcanvas.Header className='bg-primary' closeButton>
                <Offcanvas.Title className='cursonr-pointer' onClick={() => goToPage('/overview')}>
                    <strong>{userName}</strong>
                    <div className="" style={{overflow: "hidden",width: "100%"}}>
                        <small>{userRole}</small>
                    </div>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-0 position-relative'>
                <div className="section-title px-3 text-muted h5 mb-0"><b>Websites</b></div>
                <div className='appBottomMenu position-relative sidebarIconMenu border-0'>
                    <div className="item" onClick={() => goToPage('/build?url=home')}>
                        <div className="col">
                            <img src="assets/img/icon/web-design.png" style={{'width': "33px"}} />
                            <strong>Edit Website</strong>
                        </div>
                    </div>

                    <div className="item" onClick={() => goToPage('/managePages')}>
                        <div className="col">
                            <img src="assets/img/icon/landing-page.png" />
                            <strong>Web Pages</strong>
                        </div>
                    </div>

                    <div className="item" onClick={() => goToPage('/plugins')}>
                        <div className="col">
                            <img src="assets/img/icon/plugin.png" />
                            <strong>Plugins</strong>
                        </div>
                    </div>

                    <div className="item" onClick={() => goToPage('/manageEmail')}>
                        <div className="col">
                            <img src="assets/img/icon/mail-inbox.png" />
                            <strong>Email Accounts</strong>
                            <span className="badge badge-danger badge-empty">&nbsp;</span>
                        </div>
                    </div>

                    <div className="item" onClick={() => goToPage('/DSetting')}>
                        <div className="col">
                            <img src="assets/img/icon/dns.png" />
                            <strong>DNS</strong>
                            <span className="badge badge-success badge-empty">&nbsp;</span>
                        </div>
                    </div>

                    <div className="item" onClick={() => goToPage('/DSetting')}>
                        <div className="col">
                            <img src="assets/img/icon/domain.png" />
                            <strong>Domain Name</strong>
                            <span className="badge badge-danger badge-empty">&nbsp;</span>
                        </div>
                    </div>
                    {
                        (FileUpload === 't' || userRole === 'Admin') && <>
                            <div className="item" onClick={() => {
                                setShowUploadFilesModal(true);
                                setSideBarMenuShow(false);
                            }}>
                                <div className="col">
                                    <img src="assets/img/icon/documents-storage-files-svgrepo-com.svg" />
                                    <strong>Documents</strong>
                                    {/* <span className="badge badge-danger badge-empty">&nbsp;</span> */}
                                </div>
                            </div>    
                        </>
                    }
                </div>
                <div className="section-title px-3 text-muted h5 mb-0 border-top"><b>Engagement</b></div>
                <div className='appBottomMenu position-relative sidebarIconMenu border-0'>
                    <div className="item" onClick={() => goToPage('/visitorReport?report=hits')}>
                        <div className="col">
                            <img src="assets/img/icon/ctr.png" />
                            <strong>Visitor Report</strong>
                        </div>
                    </div>

                    <div className="item" onClick={() => goToPage('/forms')}>
                        <div className="col">
                            <img src="assets/img/icon/online-survey.png" />
                            <strong>Forms</strong>
                        </div>
                    </div>

                    <div className="item" onClick={() => goToPage('/chatBot')}>
                        <div className="col">
                            <img src="assets/img/icon/chat-app.png" />
                            <strong>ChatBot</strong>
                        </div>
                    </div>
                </div>
                {/* <div className="section-title px-3 text-muted h5 mb-0 border-top"><b>Reach</b></div>
                <div className='appBottomMenu position-relative sidebarIconMenu border-0'>
                    <div className="item" onClick={() => goToPage('/findBuisness')}>
                        <div className="col">
                            <img src="assets/img/icon/recruitment.png" />
                            <strong>Find Buisness</strong>
                        </div>
                    </div>

                    <div className="item" onClick={() => goToPage('/socialMedia')}>
                        <div className="col">
                            <img src="assets/img/icon/post.png" />
                            <strong>Social Media Post</strong>
                        </div>
                    </div>
                </div> */}

                <div className="appBottomMenu position-absolute">
                    {
                        userRole === 'Admin' &&
                        <span className="item" onClick={() => showJSONMOdal()}>
                            <div className="col">
                                <ion-icon name="code-working-outline"></ion-icon>
                                <strong>JSON</strong>
                            </div>
                        </span>
                    }
                    <span className="item" onClick={() => goToPage('/appInfo')}>
                        <div className="col">
                            <ion-icon name="headset-outline"></ion-icon>
                            <strong>Support</strong>
                        </div>
                    </span>
                    <span className="item" onClick={() => handleLogout()}>
                        <div className="col">
                            <ion-icon name="log-out-outline"></ion-icon>
                            <strong>Logout</strong>
                        </div>
                    </span>
                </div>
                {/* <ul className="listview image-listview menuList" style={{ height: 'auto' }}> */}
                    {/* <li className='divider-title py-2 bg-white'>Websites</li>
                    <li className='cursor-pointer' onClick={() => goToPage('/build?url=home')}>
                        <div className="item">
                            <div className="in">
                                <div>Edit Website</div>
                            </div>
                        </div>
                    </li>
                    <li className='cursor-pointer' onClick={() => goToPage('/managePages')}>
                        <div className="item">
                            <div className="in">
                                <div>Web Pages</div>
                            </div>
                        </div>
                    </li>
                    <li className='cursor-pointer' onClick={() => goToPage('/plugins')}>
                        <div className="item">
                            <div className="in">
                                <div>Plugins</div>
                            </div>
                        </div>
                    </li>
                    <li className='cursor-pointer' onClick={() => goToPage('/manageEmail')}>
                        <div className="item">
                            <div className="in">
                                <div>Email Accounts</div>
                                <span className="badge badge-danger badge-empty">&nbsp;</span>
                            </div>
                        </div>
                    </li>
                    <li className='cursor-pointer' onClick={() => goToPage('/DSetting')}>
                        <div className="item">
                            <div className="in">
                                <div>DNS</div>
                                <span className="badge badge-success badge-empty">&nbsp;</span>
                            </div>
                        </div>
                    </li>
                    <li className='cursor-pointer' onClick={() => goToPage('/DSetting')}>
                        <div className="item">
                            <div className="in">
                                <div>Domain Name</div>
                                <span className="badge badge-danger badge-empty">&nbsp;</span>
                            </div>
                        </div>
                    </li> */}
                    
                    {/* <li className='divider-title py-2 bg-white'>Engagement</li> */}

                    {/* <li className='py-3 cursor-pointer' onClick={() => goToPage('/overview')}>
                        <span>Home</span>
                    </li> */}
                    {/* <li className='cursor-pointer' onClick={() => goToPage('/visitorReport?report=hits')}>
                        <div className="item">
                            <div className="in">
                                <div>Visitor Report</div>
                            </div>
                        </div>
                    </li>
                    <li className='cursor-pointer' onClick={() => goToPage('/forms')}>
                        <div className="item">
                            <div className="in">
                                <div>Forms</div>
                            </div>
                        </div>
                    </li>
                    <li className='cursor-pointer' onClick={() => goToPage('/chatBot')}>
                        <div className="item">
                            <div className="in">
                                <div>ChatBot</div>
                            </div>
                        </div>
                    </li> */}

                    {/* <li className='divider-title py-2 bg-white'>Reach</li>
                    <li className='cursor-pointer' onClick={() => goToPage('/findBuisness')}>
                        <div className="item">
                            <div className="in">
                                <div>Find Buisness</div>
                            </div>
                        </div>
                    </li>

                    <li className='cursor-pointer' onClick={() => goToPage('/socialMedia')}>
                        <div className="item">
                            <div className="in">
                                <div>Social Media Post</div>
                            </div>
                        </div>
                    </li> */}
                    


                    {/* <li className='cursor-pointer' onClick={() => goToPage('/searchEngineOptimization')}>
                        <span>Search Engine Optimization</span>
                    </li>
                    
                    <li className='cursor-pointer' onClick={() => goToPage('/emailMarketing')}>
                        <span>Email Marketing</span>
                    </li>

                    <li className='cursor-pointer' onClick={() => goToPage('/webmail')}>
                        <span>Webmail</span>
                    </li>
                    <li className='cursor-pointer' onClick={() => goToPage('/liveChat')}>
                        <span>Live Chat</span>
                    </li> */}

                    {/* <li className='cursor-pointer' onClick={() => goToPage('/appInfo')}>
                        <span>Support</span>
                    </li>
                    <li className='cursor-pointer' onClick={() => handleLogout()}>
                        <span>Logout</span>
                    </li> */}
                {/* </ul> */}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas, appToast, websiteData, utilities, auth }) => {
    const {
        sideBarMenuShow
    } = offcanvas
    const {
        timeout
    } = appToast
    const {
        appearance: websiteDataAppearance,
    } = websiteData
    const {
        activeMenu
    } = utilities
    const {
        authToken,
        FileUpload
    } = auth
    return {
        sideBarMenuShow,
        authToken,
        FileUpload
    }
}

const mapDispatchToProps = {
    setSideBarMenuShow,
    setShowJSONModal,
    logout,
    setShowUploadFilesModal
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarMenu)