import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { Badge, Offcanvas } from 'react-bootstrap'
import { connect } from "react-redux"
import { hideEmailSettingOffcanvas } from '../../../features/modal/offcanvasSlice'

const EmailSetting = ({
    hideEmailSettingOffcanvas,
    isEmailSettingVisible
}) => {

    const [formDetails, setFormDetails] = useState({
        'name': '',
        'email': '',
        'quota': '',
        'amount': ''
    })

    const [error, setError] = useState({
        'name': '',
        'email': '',
        'quota': '',
        'amount': ''
    });

    const changeError = (field, value) => {
        const tempErrors = JSON.parse(JSON.stringify(error));
        tempErrors[field] = value;
        setError(tempErrors);
    }

    const plans = [
        {
            'quota' : '500 MB',
            'value' : '500 MB',
            'amount' : 120
        },
        {
            'quota' : '1 GB',
            'value' :  '1 GB',
            'amount' : 240
        },
        {
            'quota' : '2 GB',
            'value' :  '2 GB',
            'amount' : 480
        },
        {
            'quota' : '5 GB',
            'value' :  '5 GB',
            'amount' : 599
        },
        {
            'quota' : '10 GB',
            'value' : '10 GB',
            'amount' : 849
        },
        {
            'quota' : '25 GB',
            'value' : '25 GB',
            'amount' : 1149
        },
        {
            'quota' : '50 GB',
            'value' : '50 GB',
            'amount' : 2049
        }
    ]

    const search = useLocation().search
    const type = new URLSearchParams(search).get('type')
    const getClassNames = (tabName) => {
        return classNames('nav-link', { active: tabName === type })
    }


    const updateFormDetails = (value, name) => {
        const tempData = JSON.parse(JSON.stringify(formDetails));
        tempData[name] = value;
        console.log(name, value);
        if(value.trim().length === 0) {
            changeError(name, 'This Field is required');
        } else {
            changeError(name, '')
        }
        setFormDetails(tempData);
    }

    
    const handleEmailQuota = (quota) => {
        const tempData = JSON.parse(JSON.stringify(formDetails));
        tempData[quota] = quota;
        const rs = plans.filter((plan) => plan.value === quota);
        tempData.amount = rs[0].amount;
        changeError('quota', '');
        setFormDetails(tempData);
    }

    const onSubmit = () => {
        if(formDetails.name.trim().length === 0) {
            changeError('name','This Field is required');
            return;
        }

        if(formDetails.email.trim().length === 0) {
            changeError('email','This Field is required');
            return;
        }

        if(formDetails.quota.trim().length === 0) {
            changeError('quota','This Field is required');
            return;
        }

        console.log(formDetails);
    }

    useEffect(() => {
        if(!isEmailSettingVisible) {
            setError({
                'name': '',
                'email': '',
                'quota': '',
                'amount': ''
            })
            setFormDetails({
                'name': '',
                'email': '',
                'quota': '',
                'amount': ''
            })
        }
    }, [isEmailSettingVisible])
    
    return (
        <Offcanvas show={isEmailSettingVisible} placement={'end'} backdrop={true} scroll={true} id="appCapsule">
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0 p-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Email Setting</strong>
                    </div>
                    <a href="#!" className="close-sidebar-button" onClick={hideEmailSettingOffcanvas}>
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div className="section full mb-2">
                    <div className="custom-extraHeader position-absolute p-0">
                        <ul className="nav nav-tabs lined" role="tablist">
                            {/* <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#listEmailTab" role="tab" style={{height: '46px'}}>
                                    List Email
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#createEmailTab" role="tab" style={{ height: '46px' }}>
                                    Create Email
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="section p-0" style={{ marginTop: '50px' }}>
                        <div className="tab-content" style={{ marginTop: '50px' }}>

                            <div className="tab-pane fade show active" id="createEmailTab" role="tabpanel">
                                <div className="section full mt-2 mb-2">
                                    <div className="ps-2 pe-2">

                                        <form>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="name5">Name</label>
                                                    <input type="text" value={formDetails.name} onChange={(e) => updateFormDetails(e.target.value, 'name')} className="form-control" id="name5" placeholder="Enter your name"
                                                        autoComplete="off" />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                                {
                                                    error !== null && error?.name !== '' &&
                                                    <Badge bg="danger">{error?.name}</Badge>
                                                }
                                            </div>

                                            <div className="form-group basic">
                                                <label className="form-label" htmlFor="name5">E-mail</label>
                                                <div className="input-group">
                                                    <input type="text" value={formDetails.email} onChange={(e) => updateFormDetails(e.target.value, 'email')} className="form-control" placeholder="E-mail address" />
                                                    <span className="input-group-text ps-1 webweb-bg-secondary" style={{ fontSize: '14px' }} id="basic-addon2">@skyq.tech</span>
                                                </div>
                                                {
                                                    error !== null && error?.email !== "" &&
                                                    <Badge bg="danger">{error?.email}</Badge>
                                                }
                                            </div>

                                            <div className="form-group basic">
                                                <label className="form-label" htmlFor="quota">Quota</label>
                                                <div className="input-group">
                                                    <select className="form-control form-select" id="quota" onChange={(e) => { handleEmailQuota(e.target.value) }} placeholder='Select Quota'>
                                                    <option selected disabled>Select Quota</option>
                                                        {
                                                            plans.map((plan, i) => {
                                                                return <option key={i} value={plan.value}>{plan.quota}</option>
                                                            })
                                                        }
                                                        
                                                        {/* <option value="500mb">500 MB</option>
                                                        <option value="1gb">1 GB</option>
                                                        <option value="2gb">2 GB</option>
                                                        <option value="5gb">5 GB</option>
                                                        <option value="10gb">10 GB</option>
                                                        <option value="25gb">25 GB</option> */}
                                                    </select>
                                                    <span className="input-group-text ps-1 webweb-bg-secondary" style={{ fontSize: '14px' }} id="basic-addon2">₹ {formDetails.amount}/ year</span>
                                                </div>
                                                {
                                                    error !== null && error?.quota !== "" &&
                                                    <Badge bg="danger">{error?.quota}</Badge>
                                                }
                                            </div>

                                            <div className="sidebar-buttons webweb-sidebar-buttons">
                                                <button
                                                    className="btn btn-block btn-success"
                                                    type="button"
                                                    style={{ borderRadius: 'initial' }}
                                                    onClick={() => onSubmit()}
                                                >
                                                    <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                                    Create
                                                </button>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                            {/* <div className="tab-pane fade show active" id="listEmailTab" role="tabpanel">
                                <ul className="listview image-listview">
                                    <li className="custom-line">
                                        <a href="!#" className="item">
                                            <div className="icon-box bg-success">
                                                <ion-icon name="mail-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>
                                                    <header>Hussain</header>
                                                    hussain@skyq.tech
                                                    <footer>100 / 1024 MB</footer>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="custom-line">
                                        <a href="!#" className="item">
                                            <div className="icon-box bg-warning">
                                                <ion-icon name="mail-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>
                                                    <header>Nikhil</header>
                                                    nikhil@skyq.tech
                                                    <footer>1024 / 5120 MB</footer>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="custom-line">
                                        <a href="!#" className="item">
                                            <div className="icon-box bg-danger">
                                                <ion-icon name="mail-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>
                                                    <header>Amruta</header>
                                                    amruta@skyq.tech
                                                    <footer>100 / 100 MB</footer>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <div className="sidebar-buttons webweb-sidebar-buttons">
                                    <button
                                    className="btn btn-block btn-secondary w-50"
                                    type="button"
                                    style={{ borderRadius: 'initial' }}
                                    disabled={true}
                                    >
                                        <ion-icon name="refresh-outline"></ion-icon>{' '}
                                        Reset
                                    </button>
                                    <button
                                    className="btn btn-block btn-danger w-50"
                                    type="button"
                                    style={{ borderRadius: 'initial' }}
                                    disabled={true}
                                    >
                                        <ion-icon name="trash-outline"></ion-icon>{' '}
                                        Delete
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isEmailSettingVisible
    } = offcanvas
    return {
        isEmailSettingVisible
    }
}

const mapDispatchToProps = {
    hideEmailSettingOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSetting)