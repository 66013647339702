import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig'
import { useLocation } from "react-router-dom"
import jwt_decode from 'jwt-decode'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { hideAddPageModal, setADDClonePageProps, hideSwitchModal, showClonePageModal } from '../../../features/modal/offcanvasSlice'

const AddPageModal = ({
    setWebsiteData,
    showAppToast,
    showAddPageModal,
    hideAddPageModal,
    hideSwitchModal,
    setADDClonePageProps,
    showClonePageModal,
    websiteDataAppearnace,
}) => {
    const [smartPageList, setSmartPageList] = useState([])
    const [addBlankPage, setAddBlankPage] = useState(false)
    const [addSmartBlankPage, setAddSmartBlankPage] = useState(false)
    const [index, setIndex] = useState(false)

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const search = useLocation().search
    let url = ''
    url = new URLSearchParams(search).get('url')
    if (url === null) {
        url = localStorage.getItem('previousPage')
    } else {
        url = new URLSearchParams(search).get('url')
        localStorage.setItem('previousPage', url)
    }

    let ThisPageName = '';
    if(websiteDataAppearnace !== null && websiteDataAppearnace !== undefined && websiteDataAppearnace.pages !== undefined && websiteDataAppearnace.pages !== null) {
        Object.keys(websiteDataAppearnace.pages).map((value, key) => {
            if (websiteDataAppearnace.pages[value].url === url) {
                ThisPageName = websiteDataAppearnace.pages[value].properties.title;
                // console.log(websiteDataAppearnace.pages[value].properties.title)
            }
        })
    }

    const handleAddPage = () => {
        setAddBlankPage(true)
        axios.post('/page/'+ selectedDomain + '/new%20Page').then(res=>{

            if (res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                hideAddPageModal()
                showAppToast({ toastMessage: 'NEW PAGE ADDED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                setAddBlankPage(false)
            } else {
                hideAddPageModal()
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                setAddBlankPage(false)
            }
        })
    }

    const listSmartPage = () => {
        axios.post('/page/smartPages').then(res=>{
            // console.log(res.data.data)
            const response = res.data.data
            setSmartPageList(response)
            // console.log(smartPageList)
        })
    }

    useEffect(() => {
          listSmartPage()
    }, [])

    const handleAddSmartPage = (id, pageid) => {
        // console.log(id)
        setIndex(pageid)
        setAddSmartBlankPage(true)
        // console.log(index)
        axios.post('/page/'+ selectedDomain + '/newPage/' + id).then(res=>{

            if (res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                hideAddPageModal()
                showAppToast({ toastMessage: 'NEW PAGE ADDED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                setAddSmartBlankPage(false)
            } else {
                hideAddPageModal()
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                setAddSmartBlankPage(false)
            }
        })
    }

    const showPageAddPopUp = (type, copies) => {
        if(type === 'Add') {
            setADDClonePageProps({'CloneOrAdd': 'Add', 'Copies': copies});
        } else {
            setADDClonePageProps({'CloneOrAdd': 'Clone', 'Copies': copies})
        }
        hideSwitchModal()
        hideAddPageModal()
        showClonePageModal({isClonePageModalVisible: true});
    }

    return (
        <div>
            <Modal class="modal fade modalbox" id="addpageModal" data-bs-backdrop="false" tabindex="-1" role="dialog" show={showAddPageModal} fullscreen={true}>
                {/* <div class="modal-dialog" role="document"> */}
                    <div class="modal-content">
                        <Modal.Header class="modal-header">
                            <h5 class="modal-title">Add page</h5>
                            <span class="text-primary" style={{cursor: 'pointer'}} onClick={() => {hideAddPageModal()}}>Close</span>
                        </Modal.Header>
                        <Modal.Body class="modalBbody p-0">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div class="listview-title mt-2">Blank Page</div>
                                    <div class="pe-2 ps-2">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12 mb-2" onClick={() => showPageAddPopUp('Add', 'Single')} style={{cursor: 'pointer'}}>
                                                <div style={{height:'120px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover', borderRadius: '8px'}} class="d-flex justify-content-center align-items-center text-light flex-column">
                                                    {addBlankPage &&
                                                        <button class="btn border-0 bg-transparent text-light" type="button">
                                                            <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                                            Adding...
                                                        </button>
                                                    }
                                                    Blank Page
                                                </div>
                                                {/* <img src="assets/img/sample/photo/1.jpg" alt="Template" class="imaged w-100" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div class="listview-title mt-2">Duplicate This Page - {ThisPageName.length > 0 ? `(${ThisPageName})` : ''}</div>
                                    <div class="pe-2 ps-2">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12 mb-2" style={{cursor: 'pointer'}}>
                                                <div style={{height:'120px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover', borderRadius: '8px'}} class="d-flex justify-content-center align-items-center text-light flex-column">

                                                    <div>
                                                        <button className='btn btn-sm btn-dark' onClick={() => showPageAddPopUp('Clone', 'Single')}>Single</button>
                                                        <button className='btn btn-sm btn-outline-dark ms-1' onClick={() => showPageAddPopUp('Clone', 'Multiple')}>Multiple</button>
                                                    </div>
                                                </div>
                                                {/* <img src="assets/img/sample/photo/1.jpg" alt="Template" class="imaged w-100" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="listview-title mt-2">From a Template</div>
                            <div class="pe-2 ps-2">
                                <div class="row">
                                    {smartPageList.map((item, key) => {
                                        return <div class="col-6 col-sm-3 col-md-2 col-lg-2 mb-2" style={{cursor: 'pointer'}} onClick={() => {handleAddSmartPage(item.PageID, key)}}>
                                            <div style={{height:'120px', width: 'auto', backgroundImage: 'url(assets/img/gradiant.png)', backgroundSize: 'cover', borderRadius: '8px'}} class="d-flex justify-content-center align-items-center text-light flex-column">
                                                {(addSmartBlankPage && key === index) &&
                                                    <button class="btn border-0 bg-transparent text-light" type="button">
                                                        <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                                        Adding...
                                                    </button>
                                                }
                                                {item.PageTitle}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div> */}
                        </Modal.Body>
                    </div>
                {/* </div> */}
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        showAddPageModal
    } = offcanvas
    const {
        appearance: websiteDataAppearnace
    } = websiteData
    return {
        showAddPageModal,
        websiteDataAppearnace
    }
}
  
const mapDispatchToProps = {
    setWebsiteData,
    showAppToast,
    hideAddPageModal,
    setADDClonePageProps,
    showClonePageModal,
    hideSwitchModal
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPageModal)
