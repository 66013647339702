import React from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideDomainSettingOffcanvas } from '../../../features/modal/offcanvasSlice'
import DnsContact from '../../Pages/DnsContact'
import DnsManagement from '../../Pages/DnsManagement'
import DnsTransfer from '../../Pages/DnsTransfer'

const DomainSetting = ({
    hideDomainSettingOffcanvas,
    isDomainSettingVisible
}) => {
    const search = useLocation().search
    const type = new URLSearchParams(search).get('type')
    const getClassNames = (tabName) => {
        return classNames('nav-link', { active: tabName === type })
    }
    return (
        <Offcanvas show={isDomainSettingVisible} placement={'end'} backdrop={true} scroll={true} id="appCapsule">
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0 p-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Domain Setting</strong>
                    </div>
                    <a href="#!" className="close-sidebar-button" onClick={hideDomainSettingOffcanvas}>
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div className="section full mb-2">
                    <div className="custom-extraHeader position-absolute p-0">
                        <ul className="nav nav-tabs lined" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#dnsTab" role="tab" style={{height: '46px'}}>
                                    DNS
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#contactTab" role="tab" style={{height: '46px'}}>
                                    Contact
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#transferTab" role="tab" style={{height: '46px'}}>
                                    Transfer
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="section" style={{ marginTop: '50px' }}>
                        <div className="tab-content" style={{ marginTop: '50px' }}>
                            <div className="tab-pane fade show active" id="dnsTab" role="tabpanel">
                                <DnsManagement />
                            </div>
                            <div className="tab-pane fade" id="contactTab" role="tabpanel">
                                <DnsContact />
                            </div>
                            <div className="tab-pane fade" id="transferTab" role="tabpanel">
                                <DnsTransfer />
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isDomainSettingVisible
    } = offcanvas
    return {
        isDomainSettingVisible
    }
}
  
const mapDispatchToProps = {
    hideDomainSettingOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainSetting)