import React, { useEffect, useState } from 'react'
import { Button, Offcanvas } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setShowUploadFilesModal } from '../../../features/modal/fileUploadSlice'
import FileCard from '../Comps/FileCard'
import { useFileUpload } from '../../../Hooks/useFileUpload'


export const FileUploadModal = ({ showUploadFilesModal, setShowUploadFilesModal, selectedDomain, aliasDomainValue, userRole }) => {

    const { ListAllFiles, FileList, GetFilesLoading, FileUploading, ChangeFileUpload, ProgressPDFJSX, replaceSpacesWithPercent20 } = useFileUpload();

    const [searchText, setSearchText] = useState('');
    useEffect(() => {
      if(showUploadFilesModal) {
        ListAllFiles();
      }
    }, [showUploadFilesModal])
    
    return (
        <>
            <Offcanvas show={showUploadFilesModal} backdrop="static" onHide={() => setShowUploadFilesModal(false)} placement="bottom" name="bottom"  style={{'height' : '80vh'}}>
                <Offcanvas.Header>
                    <div className='d-flex gap-3 align-items-center'>
                        <img src="assets/img/icon/documents-storage-files-svgrepo-com.svg" style={{'width' : '40px'}} />
                        <h3 className='mb-0'>Documents</h3>
                    </div>
                    <div class="wide-block pt-0 pb-0 ms-auto border-0 px-0" style={{'maxWidth' : '250px'}}>
                        <form class="search-form">
                            <div class="form-group searchbox">
                                <input type="text" class="form-control" placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                <i class="input-icon">
                                    <ion-icon name="search-outline"></ion-icon>
                                </i>
                            </div>
                        </form>
                    </div>
                    <Button className='ms-1' variant='primary' size='sm' disabled={GetFilesLoading || FileUploading} onClick={() => setShowUploadFilesModal(false)}>
                        Close
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body className='position-relative' style={{'height' : '76vh', 'overflow' : 'hidden', 'overflowY' :'auto'}}>
                    <div className='position-absolute t-0 s-0 w-100' style={{'top' : '-6px'}}>
                        <ProgressPDFJSX />
                    </div>
                    <div className='row position-relative'>
                        <div className={`col-sm-6 col-md-3 col-lg-2 mb-2 ${(GetFilesLoading || FileUploading) ? 'd-none' : ''}`}>
                            <form className="custom-file-upload h-100" id="fileUpload1" style={{"aspectRatio": "3 / 2"}}>
                                <input type="file" id="documentUpload" disabled={GetFilesLoading} onChange={ChangeFileUpload} accept=".png, .jpg, .jpeg, .pdf, .mp4" />
                                <label for="documentUpload">
                                    <span>
                                        <strong>
                                            <ion-icon name="cloud-upload-outline"></ion-icon>
                                            <i>Tap to Upload PDF / Images / Video</i>
                                        </strong>
                                    </span>
                                </label>
                            </form>
                        </div>
                        {
                            (GetFilesLoading || FileUploading) &&
                            <div className='col-sm-6 col-md-3 col-lg-2 mb-2 d-flex justify-content-center align-items-center loaderDiv border border-radius'>
                                <div className="GetFileloader"></div>
                            </div>
                        }
                        {
                            FileList && FileList.map((itm) => {
                                return <>
                                {
                                    itm.name.length > 0 &&
                                    <div className={`col-sm-6 col-md-3 col-lg-2 mb-2 d-flex justify-content-center align-items-center ${(itm.name.toLowerCase().includes(replaceSpacesWithPercent20(searchText).toLocaleLowerCase()) || searchText.trim().length === 0) ? '' : 'd-none'}`}>
                                        <FileCard data={itm} selectedDomain={selectedDomain}  aliasDomainValue={aliasDomainValue} userRole={userRole} replaceSpacesWithPercent20={replaceSpacesWithPercent20} />
                                    </div>
                                }
                                </>
                            })
                        }
                        
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

const mapStateToProps = ({ FileUploadModals }) => {
    const { showUploadFilesModal } = FileUploadModals
    return {
        showUploadFilesModal
    }
}

const mapDispatchToProps = {
    setShowUploadFilesModal
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadModal)