import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideSiteSettingOffcanvas, showDomainSettingOffcanvas } from '../../../features/modal/offcanvasSlice'
import { setWebsiteData, setInfoData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'

const SiteSetting = ({
    hideSiteSettingOffcanvas,
    isSiteSettingVisible,
    showDomainSettingOffcanvas
}) => {

    const handleBusinessInfoSubmit = () => {
        console.log('Business Info Submitted')
    }

    const fetchWebsiteJSON = () => {
        const webDoc = localStorage.getItem('website')
        const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
          return res
        } else {
          return false
        }
    }

    const websiteJSON = fetchWebsiteJSON()

    const favicon = websiteJSON.appearance && websiteJSON.appearance.properties.favicon

    const handleDomainSetting = () => {
        hideSiteSettingOffcanvas()
        showDomainSettingOffcanvas({isDomainSettingVisible: true})
    }

    return (
        <Offcanvas show={isSiteSettingVisible} placement={'end'} backdrop={true} scroll={true} id="appCapsule">
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0 p-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Site Setting</strong>
                    </div>
                    <a href="#!" className="close-sidebar-button" onClick={() => {hideSiteSettingOffcanvas()}}>
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div className="section full mb-2">
                    <div className="tab-content">
                        <div className="section full mt-2 mb-2" style={{marginTop: '50px'}}>
                            <form className="ps-2 pe-2" onSubmit={handleBusinessInfoSubmit}>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label mb-0" htmlFor="businessName">
                                            <div className="row">
                                                <div className="col-12">
                                                    Business Name
                                                </div>
                                            </div>
                                        </label>
                                        <input type="text" className="form-control form-control-sm" name="businessName" id="businessName" placeholder="Enter your business name" autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label mb-0" htmlFor="ownerName">
                                            <div className="row">
                                                <div className="col-12">
                                                    Owner Name
                                                </div>
                                            </div>
                                        </label>
                                        <input type="text" className="form-control form-control-sm" name="ownerName" id="ownerName" placeholder="Enter your owner name" autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label mb-0" htmlFor="language">
                                            <div className="row">
                                                <div className="col-12">
                                                    Language
                                                </div>
                                            </div>
                                        </label>
                                        <select className="form-control form-control-sm form-select" name="language" id="language">
                                            <option value="en_IN" selected>English</option>
                                            <option value="hi_IN">Hindi</option>
                                            <option value="mr_IN">Marathi</option>
                                            <option value="tl_IN">Telugu</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label mb-0" htmlFor="favicon">Favicon</label>
                                            </div>
                                        </div>
                                        <img src={favicon} alt="favicon" className="w48" />
                                    </div>
                                </div>
                                <div className="sidebar-buttons webweb-sidebar-buttons">
                                    <button className="btn btn-block btn-success" type="submit" style={{borderRadius: "initial"}} disabled>
                                        <ion-icon name="checkmark-outline"></ion-icon> Update
                                    </button>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label
                                            className="form-label mb-2"
                                            htmlFor="navigation"
                                        >
                                        </label>
                                        <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={handleDomainSetting}>Domain Setting</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isSiteSettingVisible
    } = offcanvas
    const {
        appearance: websiteDataAppearnace,
        blocks: websiteDataBlocks,
        contents: websiteDataContents,
        fonts,
        templates,
        cropperConfig
      } = websiteData
    return {
        websiteDataAppearnace,
        websiteDataBlocks,
        websiteDataContents,
        fonts,
        templates,
        cropperConfig,
        isSiteSettingVisible
    }
}
  
const mapDispatchToProps = {
    hideSiteSettingOffcanvas,
    setWebsiteData,
    showAppToast,
    setInfoData,
    showDomainSettingOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteSetting)