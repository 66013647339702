import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, Link } from "react-router-dom"
import axios from '../../../AxiosConfig'
import { connect } from "react-redux"
import { setWebsiteData, setBlockRules, setLoading } from '../../../features/websiteData/websiteDataSlice'
import { showSwitchModal, showPopupBlockOffcanvas, showPageSetting } from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'

import jwt_decode from 'jwt-decode'

function AddressBar({
    loading,
    setLoading,
    functionObject,
    showSwitchModal,
    showPopupBlockOffcanvas,
    device,
    showAppToast,
    websiteDataAppearnace = {},
    websiteDataBlocks = {},
    showPageSetting
}) {
    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    // console.log(tokenData)
    const selectedDomain = tokenData.SelectedDomain.Domain
    // const aliasDomain = tokenData.SelectedDomain.AliasDomain
    // console.log(aliasDomain)
    const domainName = tokenData.SelectedDomain
    const aliasDomainValue = (domainName.AliasDomain !== '' && domainName.AliasDomain !== null) ? domainName.AliasDomain : domainName.Domain
    const roleID = tokenData.userInfo.RoleID;
//   const aliasDomainValue = (domainName.AliasDomain !== '' && domainName.AliasDomain !== null) ? domainName.AliasDomain : domainName.Domain



    const [launchValue, setLaunchValue] = useState();
    const [urlShow, setUrlShow] = useState(true);
    const [popupButtonFlag, setPopupButtonFlag] = useState(false)

    useEffect(() => {
        let appWidth = null
        if (device === 'mobile') {
          const appCapsule = document.getElementById("appCapsule")
          if(appCapsule) {
            appCapsule.style.width = "35%"
            appWidth = appCapsule.offsetWidth
          }
        }
        else if (device === 'desktop' || device === undefined || device === '') {
          const appCapsule = document.getElementById("appCapsule")
          if(appCapsule) {
            appCapsule.style.width = "100%"
            appWidth = appCapsule.offsetWidth
          }
        }
        if (appWidth > 411) {
          setUrlShow(true)
        } else {
          setUrlShow(false)
        }
    
      }, [device, url])
    const handleBack = () => {
        window.history.go(-1)
    }

    const handleRefresh = () => {
        setLoading(true)
        axios.get('/user/refresh?domain=' + selectedDomain + '&type=website').then(res => {
            // console.log('Refresh Token')
            localStorage.setItem('token', res.data.data)
            setLoading(false)
        })
        // if (functionObject.fetchWebsite) {
        //   const { fetchWebsite } = functionObject;
        //   fetchWebsite();
        //   setLoading(false)
        // }
    }

    const handleSwitchModal = () => {
        showSwitchModal({ isSwitchModalVisible: true, switchModalMessage: 'changePage', blockID: 'SM_HEAD' })
    }

    const handlePreview = (e) => {
        e.stopPropagation()
        console.log('Preview');
        window.open('https://' + selectedDomain + '/' + url)
        // showPopupBlockOffcanvas({isPopupBlockVisible: true})
    }

    const handlePopupBlock = (e) => {
        e.stopPropagation()
        showPopupBlockOffcanvas({ isPopupBlockVisible: true })
    }

    const handlePageSetting = (activeTabName) => {
        showPageSetting({ isPageSettingVisible: true, defaultActiveTab: activeTabName })
    }

    const handleLaunchSite = (e) => {
        e.preventDefault()
        // console.log('Site Launched')
        setLaunchValue(true)
        axios.get('/website/publishWebsite/' + selectedDomain).then(res => {
          // setResponsiveIframe({runPartyParticals: true})
          showAppToast({ toastMessage: 'Your changes updated to your live website', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
          setLaunchValue(false)
        })
    }

    useEffect(() => {
        if (websiteDataAppearnace.hasOwnProperty('pages')) {
          let pageId = 'Not Found'
          let pageName = 'Not Found'
          Object.keys(websiteDataAppearnace.pages).map((value, key) => {
            if (websiteDataAppearnace.pages[value].url === url) {
              pageId = value
              websiteDataAppearnace.navigation.header.map((v, k) => {
                if (v.hasOwnProperty('children')) {
                  websiteDataAppearnace.navigation.header[k].children.map((v1, k1) => {
                    if (v1.page === pageId) {
                      pageName = v1.text
                    }
                  })
                } else {
                  if (v.page === pageId) {
                    pageName = v.text
                  }
                }
              })
            }
          })
          let pageBlocks = []
          const currentPage = pageId
          // console.log(currentPage)
          pageBlocks = websiteDataAppearnace.pages.hasOwnProperty(currentPage) ? websiteDataAppearnace.pages[currentPage].blocks : false
          Object.keys(websiteDataBlocks).map((value, key) => {
            if (value.includes('PL_')) {
              pageBlocks = [...pageBlocks, value]
            }
          })
          setPopupButtonFlag(false)
          // console.log(pageBlocks)
          if (typeof (pageBlocks) !== 'boolean') {
            pageBlocks.map(value => {
              if (value.includes('POUP')) {
                setPopupButtonFlag(true)
              }
            })
          }
        }

    }, [websiteDataAppearnace, websiteDataBlocks])

    const urlLink = new URLSearchParams(search).get('url');
    const showPageSettingPopup = () => {
        if(urlLink !== undefined && urlLink !== null && urlLink !== '') {
            handlePageSetting('seo');
        }
    }
    return (
        <div>
            <div className="wide-block p-0 mb-3 webweb-responsive-addressbar mx-0">

                <div className="row w-100 m-0" style={{ paddingLeft: '1%', paddingRight: '12px', backgroundColor: '#fbfbfb', boxShadow: '0 2px 4px 0 rgb(0 0 0 / 2%), 0 1px 3px 0 rgb(0 0 0 / 20%)' }}>
                    <div className="col-2 col-md-1 col-lg-1">
                        <div className="w-100 d-flex" style={{ paddingTop: '10px' }}>
                            <div className="w-50">
                                <button type='button' className='use-button' onClick={handleBack} >
                                    <ion-icon name="arrow-back-outline" className="fs-5 text-secondary" style={{ cursor: 'pointer' }}></ion-icon>
                                </button>
                            </div>
                            <div className="w-50">
                                {
                                    loading ?
                                        <div className="">
                                            <ion-icon name="refresh-outline" className="fs-5 text-secondary rotatingAnimation" style={{ cursor: 'pointer' }}></ion-icon>
                                        </div>
                                        :
                                        <button type='button' className='use-button' onClick={handleRefresh}>
                                            <ion-icon name="refresh-outline" className="fs-5 text-secondary" style={{ cursor: 'pointer' }}></ion-icon>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-8 col-md-9 col-lg-10 p-0">
                        <div
                            className="form-group boxed"
                        >
                            <div className="input-wrapper">
                                <div
                                    className="form-control headerButton rounded-pill custom-disabled-color d-flex align-items-center"
                                    style={{ paddingLeft: '10px !important', fontSize: '14px', height: '28px' }}
                                >
                                    <div style={{ width: '100%' }}>
                                        <ion-icon
                                            name="lock-closed"
                                            data-bs-toggle="tooltip"
                                            data-placement="bottom"
                                            data-bs-html="true"
                                            title="<ion-icon name='lock-closed-outline'></ion-icon> Your connection is secure"
                                            style={{
                                                position: 'absolute',
                                                fontSize: '18px !important',
                                                zIndex: 1
                                            }}
                                            class="text-success header-address-bar"
                                        ></ion-icon>
                                        <div className='d-flex w-100 cursor-pointer' style={!urlShow ? { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '60%' } : {}}>

                                            {aliasDomainValue === '' &&
                                                <div className='d-flex'>
                                                    <button type='button' className="text-secondary d-flex border-0" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', 'maxWidth': '100%', 'overflow': 'hidden' }}
                                                        onClick={handleSwitchModal}>&nbsp;https://{selectedDomain}/{url} </button>
                                                    <button type='button' className='use-button' onClick={(e) => { handlePreview(e) }}>
                                                        <ion-icon name="open-outline"></ion-icon>
                                                    </button>
                                                </div>
                                            }

                                            {aliasDomainValue !== '' &&
                                                <div className='d-flex' style={{ 'maxWidth': '80%' }}>
                                                    <button type='button' className="text-secondary d-flex border-0" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', 'maxWidth': '100%', 'overflow': 'hidden' }}
                                                        onClick={handleSwitchModal}>&nbsp;https://{aliasDomainValue}/{url} </button>
                                                    <button type='button' className='use-button' onClick={(e) => { handlePreview(e) }}>
                                                        <ion-icon name="open-outline" ></ion-icon>
                                                    </button>
                                                </div>
                                            }

                                            {
                                                window.location.pathname === '/build' &&
                                                <button type='button' className="text-danger border-0" onClick={() => showPageSettingPopup()}>
                                                    &nbsp;&nbsp;Page Settings
                                                </button>
                                            }


                                            {popupButtonFlag &&
                                                <span className="text-danger d-none d-sm-block" style={{ position: 'absolute', right: 13, top: 2 }} onClick={(e) => { handlePopupBlock(e) }}>
                                                    &nbsp;&nbsp;Popup Block
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 col-md-2 col-lg-1 d-flex align-items-center justify-content-md-center">
                        {(aliasDomainValue !== '' && aliasDomainValue !== null && aliasDomainValue !== undefined) &&
                            <>
                                {launchValue &&
                                    <button className="btn btn-primary btn-sm" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                        Publishing...
                                    </button>
                                }
                                {!launchValue &&
                                    <div>
                                        <button className="btn btn-primary btn-sm" disabled={window.location.pathname !== '/build' && window.location.pathname !== '/builder'} onClick={handleLaunchSite}>
                                            <span className='d-block d-md-none'>
                                                <ion-icon name="megaphone-outline"></ion-icon>
                                            </span>
                                            <span className='d-none d-md-block'>Publish</span>
                                        </button>
                                    </div>
                                }
                            </>
                        }
                        {(aliasDomainValue === '' || aliasDomainValue === null || aliasDomainValue === undefined) &&
                            <Link to="/searchDomain" className="text-primary" style={{ fontSize: '10px' }}>
                                <button className="btn btn-success btn-sm">Go Live</button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData, utilities }) => {
    const {
    } = offcanvas
    const {
        appearance: websiteDataAppearnace,
        websiteDataBlocks,
        appearance,
        loading,
    } = websiteData
    const {
        device
    } = utilities
    return {
        appearance,
        loading,
        device,
        websiteDataAppearnace,
        websiteDataBlocks
    }
}

const mapDispatchToProps = {
    setLoading,
    showSwitchModal,
    showPopupBlockOffcanvas,
    showAppToast,
    showPageSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressBar)