import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import axios from '../../../AxiosConfig'
import { connect } from "react-redux"
import jwt_decode from 'jwt-decode'
import { hideTemplateOffcanvas } from '../../../features/modal/offcanvasSlice'
import { setInfoData } from '../../../features/websiteData/websiteDataSlice'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'

const Templates = ({
    show,
    hideTemplateOffcanvas,
    templates,
    setInfoData,
    appearance,
    showAppToast,
    setWebsiteData
}) => {
    const [templateList, setTemplateList] = useState([])
    const [activeLoader, setActiveLoader] = useState(false)
    const [index, setIndex] = useState(null)

    useEffect(() => {
        if (templates) {
            if (templates.length === 0 || templates === undefined) {
                axios.get('info/style/templates').then(res=>{
                    if (res.data.message === 'Success') {
                        setTemplateList(res.data.data)
                    } else {
                        console.log("SOMETHING WENT WRONG")
                    }
                })
            } else {
                setTemplateList(templates)
            }
        }
    }, [templates])

    useEffect(() => {
        if (templateList.length > 0) {
            setInfoData({
                fonts: [],
                templates: templates,
                cropperConfig: []
            })
            // console.log(templates, templateList)
        }
    }, [templateList])

    let oldTemplateName = ''
    const collectData = () => {
        if (appearance !== undefined) {
            if (oldTemplateName === '') {
                oldTemplateName = appearance.properties.template
                // console.log(oldTemplateName)
            }
        }
        // console.log(templates, appearance, oldTemplateName)
    }

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const handleTemplateChange = (k, templateName) => {
        setActiveLoader(true)
        setIndex(k)
        axios.put('appearance/' + selectedDomain, {"template": templateName}).then(res=>{

            if (res.data.message === 'Success') {
                setActiveLoader(false)
                hideTemplateOffcanvas()
                setWebsiteData(res.data.data)
                showAppToast({ toastMessage: 'UPDATED SUCCESSFULLY', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
            } else {
                setActiveLoader(false)
                hideTemplateOffcanvas()
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
            }
        })
    }

    return (
        <Offcanvas show={show} placement={'end'} backdrop={true} scroll={true} onEntered={collectData} id="appCapsule">
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Templates</strong>
                    </div>
                    <span className="close-sidebar-button" style={{cursor:'pointer'}} onClick={hideTemplateOffcanvas}>
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
                <div className="section full mb-2">
                    <div className="row ms-1 me-1">
                        {templateList.map((item, key) => {
                            return (<div className="col-6 col-sm-6 col-md-6 col-lg-6 mb-2" key={key} style={{cursor: 'pointer'}}>
                                <div style={{height:'100px', width: 'auto', backgroundImage: 'url(assets/img/sample/photo/1.jpg)', backgroundSize: 'cover', borderRadius: '8px'}} className={(oldTemplateName === item.Name) ? 'border-primary d-flex justify-content-center align-items-center text-light flex-column border' : 'd-flex justify-content-center align-items-center text-light flex-column border'} onClick={() => {handleTemplateChange(key, item.Name)}}>
                                    {(activeLoader && (key === index)) &&
                                        <button className="btn border-0 bg-transparent text-light" type="button">
                                            <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                            Changing...
                                        </button>
                                    }
                                    {item.Name}
                                </div>
                                {/* <img src="assets/img/sample/photo/1.jpg" alt="Template" className="imaged w-100" /> */}
                            </div>)
                        })}
                    </div>
                </div>
                {/* <div className="sidebar-buttons webweb-sidebar-buttons">
                    <a href="#!" className="btn btn-block btn-success" style={{borderRadius: "initial"}}>
                        <ion-icon name="cloud-upload-outline"></ion-icon> Update
                    </a>
                </div> */}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ websiteData }) => {
    const {
        templates,
        appearance
    } = websiteData
    return {
        templates,
        appearance
    }
  }
  
  const mapDispatchToProps = {
    hideTemplateOffcanvas,
    setInfoData,
    showAppToast,
    setWebsiteData
  }

export default connect(mapStateToProps, mapDispatchToProps)(Templates)
