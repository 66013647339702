import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  domains:'',
  websites:'',
  userInfo:'',
  type:'',
  activeMenu: ''
}

export const utilitiesSlice = createSlice({
  name: 'utilities',
  initialState,
  reducers: {
    updateUtilities: (state, action) => {
      const {
        domains,
        websites,
        userInfo,
        type
      } = action.payload
      state.domains = domains
      state.websites = websites
      state.userInfo = userInfo
      state.type = type
    },
    updateDeviceSize: (state, action) => {
      const {
        device
      } = action.payload
      state.device = device
    },
    setActiveMenu: (state, action) => {
      const {
        activeMenu
      } = action.payload
      state.activeMenu = activeMenu
    }
  }
})

export const { updateUtilities, updateDeviceSize, setActiveMenu } = utilitiesSlice.actions;


export default utilitiesSlice.reducer
