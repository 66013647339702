import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { useNavigate, useLocation } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { showOffcanvas, hideOffcanvas, showPropertyOffcanvas, showDeleteModal, showAddOffcanvas, addBlockModal, showAddBlockOffcanvas, setNewBlockType, showUpdateDataOffcanvas, showNavigationOffcanvas, setCloneBlockProps } from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'

const ToolBar = ({
    blockType,
    blockID,
    seq,
    items,
    properties,
    showDeleteModal,
    showOffcanvas,
    setWebsiteData,
    showAddOffcanvas,
    showAppToast,
    addBlockModal,
    contentCount,
    itemCount,
    showAddBlockOffcanvas,
    websiteDataBlocks,
    cssID,
    setNewBlockType,
    showUpdateDataOffcanvas,
    showNavigationOffcanvas,
    setCloneBlockProps
}) => {
    const [linkDirection, setLinkDirection] = useState('')
    const [linkStep, setLinkStep] = useState('')
    const [blockStyleList, setBlockStyleList] = useState({})
    const [sitemapList, setSitemapList] = useState([{}])
    const [itemFilterType, setItemFilterType] = useState('')
    const [noItems, setNoItems] = useState(0)

    let navigate = useNavigate()
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    const roleID = tokenData.userInfo.RoleID
    const aliasDomain = tokenData.SelectedDomain.AliasDomain

    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    const currentPage = useLocation().pathname.split('/')[1]

    const fetchWebsiteJSON = () => {
        const webDoc = localStorage.getItem('website')
        const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
            return res
        } else {
            return false
        }
    }

    const websiteJSON = fetchWebsiteJSON()
    // const selectedPage = websiteJSON.appearance.properties.defaultPage

    function AddNewItem() {
        return (
            <div>
                <a class="dropdown-item" href="#!" onClick={() => { handleAddNewItem('first') }}>
                    <ion-icon name="add-outline"></ion-icon> New Item
                </a>
                <a class="dropdown-item" href="#!" onClick={() => { handleAddNewItem('last') }}>
                    <ion-icon name="caret-down-outline"></ion-icon> New Last Item
                </a>
            </div>
        )
    }

    const handleAddNewItem = (contentPosition) => {
        showAddOffcanvas({ isAddOffcanvasVisible: true, addMessage: 'Content', blockid: blockID, contentPosition: contentPosition })
    }

    const allowAddNewItem = (blockType === 'CN') ? true : false
    if (allowAddNewItem === true) {
        var AddNewItemVariable = <AddNewItem />
    }

    // useEffect(() => {
    //     axios.get('/block/getBlockStyles/' + properties.style + '/' + selectedDomain).then(res => {
    //         if (res.data.message === 'Success') {
    //             setBlockStyleList(res.data.data)
    //         } else {
    //             console.log('FAIL BLOCK STYLE LIST')
    //         }
    //     })
    // }, [properties.styleID])

    const handleBlockStyleUpdate = (newStyleName) => {
        // event.preventDefault()
        const styleData = newStyleName.split('_')
        const blockType = styleData[0]
        const blockName = styleData[1]
        const data = { 'style': blockType + '_' + blockName, 'styleID': newStyleName }
        axios.put('/block/' + selectedDomain + '/' + blockID, data).then(res => {
            if (res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                showAppToast({ toastMessage: 'PROPERTIES UPDATED ', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
            } else {
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
            }
        })
    }

    const BlockStyleName = ({
        name
    }) => {
        return (
            <>
                <ion-icon name="list-outline"></ion-icon> {name}
            </>
        )
    }

    const handleBlockUpdate = (msg) => {
        showOffcanvas({ isVisible: true, items: items, itemCount: contentCount, properties: properties, blockType: blockType, blockID: blockID, msg: msg })
    }

    const handleNewEditItem = (msg) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': 0 });
        if(blockID.slice(0, 2) === 'CN') {
            showUpdateDataOffcanvas({
                isEditVisible: true,
                itemID: 0,
                items: items,
                blockID: blockID,
                properties: properties,
                ActiveOffcanvasParentTab: 'Layers',
                ActiveOffcanvasChildTab: 'Block'
            })
        } else {
            if(blockID.slice(0, 7) === 'SM_HEAD' || blockID.slice(0, 7) === 'SM_FOOT') {
                showUpdateDataOffcanvas({
                    isEditVisible: true,
                    items: items,
                    blockID: blockID,
                    properties: properties,
                    blockType: blockType,
                    msg: msg,
                    ActiveOffcanvasParentTab: 'Layers',
                    ActiveOffcanvasChildTab: 'Block'
                })
            } else {
                showUpdateDataOffcanvas({
                    isEditVisible: true,
                    items: items,
                    blockID: blockID,
                    properties: properties,
                    blockType: blockType,
                    msg: msg,
                    ActiveOffcanvasParentTab: 'Layers',
                    ActiveOffcanvasChildTab: 'Content'
                })
            }
        }
    }

    const handleBlockSeq = (direction, steps) => {
        let pageId = ''
        Object.keys(websiteJSON.appearance.pages).map((value, key) => {
            if (websiteJSON.appearance.pages[value].url === url) {
                pageId = value
            }
        })
        setLinkDirection(direction)
        setLinkStep(steps)
        if (tokenData.userInfo.RoleID != 'Moderator') {
            axios.put('/block/move/' + selectedDomain + '/' + blockID + '?page=' + pageId + '&direction=' + direction + '&step=' + steps).then(res => {
                if (res.data.message === 'Success') {
                    // localStorage.setItem('website',btoa(JSON.stringify(res.data.data)))
                    setWebsiteData(res.data.data)
                    setLinkDirection('')
                    setLinkStep('')
                    showAppToast({ toastMessage: 'Moved ' + direction, background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                } else {
                    setLinkDirection('')
                    setLinkStep('')
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                }
            })
        } else {
            const selectedCategoryID = tokenData.SelectedDomain.ID
            const categoryPageID = localStorage.getItem('selectedPageID')
            axios.put('/block/move/' + selectedCategoryID + '/' + categoryPageID + '/' + blockID + '?page=' + pageId + '&direction=' + direction + '&step=' + steps).then(res => {
                if (res.data.message === 'Success') {
                    // localStorage.setItem('website',btoa(JSON.stringify(res.data.data)))
                    setWebsiteData(res.data.data)
                    setLinkDirection('')
                    setLinkStep('')
                    showAppToast({ toastMessage: 'Moved ' + direction, background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                } else {
                    setLinkDirection('')
                    setLinkStep('')
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                }
            })
        }
    }

    const handleDeleteBlock = (blockName) => {
        showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this block?', triggerMessage: 'block', deleteID: blockName })
    }

    // const addNewBlock = () => {
    //     addBlockModal({showAddBlockModal:true, blockName: blockID})
    // }

    let listSequencePositionUp = -1
    let listSequencePositionDown = -1
    useEffect(() => {
        listSequencePositionUp = (seq === 2) ? true : false
        listSequencePositionDown = (seq === NaN || seq === 'last') ? true : false
    }, [seq])

    const navigation = websiteJSON.appearance.properties.navigation
    const sitemapData = websiteJSON.appearance.navigation.header
    const activePage = websiteJSON.appearance.properties.defaultPage

    const getSitemapList = (sitemap) => {
        let list = []
        sitemapData.map(value => {
            if (!value.hasOwnProperty('children')) {
                list.push(value)
            } else {
                value.children.map(val => {
                    list.push(val)
                })
            }
        })
        setSitemapList(list)
    }

    useEffect(() => {
        getSitemapList(sitemapData)
    }, [items])

    const handleCloneBlock = (pid, pname) => {
        axios.post('block/cloneBlock/' + selectedDomain + '/' + pid + '/' + blockID).then(res => {
            if (res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                showAppToast({ toastMessage: 'COPY SUCCESSFULL', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
            } else {
                showAppToast({ toastMessage: 'COPY FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
            }
        })
    }

    const showNavigationPopup = (e) => {
        showNavigationOffcanvas({ isNavigationVisible: true, triggerMessage: '', blockID: blockID, clonningblock: true });
        setCloneBlockProps({ 'DomainName' : selectedDomain, 'BlockID': blockID});
    }

    const addNewBlock = () => {
        showAddBlockOffcanvas({ isAddBlockVisible: true, blockName: blockID })
    }

    if (blockID !== '' && blockID !== undefined) {
        var blockProperties = websiteDataBlocks[blockID].items
    }

    useEffect(() => {
        if (blockID !== '' && blockID !== undefined) {
            setItemFilterType(blockProperties.itemFilters)
            setNoItems(blockProperties.numItems)
        }
    }, [websiteDataBlocks, blockID])

    return (
        <div class="listview-title pb-0 p-0" style={{ marginTop: '-17px' }}>
            <div class="row w-100 mx-0">
                <div class="col-4 col-md-4 d-flex justify-content-start align-items-center mt-1" style={{ marginBottom: '-35px', zIndex: 300 - cssID }}>

                    <Dropdown autoClose={true}>
                        <Dropdown.Toggle variant="light" className='box-shadow rounded me-1 toolbar-btn' type="button" size="sm" id="dropdown-basic" title='Move Block Up' disabled={(seq === 1) ? true : false} style={{ padding: '6px', margin: '2px' }}>
                            <ion-icon name="arrow-up-outline" class="fs-6 m-0 text-secondary" alt="Move Block Up"></ion-icon>
                            <span className='d-none d-lg-block mx-0 mx-lg-1'>Move Up</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ maxWidth: '250px' }}>
                            <Dropdown.Item href="#!" onClick={() => { handleBlockSeq('up', 1) }} style={{ whiteSpace: 'normal' }}>
                                {(linkDirection === '' || linkStep === 2 || linkStep === 'first') &&
                                    <ion-icon name="arrow-up-outline"></ion-icon>
                                }
                                {(linkDirection === 'up' && linkStep === 1) &&
                                    <span class="spinner-border spinner-border-sm text-muted me-2" role="status" aria-hidden="true"></span>
                                    // <Spinner animation="border" />
                                }
                                Move Up
                            </Dropdown.Item>
                            <Dropdown.Item href="#!" className={(linkDirection === 'up' && linkStep === 2) ? 'dropdown-item disabled' : 'dropdown-item'} onClick={() => { handleBlockSeq('up', 2) }} style={{ whiteSpace: 'normal' }}>
                                {(linkDirection === '' || linkStep === 1 || linkStep === 'first') &&
                                    <ion-icon name="play-forward" style={{ transform: 'rotate(270deg)' }}></ion-icon>
                                }
                                {(linkStep === 2 && linkDirection === 'up') &&
                                    <span class="spinner-border spinner-border-sm text-muted me-2" role="status" aria-hidden="true"></span>
                                }
                                Move Up By 2
                            </Dropdown.Item>
                            <Dropdown.Item href="#!" className={(linkDirection === 'up' && linkStep === 'first') ? 'dropdown-item disabled' : 'dropdown-item'} onClick={() => { handleBlockSeq('up', 'first') }} style={{ whiteSpace: 'normal' }}>
                                {(linkDirection === '' || linkStep === 1 || linkStep === 2) &&
                                    <ion-icon name="chevron-up-outline"></ion-icon>
                                }
                                {(linkStep === 'first' && linkDirection === 'up') &&
                                    <span class="spinner-border spinner-border-sm text-muted me-2" role="status" aria-hidden="true"></span>
                                }
                                Move To Top
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown autoClose={true}>
                        <Dropdown.Toggle variant="light" className='box-shadow rounded me-1 toolbar-btn' type="button" size="sm" id="dropdown-basic" title='Move Block Down' disabled={(seq === NaN || seq === 'last') ? true : false} style={{ padding: '6px', margin: '2px' }}>
                            <ion-icon name="arrow-down-outline" class="fs-6 m-0 text-secondary" alt="Move Block Down"></ion-icon>
                            <span className='d-none d-lg-block mx-0 mx-lg-1'>Move Down</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#!" onClick={() => { handleBlockSeq('down', 1) }} class={(linkDirection === 'down' && linkStep === 1) ? 'dropdown-item disabled' : 'dropdown-item'}>
                                {(linkDirection === '' || linkStep === 2 || linkStep === 'last') &&
                                    <ion-icon name="arrow-down-outline"></ion-icon>
                                }
                                {(linkDirection === 'down' && linkStep === 1) &&
                                    <span class="spinner-border spinner-border-sm text-muted me-2" role="status" aria-hidden="true"></span>
                                }
                                Move Down
                            </Dropdown.Item>
                            <Dropdown.Item href="#!" onClick={() => { handleBlockSeq('down', 2) }} class={(linkDirection === 'down' && linkStep === 2) ? 'dropdown-item disabled' : 'dropdown-item'}>
                                {(linkDirection === '' || linkStep === 1 || linkStep === 'last') &&
                                    <ion-icon name="play-forward" style={{ transform: 'rotate(90deg)' }}></ion-icon>
                                }
                                {(linkStep === 2 && linkDirection === 'down') &&
                                    <span class="spinner-border spinner-border-sm text-muted me-2" role="status" aria-hidden="true"></span>
                                }
                                Move Down By 2
                            </Dropdown.Item>
                            <Dropdown.Item href="#!" onClick={() => { handleBlockSeq('down', 'last') }} class={(linkDirection === 'down' && linkStep === 'last') ? 'dropdown-item disabled' : 'dropdown-item'}>
                                {(linkDirection === '' || linkStep === 1 || linkStep === 2) &&
                                    <ion-icon name="chevron-down-outline"></ion-icon>
                                }
                                {(linkStep === 'last' && linkDirection === 'down') &&
                                    <span class="spinner-border spinner-border-sm text-muted me-2" role="status" aria-hidden="true"></span>
                                }
                                Move To Bottom
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown autoClose={true}>
                        <Dropdown.Toggle variant="light" className='box-shadow rounded me-1 toolbar-btn' type="button" size="sm" id="dropdown-basic" title='Copy Block' style={{ padding: '6px', margin: '2px' }} onClick={(e) => showNavigationPopup()}>
                            <ion-icon name="copy-outline" class="fs-6 m-0 text-secondary" alt="Copy Block"></ion-icon>
                            <span className='d-none d-lg-block mx-0 mx-lg-1'>Copy</span>
                        </Dropdown.Toggle>

                        {/* <Dropdown.Menu style={{ height: '400px', overflow: 'auto', maxWidth: '250px' }}> */}
                            {/* {blockID !== 'HEADER' &&
                                <Dropdown.Item href="#!" onClick={() => {handleBlockUpdate('changeBackground')}}><ion-icon name="image-outline"></ion-icon> Change Background</Dropdown.Item>
                            }
                            {blockID === 'HEADER' &&
                                <Dropdown.Item href="#!"><ion-icon name="eye-outline"></ion-icon> Publish Page</Dropdown.Item>
                            } */}
                            {/* <div class="row">
                                {sitemapList.map(value => {
                                    return <Dropdown.Item href="#!" style={{ whiteSpace: 'normal' }} onClick={() => { handleCloneBlock(value.page, value.title) }}>

                                        <div class="col-2 col-md-2">
                                            <ion-icon name="reader-outline"></ion-icon>
                                        </div>
                                        <div class="col-10 col-md-10">
                                            {value.title}
                                        </div>

                                    </Dropdown.Item>
                                })}
                            </div> */}
                        {/* </Dropdown.Menu> */}
                    </Dropdown>
                </div>
                <div class="col-4 col-md-4 d-flex justify-content-center align-items-center" style={{ zIndex: 300 - cssID }}>
                    {seq !== 1 &&
                        <Dropdown autoClose={true}>
                            <Dropdown.Toggle id="dropdown-basic" onClick={() => { addNewBlock() }} style={{ padding: '6px' }} title='Add New Block' size="sm" className="btn btn-primary">
                                <ion-icon name="add-outline" class="fs-6 m-0"></ion-icon>
                            </Dropdown.Toggle>

                            {/* <Dropdown.Menu style={{maxWidth: '250px'}}>
                                {AddNewItemVariable}
                                <a class="dropdown-item" href="#!" onClick={() => {addNewBlock()}} style={{whiteSpace: 'normal'}}>
                                    <ion-icon name="add-outline"></ion-icon> New Block
                                </a>
                            </Dropdown.Menu> */}
                        </Dropdown>
                    }
                </div>
                <div class="col-4 col-md-4 d-flex justify-content-end align-items-center mt-1" style={{ marginBottom: '-35px', zIndex: 300 - cssID }}>

                    {blockType === 'CN' &&
                        <Dropdown autoClose={true}>
                            <Dropdown.Toggle variant="blue" className='box-shadow rounded me-1 toolbar-btn' type="button" size="sm" id="dropdown-basic" title="Add Item" style={{ padding: '6px', margin: '2px' }} onClick={(aliasDomain === 'nisargamitrapanvel.com' && url === 'events' && blockID === 'CN_LIST5' && properties.styleID === 'CN_LIST_CAR5') ? () => navigate('/eventDetail') : () => handleAddNewItem('last')}>
                                <ion-icon name="add" class="fs-6 m-0 md hydrated" alt="Add Item"></ion-icon>
                                <span className='d-none d-lg-block mx-0 mx-lg-1'>Add</span>
                            </Dropdown.Toggle>
                        </Dropdown>
                    }

                    <Dropdown autoClose={true}>
                        <Dropdown.Toggle variant="success" className='box-shadow rounded me-1 toolbar-btn' type="button" size="sm" id="dropdown-basic" title="Edit Block" style={{ padding: '6px', margin: '2px' }} onClick={() => handleNewEditItem()}>
                            <ion-icon name="pencil" class="fs-6 m-0 md hydrated" alt="Edit Block"></ion-icon>
                            <span className='d-none d-lg-block mx-0 mx-lg-1'>Edit</span>
                        </Dropdown.Toggle>
                    </Dropdown>

                    <Dropdown autoClose={true}>
                        <Dropdown.Toggle variant="danger" className='box-shadow rounded me-1 toolbar-btn' type="button" size="sm" id="dropdown-basic" title="Delete Block" style={{ padding: '6px', margin: '2px' }} onClick={() => handleDeleteBlock(blockID)}>
                            <ion-icon name="trash" class="fs-6 m-0 md hydrated" alt="Delete Block"></ion-icon>
                            <span className='d-none d-lg-block mx-0 mx-lg-1'>Delete</span>
                        </Dropdown.Toggle>
                    </Dropdown>


                    {/* <Dropdown autoClose={true}>
                        <Dropdown.Toggle variant="light" type="button" size="sm" id="dropdown-basic" style={{ padding: '6px', border: 'none', margin: '2px' }}>
                            <ion-icon name="ellipsis-vertical" class="fs-6 m-0 text-secondary"></ion-icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ maxWidth: '250px' }}>
                            {blockType === 'CN' &&
                                <>
                                    <Dropdown.Item href="javascript:void(0)" onClick={() => { handleAddNewItem('last') }}>
                                        <ion-icon name="add-outline" class="m-0"></ion-icon> New Item
                                    </Dropdown.Item>
                                    <div class="dropdown-divider m-0"></div>
                                    <div class="text" style={{ fontSize: '10px', lineHeight: 'normal', paddingLeft: '10px', paddingRight: '10px' }}>
                                        <span style={{ textTransform: 'capitalize' }}>{itemFilterType}</span> {noItems} items are visible
                                    </div>
                                    <div class="dropdown-divider m-0"></div>
                                </>
                            }
                            <Dropdown.Item href="javascript:void(0)" onClick={(blockType === 'CN') ? () => { handleNewEditItem() } : () => { handleNewEditItem() }}>
                                Edit Block
                            </Dropdown.Item
                            <Dropdown.Item href="#!" onClick={() => { handleDeleteBlock(blockID) }} style={{ whiteSpace: 'normal' }}>
                                Delete Block
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}

                    {/* <span class="dropdown">
                        <button type="button" class="btn btn-text-secondary btn-sm"><ion-icon name="trash-outline" class="fs-6 m-0"></ion-icon></button>
                    </span> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, appToast, websiteData }) => {
    const {
        isVisible,
        message,
        blockid,
        blockID,
        isPropertiesVisible,
        isAddOffcanvasVisible,
        addMessage,
        showAddBlockModal,
        itemCount
    } = offcanvas
    const {
        toastMessage,
        background,
        icon,
        position,
        timeout
    } = appToast
    let {
        blocks: websiteDataBlocks
    } = websiteData
    return {
        offcanvasVisible: isVisible,
        offcanvasMessage: message,
        offcanvasBlockID: blockID,
        offcanvasIsPropertiesVisible: isPropertiesVisible,
        isAddOffcanvasVisible: isAddOffcanvasVisible,
        addMessage: addMessage,
        toastMessage,
        background,
        icon,
        position,
        timeout,
        blockid,
        showAddBlockModal,
        itemCount,
        websiteDataBlocks
    }
}

const mapDispatchToProps = {
    showOffcanvas,
    hideOffcanvas,
    showPropertyOffcanvas,
    showDeleteModal,
    setWebsiteData,
    showAddOffcanvas,
    showAppToast,
    addBlockModal,
    showAddBlockOffcanvas,
    setNewBlockType,
    showUpdateDataOffcanvas,
    showNavigationOffcanvas,
    setCloneBlockProps
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar)
