import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideAddModal, addBlockModal, addPageModal } from '../../../features/modal/offcanvasSlice'
import { Link } from 'react-router-dom'

const AllAdd = ({
    hideAddModal,
    isAddVisible,
    addBlockModal,
    addPageModal
}) => {
    const addNewBlock = () => {
        addBlockModal({showAddBlockModal:true})
        hideAddModal()
    }
    const handleAddPageModal = () => {
        addPageModal({showAddPageModal: true}) 
        hideAddModal()
    }
    return (
        <div>
            <Offcanvas show={isAddVisible} placement="bottom" class="offcanvas offcanvas-bottom" tabindex="-1" style={{height: 'auto'}} backdrop="true" scroll="true">
                <Offcanvas.Header class="offcanvas-header">
                    <h5 class="offcanvas-title text-center">Add</h5>
                    <a href="#!" class="offcanvas-close text-muted" onClick={() => {hideAddModal()}}>
                        <ion-icon name="close-outline"></ion-icon>
                    </a>
                </Offcanvas.Header>
                <Offcanvas.Body class="offcanvas-body p-0">
                    <ul class="action-button-list">
                        <li>
                            <a href="#!" class="btn btn-list text-muted" onClick={() => {addNewBlock()}}>
                                <span>
                                    <ion-icon name="albums-outline"></ion-icon>
                                    Block
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#!" class="btn btn-list text-muted" onClick={() => {handleAddPageModal()}}>
                                <span>
                                    <ion-icon name="documents-outline"></ion-icon>
                                    Page
                                </span>
                            </a>
                        </li>
                        <li>
                            <Link to="/email" class="btn btn-list text-muted">
                                <span>
                                    <ion-icon name="mail-outline"></ion-icon>
                                    Email
                                </span>
                            </Link>
                        </li>
                        <li>
                            <a href="https://webweb.ai/build-website" rel="noreferrer" target="_blank" class="btn btn-list text-primary">
                                <span>
                                    <ion-icon name="globe-outline"></ion-icon>
                                    Website
                                </span>
                            </a>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isAddVisible
    } = offcanvas
    return {
        isAddVisible
    }
  }

const mapDispatchToProps = {
    hideAddModal,
    addBlockModal,
    addPageModal
}

export default connect(mapStateToProps, mapDispatchToProps)(AllAdd)
