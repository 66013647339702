import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import Modal from 'react-bootstrap/Modal'
import PreviewFooter from '../Management/PreviewFooter'
import PreviewHeader from '../Management/PreviewHeader'
import Confetti from 'react-confetti'

const Preview = ({
    selectedDomain,
    runPartyParticals,
    isPreviewVisible
}) => {
    // console.log(isPreviewVisible)
    let previousPage = ''
    useEffect(() => {
        previousPage = localStorage.getItem('previousPage')
    }, [previousPage])

    const [stopParty, setStopParty] = useState(true)
    const [runParty, setRunParty] = useState(true)

    // useEffect(() => {
    //     console.log("PARTY STOPPED")
    // }, [stopParty])

    useEffect(() => {
        if (runPartyParticals) {
            setStopParty(runPartyParticals)
            setRunParty(runPartyParticals)
            setTimeout(function(){
                setStopParty(false)
            }, 10000)
        
            setTimeout(function(){
                setRunParty(false)
            }, 15000)
        }
    }, [runPartyParticals])

    const token = localStorage.getItem('token')

    async function getSrc() {
        const res = await fetch("https://try.webweb.ai/" + previousPage, {
          method: 'GET',
          headers: {
            "Authorization": token
          }
        });
        const blob = await res.blob()
        const urlObject = URL.createObjectURL(blob)
        document.querySelector('iframe').setAttribute("src", urlObject)
    }
    // getSrc()

    return (
        <div class="extraHeaderActive">
            <Modal show={isPreviewVisible} onEntered={() => {getSrc()}} class="modal fade" id="previewModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                {/* <div class="modal-dialog modal-fullscreen-xxl-down"> */}
                    <div class="modal-content" style={{position: 'fixed', bottom: 0, top: 0, left: 0}}>
                        <Confetti
                            width={Window.innerWidth}
                            height={Window.innerHeight}
                            recycle={stopParty}
                            run={runParty}
                            numberOfPieces={(runPartyParticals) ? 200 : 0}
                        />
                        <PreviewHeader selectedDomain={selectedDomain} pageName={previousPage} />
                        <Modal.Body class="modal-body pt-5 mt-3 pb-3 mb-3 p-0 d-flex justify-content-center">
                            <iframe id="dynamicIframe" height="100%" title="Magowa Travels" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </Modal.Body>
                        <PreviewFooter />
                    </div>
                {/* </div> */}
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        runPartyParticals,
        isPreviewVisible
    } = offcanvas
    return {
        runPartyParticals,
        isPreviewVisible
    }
  }
  
  const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
