import React, { useEffect, useState } from 'react'
import {
    setWebsiteData
} from '../../../features/websiteData/websiteDataSlice'
import axios from '../../../AxiosConfig'
import { useLocation, Link, useNavigate, useSearchParams, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../../features/auth/authSlice'
import jwt_decode from 'jwt-decode'
import config from '../../../config'
import Preview from '../../Elements/Modals/Preview'
import AppToast from '../../Elements/Notification/AppToast'
import DeleteDialogueBox from '../../Elements/Delete/DeleteDialogueBox'
import ConnectDomainModal from '../../Elements/Modals/connectDomainModal'
// import Logo from '../../images/webweb.svg'
import { updateDeviceSize } from '../../../features/utilities/utilitiesSlice'
import { setShowJSONModal, setSideBarMenuShow } from '../../../features/modal/offcanvasSlice'
import { showAppearance, showSiteSettingOffcanvas, showDeleteModal, showPageSetting, setResponsiveIframe, showNavigationOffcanvas, showDomainSettingOffcanvas, showEmailSettingOffcanvas, showSidebarToggle } from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import ConfirmationModal from '../../Elements/Modals/ConfirmationModal'
import HtmlEditor from '../../Elements/Modals/HtmlEditor'
import CategoryManage from '../Rightpanel/CategoryManage'
import DomainsAdminOffcanvas from '../Rightpanel/DomainsAdminOffcanvas'
import Ogoffcanvas from '../Rightpanel/ogoffcanvas'
import { Button, Dropdown, Offcanvas } from 'react-bootstrap'
import { getDomainName } from '../../../features/auth/authSlice'

const Header2 = ({
    setWebsiteData,
    selectedDomain,
    appToastVisible,
    appToastMessage,
    appToastBackground,
    appToastIcon,
    appToastPosition,
    isDeleteVisible,
    deleteMessage,
    triggerMessage,
    deleteID,
    logout,
    updateDeviceSize,
    showAppearance,
    showSiteSettingOffcanvas,
    showDeleteModal,
    showPageSetting,
    domains,
    showAppToast,
    showNavigationOffcanvas,
    setResponsiveIframe,
    showDomainSettingOffcanvas,
    showEmailSettingOffcanvas,
    setShowJSONModal,
    showSidebarToggle,
    sidebarToggle,
    setSideBarMenuShow,
    websiteDataAppearance
}) => {
    const [searchParams, setSearchParams] = useSearchParams({ 'url': 'home', 'menu': '' })
    const pageurl = searchParams.get('url');
    const pagemenu = searchParams.get('menu');

    const [device, setDevice] = useState('')
    useEffect(() => {
        updateDeviceSize({ device: device })
    }, [device])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [AllPagesArray, setAllPagesArray] = useState([]);
    const [AllOtherPagesArray, setAllOtherPagesArray] = useState([]);
    const pathname = window.location.pathname;
    
    const returnPageItems = (pageObjectID, hasChildren) => {
        let pageData;
        let pageItem;
        if (websiteDataAppearance.pages[pageObjectID] && hasChildren.type === '') {
            pageData = JSON.parse(JSON.stringify(hasChildren));
            pageItem = {
                ...pageData,
                'url': websiteDataAppearance.pages[pageObjectID].url,
                'metaDescription': websiteDataAppearance.pages[pageObjectID].properties.metaDescription,
                'ogImage': websiteDataAppearance.pages[pageObjectID].properties.ogImage
            }
        } else {
            pageData = JSON.parse(JSON.stringify(hasChildren));
            pageItem = { ...pageData };
        }

        return pageItem
    }

    function GetAllPagesInMenuArray(headerMenu) {
        const headerMenuArray = headerMenu.map((item) => {
            const tempItem = { ...returnPageItems(item.page, item) };
            if (item.children && item.children.length > 0) {
                tempItem.children = GetAllPagesInMenuArray(tempItem.children);
            }
            return tempItem
        })

        return headerMenuArray
    }

    useEffect(() => {
        if (websiteDataAppearance?.pages && pathname === '/build') {
            const getPages = GetAllPagesInMenuArray(websiteDataAppearance.navigation.header);
            setAllPagesArray(getPages);
            console.log(websiteDataAppearance?.pages);
            const tempAllOtherPagesArray = Object.keys(websiteDataAppearance?.pages).map((page) => {
                const tmpPg = {
                    title: websiteDataAppearance?.pages[page].properties.title,
                    page: page,
                    type:"",
                    url: websiteDataAppearance?.pages[page].url,
                    icon:"",
                }
                return tmpPg
            })
            setAllOtherPagesArray(tempAllOtherPagesArray);
        }
    }, [websiteDataAppearance]);

    // useEffect(() => {
    //     if(pathname === '/build' && AllPagesArray.length > 0) {
    //         const TempAllPagesArray = JSON.parse(JSON.stringify(AllPagesArray));
    //     }
    // }, [pageurl, AllPagesArray]);

    const [searchIn, setSearchIn] = useState('');

    return (
        <div>
            <AppToast
                message={appToastMessage}
                show={appToastVisible}
                background={appToastBackground}
                icon={appToastIcon}
                position={appToastPosition}
            />
            <div className="appHeader appMenuHeader">
                
                <div className="d-flex align-items-center innerScroller justify-content-between">
                    <div className="d-flex justify-content-start logoSection align-items-center">
                        <Button variant="link" className='text-dark' onClick={() => setSideBarMenuShow(true)}>
                            <ion-icon name="menu-outline" style={{'display': 'block', 'margin': 'auto'}}></ion-icon>
                        </Button>
                        <a href="#!" className="headerButton">
                            <img src='assets/img/webweb-white-logo.svg' className="img-fluid" alt="Brand" width="45x45" />
                        </a>
                    </div>
                    <div className='pagesTabs'>
                        {
                            AllPagesArray.map((page, i) => {
                                return (
                                    <>
                                        {
                                            (i < 10) &&
                                            <>
                                            {
                                                (page.children && page.children.length > 0) ?
                                                <>
                                                <NavLink to={`/build?url=${page.url}&menu=${page.page}`}>
                                                    <div type="button" className={`btn btn-link ${page.page === pagemenu ? 'active' : 'text-dark'}`}>
                                                        {page.title}
                                                        {/* {
                                                            i === 1 &&
                                                            <span className='activeBar' />
                                                        } */}
                                                    </div>
                                                </NavLink>
                                                </>
                                                :
                                                <NavLink to={`/build?url=${page.url}`}>
                                                    <div type="button" className={`btn btn-link ${page.url === pageurl ? 'active' : 'text-dark'}`}>
                                                        {page.title}
                                                        {/* {
                                                            i === 1 &&
                                                            <span className='activeBar' />
                                                        } */}
                                                    </div>
                                                </NavLink>
                                            }
                                            </>
                                        }
                                    </>

                                )
                            })
                        }
                        {
                            AllPagesArray.length > 0 &&
                            <div type="button" className='btn btn-sm' onClick={() => show ? handleClose() : handleShow()}>
                                <ion-icon name="chevron-down-outline"></ion-icon>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Preview selectedDomain={selectedDomain} />
            <DeleteDialogueBox
                show={isDeleteVisible}
                deleteMessage={deleteMessage}
                triggerMessage={triggerMessage}
                deleteID={deleteID}
            ></DeleteDialogueBox>
            <ConnectDomainModal />
            <ConfirmationModal></ConfirmationModal>
            <HtmlEditor></HtmlEditor>
            <CategoryManage></CategoryManage>
            <DomainsAdminOffcanvas></DomainsAdminOffcanvas>
            <Ogoffcanvas></Ogoffcanvas>
            <Offcanvas show={show} onHide={handleClose} backdrop={false} animation={false} className="AllPagesMenuOffcanvas" placement='top' responsive="lg">
                {/* <Offcanvas.Header closeButton className='py-2'>
                    <Offcanvas.Title>All Pages</Offcanvas.Title>
                </Offcanvas.Header> */}
                <Offcanvas.Body className='p-0'>
                <div className="form-group boxed p-1 searchbar">
                    <div className="input-wrapper">
                        <input type="text" className="form-control px-1" id="name5" value={searchIn} placeholder="Search page" onChange={(e) => setSearchIn(e.target.value)} autocomplete="off" />
                        {/* <i className="input-icon1 position-absolute t-0 mt-1 ms-1 fs-20">
                            <ion-icon name="search-outline"></ion-icon>
                        </i> */}
                    </div>
                </div>
                <ul class="listview simple-listview">
                    {
                        AllOtherPagesArray.map((page) => {
                            return (
                                <li className={`p-0 ${searchIn.trim().length > 0 && ((page?.title?.toLowerCase().includes(searchIn.toLowerCase()) || page?.url?.toLowerCase().includes(searchIn.toLowerCase())) ? '' : 'd-none')}`}>
                                    <NavLink to={`/build?url=${page.url}`} className={`w-100 h-100 p-1 ${pageurl === page.url ? 'activePage' : '' }`}>
                                        <h5 className='mb-0 text-truncate' >{page.title}</h5>
                                        <span className='text-muted fs-10 text-truncate'>{`${getDomainName()}/${page.url}`}</span>
                                    </NavLink>
                                </li>
                            )
                        })
                    }
                </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ appToast, offcanvas, utilities, websiteData }) => {
    const { isVisible, toastMessage, background, icon, position } = appToast
    const { isDeleteVisible, deleteMessage, triggerMessage, deleteID, sidebarToggle } = offcanvas
    const { domains } = utilities
    const { appearance: websiteDataAppearance } = websiteData
    return {
        appToastVisible: isVisible,
        appToastMessage: toastMessage,
        appToastBackground: background,
        appToastIcon: icon,
        appToastPosition: position,
        isDeleteVisible,
        deleteMessage,
        triggerMessage,
        deleteID,
        domains,
        sidebarToggle,
        websiteDataAppearance
    }
}

const mapDispatchToProps = {
    setWebsiteData,
    logout,
    updateDeviceSize,
    showAppearance,
    showDeleteModal,
    showPageSetting,
    setResponsiveIframe,
    showAppToast,
    showNavigationOffcanvas,
    showSiteSettingOffcanvas,
    showDomainSettingOffcanvas,
    showEmailSettingOffcanvas,
    setShowJSONModal,
    showSidebarToggle,
    setSideBarMenuShow
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header2)
