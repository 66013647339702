import React from 'react'
import { connect } from "react-redux"
import { hideEditDns } from '../../../features/modal/offcanvasSlice'
import Offcanvas from 'react-bootstrap/Offcanvas'

const EditDns = ({
    isDnsOffcanvasVisible,
    hideEditDns
}) => {
    const collectData = () => {
        console.log('EDIT DNS OFFCANVAS', isDnsOffcanvasVisible)
    }
    return (
        <div>
            <Offcanvas show={isDnsOffcanvasVisible} placement={'end'} backdrop={true} scroll={true} onEntered={collectData} id="appCapsule">
                <Offcanvas.Body className="offcanvas-body ps-0 pe-0 p-0">
                    <div className="profileBox webweb-profileBox">
                        <div className="in">
                            <strong>Edit DNS</strong>
                        </div>
                        <a href="#!" className="close-sidebar-button" onClick={() => {hideEditDns()}}>
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>
                    <div className="section full mb-2">
                        <div className="wide-block">
                            <form>
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="type5">Type</label>
                                        <input type="text" className="form-control" id="type5" placeholder="Enter your name"
                                            autoComplete="off" value="A" readOnly />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="hostname5">Hostname</label>
                                        <input type="text" className="form-control" id="hostname5" placeholder="Enter your name"
                                            autoComplete="off" value="api.hussain.in" readOnly />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="value5">Value</label>
                                        <input type="text" className="form-control" id="value5" placeholder="Select resource or enter IP"
                                            autoComplete="off" value="139.59.0.46" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="ttl5">TTL (Seconds)</label>
                                        <input type="text" className="form-control" id="ttl5" placeholder="Enter TTL"
                                            autoComplete="off" value="1800" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="sidebar-buttons webweb-sidebar-buttons">
                                    <button className="btn btn-block btn-success" type="submit" style={{borderRadius: "initial"}}>
                                        <ion-icon name="checkmark-outline"></ion-icon> Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isDnsOffcanvasVisible
    } = offcanvas
    return {
        isDnsOffcanvasVisible
    }
  }
    
  const mapDispatchToProps = {
      hideEditDns
  }

export default connect(mapStateToProps, mapDispatchToProps)(EditDns)
