import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVisible: false,
  isEditVisible: false,
  isTemplateVisible:false,
  items:[],
  itemID: -1,
  properties:{},
  blockType:'',
  blockID:'',
  blockid:'',
  contentPosition: '',
  seq:0,
  selectedItems: -1,
  isImageModalVisible:false,
  imageData:[],
  newImage:[],
  imageID:-1,
  isButtonModalVisible:false,
  buttonData:[],
  buttonID:-1,
  isPropertiesVisible: false,
  isDeleteVisible: false,
  deleteMessage:'This data can not be recovered',
  triggerMessage:'',
  deleteID:'',
  deleteItemID: '',
  isAddOffcanvasVisible: false,
  addMessage: '',
  showAddBlockModal: false,
  blockName: '',
  showAddPageModal: false,
  newButton: [],
  isResponsive: false,
  runPartyParticals: false,
  isPageSettingVisible: false,
  isEditBannerVisible: false,
  bannerID: 0,
  bannerData: null,
  isSidebarVisible: false,
  isAppearanceVisible: false,
  isDnsOffcanvasVisible: false,
  isAddVisible: false,
  isPreviewVisible: false,
  isSwitchModalVisible: false,
  reorderItems: [],
  clonningblock: false,
  isNavigationVisible: false,
  isSiteSettingVisible: false,
  isDomainSettingVisible: false,
  isEmailSettingVisible: false,
  switchModalMessage: '',
  isFormVisible: false,
  formID: '',
  itemCount: [],
  isAddBlockVisible: false,
  sidebarToggle: false,
  isPopupBlockVisible: false,
  isConfirmationVisibleModal: false,
  confirmationMessage: '',
  isClonePageModalVisible: false,
  editOffcanvasVisible: false,
  JSONModal: false,
  isCodeEditorVisible : false,
  isCategoryVisible: false,
  isDomainAdminVisible: false,
  NewBlockType: null,
  MainBlockProps: null,
  ContentBlockItem: null,
  ContentItems: null,
  ContentItemsArray: [],
  WhereToUpdateImage: null,
  CropperSizes: null,
  buttonDataObj: null,
  isOgVisible: false,
  isFooterActionVisible: false,
  actionSheetOf: '',
  sideBarMenuShow: false,
  // {
  //   blockType: null, // SM/CN
  //   ItemOrBg: null, // Item/Background/Overlay/BlockBackground/BlockOverlay
  //   ItemPosition: null, // 0/1/2
  // }
  FlagsToCheckChanges: {
    'title': false,
    'titleCss': false,
    'subtitle': false,
    'subtitleCss': false,
    'description': false,
    'descriptionCss': false,
    'image': false,
    'imageCss': false,
    'button': false,
    'buttonCss': false,
    'category': false,
    'blockHeading': false,
    'blockHeadingCss': false,
    'blockDescription': false,
    'blockDescriptionCss': false,
    'css': false,
    'structureCss': false,
    'countInRow': false,
    'itemFilters': false,
    'numItems': false,
    'contents': false,
    'url': false,
    'container': false,
    'background': false,
    'overlay': false,
    'additionalDetails': false,
    'itemCss': false,
    'itemBackground': false,
    'itemOverlay': false
  },
  ActiveOffcanvasParentTab: 'Layers',
  ActiveOffcanvasChildTab: 'Content',
  ActiveOffcanvasChild2Tab: 'Layout',
  CloneBlockProps: null,
  ADDClonePageProps: null,
  HtmlJsCssModal: null,
  draggingItems: 'logoAdjust',
}

export const offcanvasSlice = createSlice({
  name: 'offcanvas',
  initialState,
  reducers: {
    showOffcanvas: (state, action) => {
      const {
        msg,
        items,
        itemID,
        properties,
        blockType,
        blockID,
        seq,
        selectedItems,
        newImage,
        newButton,
        isVisible,
        itemCount
      } = action.payload
      console.log({newImage, selectedItems, blockID});
      state.msg = msg
      state.isVisible = isVisible
      state.items = items
      state.itemID = itemID
      state.properties = properties
      state.blockType = blockType
      state.blockID = blockID
      state.seq = seq
      state.selectedItems = selectedItems
      state.newImage = newImage
      state.newButton = newButton
      state.itemCount = itemCount
    },
    hideOffcanvas: (state, action) => {
      state.isVisible = false
    },
    showTemplateOffcanvas: (state, action) => {
      const {
        isTemplateVisible
      } = action.payload
      state.isTemplateVisible = isTemplateVisible
    },
    hideTemplateOffcanvas: (state) => {
      state.isTemplateVisible = false
    },
    showImageModal: (state, action) => {
      const {
        isImageModalVisible,
        imageData,
        imageID,
        imageType
      } = action.payload
      console.log({isImageModalVisible, imageData, imageID, imageType})
      state.isImageModalVisible = isImageModalVisible
      state.imageData = imageData
      state.imageID = imageID
      state.imageType = imageType
    },
    hideImageModal: (state) => {
      state.isImageModalVisible = false
    },
    showButtonModal: (state, action) => {
      const {
        isButtonModalVisible,
        buttonData,
        buttonID
      } = action.payload
      state.isButtonModalVisible = isButtonModalVisible
      state.buttonData = buttonData
      state.buttonID = buttonID
    },
    hideButtonModal: (state) => {
      state.isButtonModalVisible = false;
      state.buttonData = [];
    },
    showPropertyOffcanvas: (state, action) => {
      const {
        isPropertiesVisible
      } = action.payload
      state.isPropertiesVisible = isPropertiesVisible
    },
    hidePropertyOffcanvas: (state) => {
      state.isPropertiesVisible = false
    },
    showDeleteModal: (state, action) => {
      const {
        isDeleteVisible,
        deleteMessage,
        triggerMessage,
        deleteID,
        deleteItemID,
        blockID
      } = action.payload
      state.isDeleteVisible = isDeleteVisible
      state.deleteMessage = deleteMessage
      state.triggerMessage = triggerMessage
      state.deleteID = deleteID
      state.deleteItemID = deleteItemID
      state.blockID = blockID
    },
    hideDeleteModal: (state) => {
      state.isDeleteVisible = false
    },
    showAddOffcanvas: (state, action) => {
      const {
        isAddOffcanvasVisible,
        addMessage,
        blockid,
        contentPosition
      } = action.payload
      state.isAddOffcanvasVisible = isAddOffcanvasVisible
      state.addMessage = addMessage
      state.blockid = blockid
      state.contentPosition = contentPosition
    },
    hideAddOffcanvas: (state) => {
      state.isAddOffcanvasVisible = false
    },
    newImageData: (state, action) => {
      const {
        imageDataObj,
        imageID,
        itemID
      } = action.payload
      if (imageID >= 0 && itemID >= 0) {
        state.items[itemID].image = imageDataObj
      }
      state.imageDataObj = imageDataObj
      state.imageData = imageDataObj
    },
    newButtonData: (state, action) => {
      const {
        buttonDataObj,
      } = action.payload
      state.buttonDataObj = buttonDataObj
    },
    addBlockModal: (state, action) => {
      const {
        showAddBlockModal,
        blockName
      } = action.payload
      state.showAddBlockModal = showAddBlockModal
      state.blockName = blockName
    },
    hideAddBlockModal: (state, action) => {
      state.showAddBlockModal = false
    },
    addPageModal: (state, action) => {
      const {
        showAddPageModal
      } = action.payload
      state.showAddPageModal = showAddPageModal
    },
    hideAddPageModal: (state, action) => {
      state.showAddPageModal = false
    },
    setResponsiveIframe: (state, action) => {
      const {
        isResponsive,
        runPartyParticals
      } = action.payload
      state.isResponsive = isResponsive
      state.runPartyParticals = runPartyParticals
    },
    showPageSetting: (state, action) => {
      const {
        isPageSettingVisible,
        defaultActiveTab
      } = action.payload
      state.isPageSettingVisible = isPageSettingVisible
      state.defaultActiveTab = defaultActiveTab
    },
    hidePageSetting: (state) => {
      state.isPageSettingVisible = false
    },
    showEditShareBanner: (state, action) => {
      const {
        isEditBannerVisible,
        bannerData,
        bannerID,
        draggingItems
      } = action.payload
      state.isEditBannerVisible = isEditBannerVisible
      state.bannerData = bannerData
      state.bannerID = bannerID
      state.draggingItems = draggingItems
    },
    hideEditShareBanner: (state) => {
      state.isEditBannerVisible = false
    },
    showSidebar: (state, action) => {
      const {
        isSidebarVisible
      } = action.payload
      state.isSidebarVisible = isSidebarVisible
    },
    hideSidebar: (state) => {
      state.isSidebarVisible = false
    },
    showAppearance: (state, action) => {
      const {
        isAppearanceVisible
      } = action.payload
      state.isAppearanceVisible = isAppearanceVisible
    },
    hideAppearance: (state) => {
      state.isAppearanceVisible = false
    },
    showEditDns: (state, action) => {
      const {
        isDnsOffcanvasVisible
      } = action.payload
      state.isDnsOffcanvasVisible = isDnsOffcanvasVisible
    },
    hideEditDns: (state) => {
      state.isDnsOffcanvasVisible = false
    },
    showAddModal: (state, action) => {
      const {
        isAddVisible
      } = action.payload
      state.isAddVisible = isAddVisible
    },
    hideAddModal: (state) => {
      state.isAddVisible = false
    },
    showPreviewModal: (state, action) => {
      const {
        isPreviewVisible
      } = action.payload
      state.isPreviewVisible = isPreviewVisible
    },
    hidePreviewModal: (state) => {
      state.isPreviewVisible = false
    },
    showSwitchModal: (state, action) => {
      const {
        isSwitchModalVisible,
        switchModalMessage,
        blockID
      } = action.payload
      state.isSwitchModalVisible = isSwitchModalVisible
      state.switchModalMessage = switchModalMessage
      state.blockID = blockID
    },
    hideSwitchModal: (state) => {
      state.isSwitchModalVisible = false
    },
    showNavigationOffcanvas: (state, action) => {
      const {
        isNavigationVisible,
        blockID,
        triggerMessage,
        clonningblock
      } = action.payload
      state.isNavigationVisible = isNavigationVisible
      state.blockID = blockID
      state.triggerMessage = triggerMessage
      state.clonningblock = clonningblock !== undefined ? clonningblock : false
    },
    setReorderItems: (state, action) => {
      state.reorderItems = action.payload;
    },
    hideNavigationOffcanvas: (state) => {
      state.isNavigationVisible = false
      state.clonningblock = false
    },
    showSiteSettingOffcanvas: (state, action) => {
      const {
        isSiteSettingVisible
      } = action.payload
      state.isSiteSettingVisible = isSiteSettingVisible
    },
    hideSiteSettingOffcanvas: (state) => {
      state.isSiteSettingVisible = false
    },
    showEditOffcanvas: (state, action) => {
      const {
        msg,
        items,
        itemID,
        properties,
        blockType,
        blockID,
        seq,
        selectedItems,
        newImage,
        newButton,
        isEditVisible
      } = action.payload
      console.log({msg,
        items,
        itemID,
        properties,
        blockType,
        blockID,
        seq,
        selectedItems,
        newImage,
        newButton,
        isEditVisible});
      state.msg = msg
      state.isEditVisible = isEditVisible
      state.items = items
      state.itemID = itemID
      state.properties = properties
      state.blockType = blockType
      state.blockID = blockID
      state.seq = seq
      state.selectedItems = selectedItems
      state.newImage = newImage
      state.newButton = newButton
    },
    hideEditOffcanvas: (state, action) => {
      state.isEditVisible = false;

    },
    showDomainSettingOffcanvas: (state, action) => {
      const {
        isDomainSettingVisible
      } = action.payload
      state.isDomainSettingVisible = isDomainSettingVisible
    },
    hideDomainSettingOffcanvas: (state) => {
      state.isDomainSettingVisible = false
    },
    showEmailSettingOffcanvas: (state, action) => {
      const {
        isEmailSettingVisible
      } = action.payload
      state.isEmailSettingVisible = isEmailSettingVisible
    },
    hideEmailSettingOffcanvas: (state) => {
      state.isEmailSettingVisible = false
    },
    showFormOffcanvas: (state, action) => {
      const {
        isFormVisible,
        formID
      } = action.payload
      state.isFormVisible = isFormVisible
      state.formID = formID
    },
    hideFormOffcanvas: (state) => {
      state.isFormVisible = false
    },
    showAddBlockOffcanvas: (state, action) => {
      const {
        isAddBlockVisible,
        blockName
      } = action.payload
      state.isAddBlockVisible = isAddBlockVisible
      state.blockName = blockName
    },
    hideAddBlockOffcanvas: (state) => {
      state.isAddBlockVisible = false
    },
    showSidebarToggle: (state, action) => {
      const {
        sidebarToggle
      } = action.payload
      state.sidebarToggle = sidebarToggle
    },
    showPopupBlockOffcanvas: (state, action) => {
      // console.log(state, action)
      const {
        isPopupBlockVisible
      } = action.payload
      state.isPopupBlockVisible = isPopupBlockVisible
    },
    hidePopupBlockOffcanvas: (state) => {
      state.isPopupBlockVisible = false
    },
    showConfirmationModal: (state, action) => {
      // console.log(state, action)
      const {
        isConfirmationVisibleModal,
        confirmationMessage
      } = action.payload
      state.isConfirmationVisibleModal = isConfirmationVisibleModal
      state.confirmationMessage = confirmationMessage
    },
    hideConfirmationModal: (state) => {
      state.isConfirmationVisibleModal = false
    },
    showClonePageModal: (state, action) => {
      // console.log(state, action)
      const {
        isClonePageModalVisible
      } = action.payload
      state.isClonePageModalVisible = isClonePageModalVisible
    },
    hideClonePageModal: (state) => {
      state.isClonePageModalVisible = false
    },
    showUpdateDataOffcanvas: (state, action) => {
      // console.log(state, action)
      const {
        isEditVisible,
        itemID,
        items,
        blockID,
        properties,
        msg,
        blockType,
        seq,
        selectedItems,
        newImage,
        newButton,
        itemCount,
        ActiveOffcanvasParentTab,
        ActiveOffcanvasChildTab,
        ActiveOffcanvasChild2Tab
      } = action.payload
      state.isEditVisible = isEditVisible;
      state.itemID = itemID;
      state.items = items;
      state.blockID = blockID;
      state.properties = properties;
      state.msg = msg;
      state.blockType = blockType;
      state.seq = seq;
      state.selectedItems = selectedItems;
      state.newImage = newImage;
      state.newButton = newButton;
      state.itemCount = itemCount;
      if(ActiveOffcanvasParentTab !== undefined && ActiveOffcanvasParentTab !== null) state.ActiveOffcanvasParentTab = ActiveOffcanvasParentTab;
      if(ActiveOffcanvasChildTab !== undefined && ActiveOffcanvasChildTab !== null) state.ActiveOffcanvasChildTab = ActiveOffcanvasChildTab;
      if(ActiveOffcanvasChild2Tab !== undefined && ActiveOffcanvasChild2Tab !== null) state.ActiveOffcanvasChild2Tab = ActiveOffcanvasChild2Tab;
    },
    hideUpdateDataOffcanvas: (state, action) => {
      state.isEditVisible = false;
      state.NewBlockType = null;
      state.MainBlockProps = null;
      state.ContentBlockItem = null;
      state.ContentItems = null;
      state.ContentItemsArray = [];
      state.WhereToUpdateImage = null;
      state.CropperSizes = null;
      state.buttonDataObj = null;
      state.FlagsToCheckChanges = {
        'title': false,
        'titleCss': false,
        'subtitle': false,
        'subtitleCss': false,
        'description': false,
        'descriptionCss': false,
        'image': false,
        'imageCss': false,
        'button': false,
        'buttonCss': false,
        'category': false,
        'blockHeading': false,
        'blockHeadingCss': false,
        'blockDescription': false,
        'blockDescriptionCss': false,
        'css': false,
        'structureCss': false,
        'countInRow': false,
        'itemFilters': false,
        'numItems': false,
        'contents': false,
        'url': false,
        'container': false,
        'background': false,
        'overlay': false,
        'additionalDetails': false,
        'itemCss': false,
        'itemBackground': false,
        'itemOverlay': false
      };
      state.ActiveOffcanvasParentTab = 'Layers';
      state.ActiveOffcanvasChildTab = 'Content';
      state.ActiveOffcanvasChild2Tab = 'Layout';
    },
    setShowJSONModal: (state, action) => {
      const { JSONModal } = action.payload;
      state.JSONModal = JSONModal;
      console.log(JSONModal);
    },
    showHtmlEditor: (state, action) => {
      // console.log(state, action)
      const {
        isCodeEditorVisible
      } = action.payload
      state.isCodeEditorVisible = isCodeEditorVisible
    },
    hideHtmlEditor: (state) => {
      state.isCodeEditorVisible = false
    },
    showCategoryOffcanvas: (state, action) => {
      // console.log(state, action)
      const {
        isCategoryVisible
      } = action.payload
      state.isCategoryVisible = isCategoryVisible
    },
    hideCategoryOffcanvas: (state) => {
      state.isCategoryVisible = false
    },
    showDomainAdminOffcanvas: (state, action) => {
      // console.log(state, action)
      const {
        isDomainAdminVisible
      } = action.payload
      state.isDomainAdminVisible = isDomainAdminVisible
    },
    hideDomainAdminOffcanvas: (state) => {
      state.isDomainAdminVisible = false
    },
    setNewBlockType: (state, action) => {
      state.NewBlockType = action.payload;
    },
    setMainBlockProps: (state, action) => {
      state.MainBlockProps = action.payload;
    },
    setContentBlockItem: (state, action) => {
      state.ContentBlockItem = action.payload;
    },
    setContentItems: (state, action) => {
      state.ContentItems = action.payload;
    },
    setContentItemsArray: (state, action) => {
      state.ContentItemsArray = action.payload;
    },
    setWhereToUpdateImage(state, action) {
      state.WhereToUpdateImage = action.payload;
    },
    setCropperSizes(state, action) {
      state.CropperSizes = action.payload;
    },
    setFlagsToCheckChanges(state, action) {
      state.FlagsToCheckChanges = action.payload;
    },
    setActiveOffcanvasParentTab(state, action) {
      state.ActiveOffcanvasParentTab = action.payload;
    },
    setActiveOffcanvasChildTab(state, action) {
      state.ActiveOffcanvasChildTab = action.payload;
    },
    setActiveOffcanvasChild2Tab(state, action) {
      state.ActiveOffcanvasChild2Tab = action.payload;
    },
    setCloneBlockProps(state, action) {
      state.CloneBlockProps = action.payload;
    },
    setADDClonePageProps(state, action) {
      state.ADDClonePageProps = action.payload;
    },
    showOgOffcanvas: (state, action) => {
      const {
        isOgVisible
      } = action.payload
      state.isOgVisible = isOgVisible
    },
    hideOgOffcanvas: (state) => {
      state.isOgVisible = false
    },
    showFooterActionSheet: (state, action) => {
      const {
        isFooterActionVisible,
        actionSheetOf
      } = action.payload
      state.isFooterActionVisible = isFooterActionVisible
      state.actionSheetOf = actionSheetOf
    },
    hideFooterActionSheet: (state) => {
      state.isFooterActionVisible = false
    },
    setShowHtmlJsCssModal: (state, action) => {
      state.HtmlJsCssModal = action.payload;
    },
    setSideBarMenuShow: (state, action) => {
      state.sideBarMenuShow = action.payload
    }
  }
})


export const { 
  showOffcanvas, hideOffcanvas, showTemplateOffcanvas, hideTemplateOffcanvas, 
  showImageModal, hideImageModal, showButtonModal, hideButtonModal, 
  showPropertyOffcanvas, hidePropertyOffcanvas, showDeleteModal, hideDeleteModal, 
  showAddOffcanvas, hideAddOffcanvas, newImageData, addBlockModal, 
  hideAddBlockModal, addPageModal, hideAddPageModal, newButtonData, 
  setResponsiveIframe, showPageSetting, hidePageSetting, showEditShareBanner, 
  hideEditShareBanner, showSidebar, hideSidebar, showAppearance, 
  hideAppearance, showEditDns, hideEditDns, showAddModal, 
  hideAddModal, showPreviewModal, hidePreviewModal, showSwitchModal, 
  hideSwitchModal, showNavigationOffcanvas, hideNavigationOffcanvas, showSiteSettingOffcanvas, 
  hideSiteSettingOffcanvas, showEditOffcanvas, hideEditOffcanvas, 
  showDomainSettingOffcanvas, hideDomainSettingOffcanvas, showEmailSettingOffcanvas, 
  hideEmailSettingOffcanvas, showFormOffcanvas, hideFormOffcanvas, 
  showAddBlockOffcanvas, hideAddBlockOffcanvas, showSidebarToggle, 
  showPopupBlockOffcanvas, hidePopupBlockOffcanvas, showConfirmationModal, 
  hideConfirmationModal, showClonePageModal, hideClonePageModal, 
  showUpdateDataOffcanvas, hideUpdateDataOffcanvas, showHtmlEditor, 
  hideHtmlEditor, showCategoryOffcanvas, hideCategoryOffcanvas, 
  showDomainAdminOffcanvas, hideDomainAdminOffcanvas,
  setCropperSizes, setFlagsToCheckChanges,
  setReorderItems,showOgOffcanvas,hideOgOffcanvas,setShowJSONModal,
  setNewBlockType, setMainBlockProps, setContentBlockItem, setContentItems, setContentItemsArray, setWhereToUpdateImage, setActiveOffcanvasParentTab, setActiveOffcanvasChildTab, setActiveOffcanvasChild2Tab, setCloneBlockProps, setADDClonePageProps, showFooterActionSheet, hideFooterActionSheet, setShowHtmlJsCssModal, setSideBarMenuShow } = offcanvasSlice.actions;
  

export const getImageType = (BlockType) => {
  let imageType = 'other'
  if (BlockType === 'SM_HEAD' || BlockType === 'SM_FOOT') {
      imageType = 'logo'
  } else if (BlockType === 'SM_BRDC') {
      imageType = 'pageImage'
  } else if (BlockType === 'CN_CRSL') {
      imageType = 'slider'
  } else if (BlockType === 'CN_GLRY') {
      imageType = 'gallery'
  } else if (BlockType === 'Profile') {
    imageType = 'Profile'
  } else {
      imageType = 'other'
  }
  return imageType;
}

export default offcanvasSlice.reducer
