import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import axios from '../../../AxiosConfig'
import { useLocation, useNavigate } from "react-router-dom"
import jwt_decode from 'jwt-decode'
import { connect } from "react-redux"
import { hidePageSetting, showDeleteModal, showOgOffcanvas } from '../../../features/modal/offcanvasSlice'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import NotFound from '../../Pages/NotFound'
import Appearance from './Appearance'

const PageSetting = ({
    isPageSettingVisible,
    message = "seo",
    hidePageSetting,
    appearance,
    setWebsiteData,
    showAppToast,
    showDeleteModal,
    showOgOffcanvas,
    defaultActiveTab = "seo"
}) => {
    const [query, setQuery] = useState("")
    const [propValue, setValue] = useState('')
    const [ogImage, setOgImage] = useState('')
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [disablePropertyButton, setDisablePropertyButton] = useState(false)
    const [saveButton, showSaveButton] = useState(false)
    const [loader, showLoader] = useState(false)
    const [urlValidated, setUrlValidated] = useState(true)
    const [urlMessage, setUrlMessage] = useState('')
    
    let navigate = useNavigate()

    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    let pageId = ''
    let pageName = ''

    if(url === '' || url === undefined) {
        hidePageSetting();
        return (<></>)
    }


    const fetchWebsiteJSON = () => {
        const webDoc = localStorage.getItem('website')
        const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
          return res
        } else {
          return false
        }
    }
    const websiteJSON = fetchWebsiteJSON()
    // console.log(appearance)
    if (appearance !== undefined && !appearance.hasOwnProperty('pages') && websiteJSON && websiteJSON.appearance !== undefined) {
        const websiteDataAppearance = websiteJSON.appearance
        Object.keys(websiteDataAppearance.pages).map((value, key) => {
            if (websiteDataAppearance.pages[value].url === url) {
              pageId = value
              websiteDataAppearance.navigation.header.map((v, k) => {
                // console.log(v)
                if (v.hasOwnProperty('children')) {
                    if (v.children.length > 0) {
                        websiteDataAppearance.navigation.header[k].children.map((v1, k1) => {
                            if (v1.page === pageId) {
                              pageName = v1.title
                            }
                          })
                    } else {
                        if (v.page === pageId) {
                            pageName = v.title
                        }
                    }
                } else {
                  if (v.page === pageId) {
                    pageName = v.title
                  }
                }
              })
            }
        })
    } else {
        appearance.pages && Object.keys(appearance.pages).map((value, key) => {
            if (appearance.pages[value].url === url) {
              pageId = value
              appearance.navigation.header.map((v, k) => {
                if (v.hasOwnProperty('children')) {
                    if (v.children.length > 0) {
                        appearance.navigation.header[k].children.map((v1, k1) => {
                            if (v1.page === pageId) {
                            pageName = v1.title
                            }
                        })
                    } else {
                        if (v.page === pageId) {
                            pageName = v.title
                        }
                    }
                } else {
                  if (v.page === pageId) {
                    pageName = v.title
                  }
                }
              })
            }
        })
    }
        

    const setDefaultValues = () => {
        if (appearance !== undefined ) {
            console.log(appearance, url)
            if (appearance.hasOwnProperty('pages')) {
                if (url !== null && url !== '' && url !== undefined) {
                    setDefaultData(appearance.pages[pageId].properties)
                    setQuery(appearance.pages[pageId].properties.backgroundType)
                    setValue(appearance.pages[pageId].properties.backgroundValue)
                }
            } else {
                return <div><NotFound /></div>
            }
        } else {
            console.log("SOMETHING WENT WRONG")
        }
    }

    const setDefaultData = (data) => {
        // console.log(data, pageName)
        // document.getElementById('ogPageTitle').value = data.ogTitle
        // document.getElementById('ogPageDescription').value = data.ogDescription
        // setOgImage(data.ogImage)

        document.getElementById('seoPageTitle').value = data.title
        document.getElementById('seoPageKeywords').value = data.metaKeywords
        document.getElementById('seoPageDescription').value = data.metaDescription

        document.getElementById('pageName').value = pageName
        document.getElementById('pageUrl').value = appearance.pages[pageId].url
    }

    const collectData = () => {
        // console.log('ENTERED PAGE SETTINGS OFFCANVAS')
        setDefaultValues()
    }

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    
    const submitMetaData = (e) => {
        e.preventDefault()
        setDisableSubmitButton(true)
        const pagePropertyData = {
            "pageName": document.getElementById('pageName').value,
            "pageUrl": (document.getElementById('pageUrl').value === undefined ? appearance.pages[pageId].url : document.getElementById('pageUrl').value),
            "title": document.getElementById('seoPageTitle').value,
            "metaKeywords": document.getElementById('seoPageKeywords').value,
            "metaDescription": document.getElementById('seoPageDescription').value
        }
        // console.log(pagePropertyData)
        axios.put('/page/'+ selectedDomain + '/'+ pageId, pagePropertyData).then(res=>{

            if (res.data.message === 'Success') {
                setDisableSubmitButton(false)
                hidePageSetting()
                setWebsiteData(res.data.data)
                showAppToast({ toastMessage: 'UPDATED SUCCESSFULLY', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                navigate('/build?url=' + pagePropertyData.pageUrl)
            } else {
                setDisableSubmitButton(false)
                hidePageSetting()
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
            }
        })

        
        // axios.put('/page/'+ selectedDomain + '/'+ url, {'pageName': newPageName, 'pageUrl': newPageUrl}).then(res=>{
        //   if (res.data.message === 'Success') {
        //     setDisablePropertyButton(false)
        //     setWebsiteData(res.data.data)
        //     showAppToast({ toastMessage: 'UPDATED SUCCESSFULLY', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
        //   } else {
        //     setDisablePropertyButton(false)
        //     showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
        //   }
        // })
    }

    const updatePageBackground = () => {
        // console.log(query, propValue)
        setDisablePropertyButton(true)
        axios.put('/page/'+ selectedDomain + '/'+ pageId, { 'backgroundValue': (query === 'none') ? '' : propValue , 'backgroundType': query}).then(res=>{

            if (res.data.message === 'Success') {
                setDisablePropertyButton(false)
                hidePageSetting()
                setWebsiteData(res.data.data)
                showAppToast({ toastMessage: 'UPDATED SUCCESSFULLY', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
            } else {
                setDisablePropertyButton(false)
                hidePageSetting()
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
            }
        })
    }

    const addFormSeoData = () => {
        showSaveButton(true)
        showLoader(true)
    }

    const addSeoData = () => {
        showSaveButton(false)
        showLoader(true)
        setTimeout(function() {
            showSaveButton(false)
            showLoader(false)
        }, 5000);
    }

    const handleDeleteSeoData = (e) => {
        e.stopPropagation()
        showDeleteModal({
            isDeleteVisible: true,
            deleteMessage: 'This data cant be recovered, once deleted',
            // triggerMessage: 'smartContent',
            // deleteID: arr,
            // deleteItemID: index,
            // blockID: blockID
        })
    }

    const validateUrl = (value) => {
        axios.post('https://api.webweb.ai/appearance/checkUniqueUrl/' + selectedDomain, {url:value}).then(res=>{
            // console.log(res)
            if (res.data.message === 'Success') {
                if (res.data.data === 'Url Accepted') {
                    setUrlMessage('Url Accepted')
                    setUrlValidated(true)
                    setDisableSubmitButton(false)
                } else if (res.data.data === 'Url Not Accepted') {
                    setUrlMessage('Url Not Accepted')
                    setUrlValidated(false)
                    setDisableSubmitButton(true)
                } else {
                    setUrlMessage('Url Not Accepted')
                    setUrlValidated(false)
                    setDisableSubmitButton(true)
                }
            } else {
                console.log('URL API ISSUE')
            }
        })
        // if (value.length > 0) {
        //     setUrlValidated(true)
        //     setDisableSubmitButton(false)
        // } else {
        //     setUrlValidated(false)
        //     setDisableSubmitButton(true)
        // }
    }

    const handleOgOffcanvas = () => {
        hidePageSetting()
        showOgOffcanvas({isOgVisible: true})
    }

    return (
        <Offcanvas show={isPageSettingVisible} placement={'end'} backdrop={true} scroll={true} onEntered={collectData} id="appCapsule" style={{'paddingBottom':'0px'}}>
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Page Settings</strong>
                    </div>
                    <span className="close-sidebar-button" style={{cursor:'pointer'}} onClick={() => {hidePageSetting()}}>
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
                <div className="section full mb-2">
                    <div className="custom-extraHeader position-absolute p-0">
                        <ul className="nav nav-tabs lined" role="tablist">
                            <li className="nav-item">
                                <a className={(defaultActiveTab !== 'seo') ? 'nav-link active' : 'nav-link' } data-bs-toggle="tab" href="#custom" role="tab" style={{height:'46px'}}>
                                    Style
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={(defaultActiveTab === 'seo') ? 'nav-link active' : 'nav-link' } data-bs-toggle="tab" href="#seo" role="tab" style={{height:'46px'}}>
                                    Meta Data
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" style={{marginTop:'30px'}}>
                        <div className={(defaultActiveTab !== 'seo') ? 'tab-pane fade show active' : 'tab-pane fade'} id="custom" role="tabpanel">
                            <div className="section full">
                                
                                {/* <div className="accordion" id="accordionExample1">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className='accordion-button custom-line' type="button" data-bs-toggle="collapse" data-bs-target='#accordionBackgroundImage'>
                                                <ion-icon name="document-attach-outline"></ion-icon>
                                                Style
                                            </button>
                                        </h2>
                                        <div id='accordionBackgroundImage' className='accordion-collapse collapse show' data-bs-parent="#accordionExample1">
                                            <div className="accordion-body">
                                                <div className="text-end">
                                                </div>
                                                <div className="row p-1">
                                                    <div className="col-12 col-md-12 mb-2">
                                                        <div className="form-check form-check-inline me-1">
                                                            <input className="form-check-input" type="radio" name="radioInline" id="radioInlineDefault1" onChange={(e) => setQuery(e.target.value)} value={'color'} checked={(query === 'color' || query === '') ? true : false} />
                                                            <label className="form-check-label" htmlFor="radioInlineDefault1"> Color </label>
                                                        </div>

                                                        <div className="form-check form-check-inline me-1">
                                                            <input className="form-check-input" type="radio" name="radioInline" id="radioInlineDefault2" onChange={(e) => setQuery(e.target.value)} value={'image'} checked={(query === 'image') ? true : false} />
                                                            <label className="form-check-label" htmlFor="radioInlineDefault2"> Image</label>
                                                        </div>

                                                        <div className="form-check form-check-inline me-1">
                                                            <input className="form-check-input" type="radio" name="radioInline" id="radioInlineDefault3" onChange={(e) => setQuery(e.target.value)} value={'none'} checked={(query === 'none') ? true : false} />
                                                            <label className="form-check-label" htmlFor="radioInlineDefault3"> None</label>
                                                        </div>
                                                    </div>

                                                    {query === 'image' &&
                                                        <div className="col-4 col-md-4 mb-2">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <ion-icon name="create-outline" className="position-absolute"></ion-icon>
                                                                <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w-100 border" />
                                                            </div>
                                                        </div>
                                                    }
                                                    {query === 'color' &&
                                                        <div className="form-group boxed">
                                                            <div className="input-wrapper">
                                                                <input type="color" className="form-control form-control-sm" id="title" value={propValue} autoComplete="off" onChange={(e) => setValue(e.target.value)} />
                                                                <i className="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <Appearance isAppearanceVisibleShow={isPageSettingVisible} />
                            </div>
                            {/* <div className="sidebar-buttons webweb-sidebar-buttons">
                                <button className="btn btn-block btn-success" style={{borderRadius: "initial"}} onClick={() => {updatePageBackground()}} disabled={disablePropertyButton}>
                                    <ion-icon name="checkmark-outline"></ion-icon> Update
                                </button>
                            </div> */}
                        </div>
                        <div className={(defaultActiveTab === 'seo') ? 'tab-pane fade show active' : 'tab-pane fade'} id="seo" role="tabpanel">
                            {/* <div className="section" id="accordionExample1"> */}
                                <form onSubmit={submitMetaData}>
                                    <div className="section full mt-1">
                                        <div className="wide-block pb-2 pt-2">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="pageName">Page Name</label>
                                                    <input type="text" className="form-control form-control-sm" id="pageName" placeholder="Enter page name"
                                                        autoComplete="off" />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>

                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="pageUrl">
                                                        <div className="row">
                                                            <div className="col-6 col-md-6 d-flex align-items-center">
                                                                Page Url
                                                            </div>
                                                            <div className="col-6 col-md-6 text-end">
                                                                {urlValidated &&
                                                                    <span className="text-success d-flex align-items-center justify-content-end">
                                                                        <ion-icon name="checkmark-circle-outline" className="fs-5"></ion-icon>
                                                                        {urlMessage}
                                                                    </span>
                                                                }
                                                                {!urlValidated &&
                                                                    <span className="text-danger d-flex align-items-center justify-content-end">
                                                                        <ion-icon name="close-circle-outline" className="fs-5"></ion-icon>
                                                                        {urlMessage}
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <h4 className="mb-0 mt-2 text-secondary">https://{selectedDomain}/</h4>
                                                    <input type="text" className="form-control form-control-sm" id="pageUrl" placeholder="Enter page url"
                                                        autoComplete="off" onChange={(e) => {validateUrl(e.target.value)}}/>
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section full mt-1">
                                        <div className="section-title">SEO Data</div>
                                        <div className="wide-block pb-2 pt-2">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="seoPageTitle">SEO Title</label>
                                                    <input type="text" className="form-control form-control-sm" id="seoPageTitle" placeholder="Enter page title"
                                                        autoComplete="off" />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>

                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="seoPageDescription">SEO Description</label>
                                                    <textarea id="seoPageDescription" rows="1" className="form-control form-control-sm" placeholder="Enter page description"></textarea>
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>

                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label" htmlFor="seoPageKeywords">SEO Keywords</label>
                                                    <textarea id="seoPageKeywords" rows="1" className="form-control form-control-sm" placeholder="Comma ',' separated"></textarea>
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={handleOgOffcanvas}>Manage Open Graph Tags</button>
                                    </div>
                                    {/* <div className="section full mt-1">
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="ogPageTitle">OG Title</label>
                                                <input type="text" className="form-control form-control-sm" id="ogPageTitle" placeholder="Enter page title"
                                                    autoComplete="off" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="ogPageDescription">OG Description</label>
                                                <textarea id="ogPageDescription" rows="1" className="form-control form-control-sm" placeholder="Enter page description"></textarea>
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="form-label mb-0" htmlFor="pageKeywords">OG Image</label>
                                            </div>
                                        </div>
                                        {ogImage &&
                                            <img src={ogImage} alt="Brand" className="img-fluid w-25" />
                                        }
                                        {!ogImage &&
                                            <div className="custom-file-upload" id="fileUpload1">
                                                <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg" />
                                                <label htmlFor="fileuploadInput" style={{height: '120px'}}>
                                                    <span>
                                                        <strong>
                                                            <ion-icon name="cloud-upload-outline"></ion-icon>
                                                            <i>Tap to Upload</i>
                                                        </strong>
                                                    </span>
                                                </label>
                                            </div>
                                        }
                                    </div> */}
                                    {/* <div className="row p-2 pb-1">
                                        <div className="col-6 col-md-6">
                                            <div className="section-title" style={{margin: '0px -16px'}}>Open Graph Data</div>
                                        </div>
                                        {!loader &&
                                            <div className="col-6 col-md-6 d-flex align-items-center justify-content-end" onClick={addFormSeoData}>
                                                <span className="badge badge-primary">+ Add</span>
                                            </div>
                                        }
                                        {saveButton &&
                                            <div className="col-6 col-md-6 d-flex align-items-center justify-content-end" onClick={addSeoData}>
                                                <span className="badge badge-success">Save</span>
                                            </div>
                                        }
                                        {!saveButton &&
                                            <>
                                                {loader &&
                                                    <div className="col-6 col-md-6 d-flex align-items-center justify-content-end">
                                                        <span className="badge badge-success" style={{fontSize: '10px'}}>
                                                            <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true" style={{height: '10px', width: '10px'}}></span>
                                                            Saving...
                                                        </span>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div> */}
                                    {/* {!loader &&
                                        <>
                                            {!saveButton &&
                                                <ul className="listview image-listview media mb-2 p-2" style={{margin: '0px -16px'}}>
                                                    <li>
                                                        <a href="#!" className="item">
                                                            <div className="imageWrapper">
                                                                <img src="assets/img/sample/photo/1.jpg" alt="image" className="imaged w64" />
                                                            </div>
                                                            <div className="in">
                                                                <div>
                                                                    Birds
                                                                    <div className="text-muted">62 photos</div>
                                                                </div>
                                                                <ion-icon name="trash-outline" className="fs-6 text-danger" onClick={(e) => {handleDeleteSeoData(e)}}></ion-icon>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="item">
                                                            <div className="imageWrapper">
                                                                <img src="assets/img/sample/photo/2.jpg" alt="image" className="imaged w64" />
                                                            </div>
                                                            <div className="in">
                                                                <div>
                                                                    Street Photos
                                                                    <div className="text-muted">15 photos</div>
                                                                </div>
                                                                <ion-icon name="trash-outline" className="fs-6 text-danger"></ion-icon>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="item">
                                                            <div className="imageWrapper">
                                                                <img src="assets/img/sample/photo/3.jpg" alt="image" className="imaged w64" />
                                                            </div>
                                                            <div className="in">
                                                                <div>
                                                                    Dogs
                                                                    <div className="text-muted">97 photos</div>
                                                                </div>
                                                                <ion-icon name="trash-outline" className="fs-6 text-danger"></ion-icon>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            }
                                        </>
                                    } */}
                                    {saveButton &&
                                        <>
                                            <div className="wide-block pb-2 pt-2">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label className="form-label" htmlFor="ogPageTitle">OG Title</label>
                                                        <input type="text" className="form-control form-control-sm" id="ogPageTitle" placeholder="Enter page title"
                                                            autoComplete="off" />
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>

                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label className="form-label" htmlFor="ogPageDescription">OG Description</label>
                                                        <textarea id="ogPageDescription" rows="1" className="form-control form-control-sm" placeholder="Enter page description"></textarea>
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>

                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <label className="form-label mb-0" htmlFor="pageKeywords">OG Image</label>
                                                    </div>
                                                </div>
                                                {ogImage &&
                                                    <img src={ogImage} alt="Brand" className="img-fluid w-25" />
                                                }
                                                {!ogImage &&
                                                    <div className="custom-file-upload" id="fileUpload1">
                                                        <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg" />
                                                        <label htmlFor="fileuploadInput" style={{height: '120px'}}>
                                                            <span>
                                                                <strong>
                                                                    <ion-icon name="cloud-upload-outline"></ion-icon>
                                                                    <i>Tap to Upload</i>
                                                                </strong>
                                                            </span>
                                                        </label>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    <div className="sidebar-buttons webweb-sidebar-buttons">
                                        <button className="btn btn-block btn-success" type="submit" style={{borderRadius: "initial"}} disabled={disableSubmitButton}>
                                            <ion-icon name="checkmark-outline"></ion-icon> Update
                                        </button>
                                    </div>
                                </form>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isPageSettingVisible,
        defaultActiveTab
    } = offcanvas
    const {
        appearance
    } = websiteData
    return {
        isPageSettingVisible,
        defaultActiveTab,
        appearance
    }
}
  
const mapDispatchToProps = {
    hidePageSetting,
    setWebsiteData,
    showAppToast,
    showDeleteModal,
    showOgOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSetting)
