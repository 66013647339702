import React, { useState } from 'react';
import './FileCard.css'
import { Button } from 'react-bootstrap';

function getFileNameFromUrl(url) {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const fileName = pathname.split('/').pop();
    return fileName;
}

function getFileType(link) {
    const fileExtension = link.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
        return 'PDF';
    } else if (['png', 'jpeg', 'jpg'].includes(fileExtension)) {
        return 'IMAGE';
    } else if (fileExtension === 'mp4') {
        return 'VIDEO';
    } else {
        return 'UNKNOWN'; // Or handle other file types as needed
    }
}


function FileCard({ data, selectedDomain, aliasDomainValue, userRole, replaceSpacesWithPercent20 }) {

    const [isCopied, setIsCopied] = useState(false);
    
    function copyToClipboard(data){
        console.log(selectedDomain, aliasDomainValue)
        if(getFileType(data?.URL) === 'PDF') {
            navigator.clipboard.writeText(`https://${aliasDomainValue}/mediaViewer?path=${replaceSpacesWithPercent20(data.FileName)}`);
        } else {
            navigator.clipboard.writeText(replaceSpacesWithPercent20(data.URL));
        }
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    }

    function ToOpenLink(data) {
        if(getFileType(data?.URL) === 'PDF') {
            window.open(`https://${aliasDomainValue}/mediaViewer?path=${data.FileName}`, "_blank")
        } else {
            window.open(data.URL, "_blank")
        }
    }

    return (
        <>
            <div className='FileCard'>
                {(getFileType(data?.URL) === 'PDF') &&
                    <img src='assets/img/icon/pdf.svg' className='pdf' alt='pdf' />
                }
                {(getFileType(data?.URL) === 'VIDEO') &&
                    <img src='assets/img/icon/play-movie-film-streaming-player-video-svgrepo-com.svg' className='pdf' alt='pdf' />
                }
                {(getFileType(data?.URL) === 'IMAGE') &&
                    <img src={data?.URL} alt='image' />
                }
                <div className='FileInfo'>
                    <p className='FileName'>
                        {decodeURIComponent(data.name)}
                        {/* {getFileNameFromUrl(data.URL)} */}
                    </p>
                    {/* <span className='FileSize'>file size | 12-Jul-2024 11:12</span> */}
                </div>
                <div className='d-flex gap-1 mt-1 flex-wrap justify-content-center'>
                    <Button variant='primary' size='sm' onClick={() => copyToClipboard(data)}>{isCopied ? 'Copied' : 'Copy Link'}</Button>
                    <Button variant='outline-primary' size='sm' onClick={() => ToOpenLink(data)}>Open Link</Button>
                    {
                        userRole === 'Admin' &&  <Button variant='secondary' size='sm' onClick={() => window.open(data.URL, "_blank")}>Original Link</Button>
                    }
                </div>
            </div>
        </>
    )
}


export default FileCard