import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideConfirmationModal } from '../../../features/modal/offcanvasSlice'

const ConfirmationModal = ({
    isConfirmationVisibleModal,
    hideConfirmationModal,
    confirmationMessage
}) => {
    
    return (
        <Modal show={isConfirmationVisibleModal} class="dialogbox" id="ConfirmationModal" data-bs-backdrop="static" tabindex="-1"
            role="dialog" centered>
            {/* <Modal.Dialog class="modal-dialog" role="document"> */}
                <Modal.Header class="modal-header">
                    <h3 class="modal-title">
                        CONFIRM
                    </h3>
                </Modal.Header>
                <Modal.Body class="modal-body">
                    {confirmationMessage}
                </Modal.Body>
                <Modal.Footer class="modal-footer">
                    <div class="btn-inline">
                        <span class="btn btn-text-secondary" onClick={hideConfirmationModal}>
                            <ion-icon name="close-outline"></ion-icon>
                            CANCEL
                        </span>
                        <span class="btn btn-text-danger">
                            <ion-icon name="trash-outline"></ion-icon>
                            CONFIRM
                        </span>
                    </div>
                </Modal.Footer>
            {/* </Modal.Dialog> */}
        </Modal>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isConfirmationVisibleModal,
        confirmationMessage
    } = offcanvas
    return {
        isConfirmationVisibleModal,
        confirmationMessage
    }
  }
  
  const mapDispatchToProps = {
    hideConfirmationModal
  }

export default connect(mapStateToProps,mapDispatchToProps)(ConfirmationModal)
