import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  appearance: {},
  blocks: {},
  blockRules: [],
  contents: {},
  data: {},
  encrypt: {},
  forms: {},
  fonts: [],
  templates: [],
  cropperConfig: {},
  CompleteJSON: null,
  loading: false,
  newBlockList: null
}

export const websiteDataSlice = createSlice({
  name: 'websiteData',
  initialState,
  reducers: {
    setWebsiteData: (state, action) => {
      const {
        appearance,
        blocks,
        contents,
        data,
        encrypt,
        forms
      } = action.payload
      state.appearance = appearance
      state.blocks = blocks
      state.contents = contents
      state.data = data
      state.encrypt = encrypt
      state.forms = forms
      state.CompleteJSON = action.payload;
    },
    setBlockRules: (state, action) => {
      const {
        blockRules
      } = action.payload
      state.blockRules = blockRules
    },
    setInfoData: (state, action) => {
      const {
        fonts,
        templates,
        cropperConfig
      } = action.payload
      state.fonts = fonts
      state.templates = templates
      state.cropperConfig = cropperConfig
    },
    updateImageData: (state, action) => {
      let itemID = action.payload.itemID
      const {
        newImageData,
        blockID,
        blockItemIndex,
      } = action.payload
      const blockType = blockID.split('_')[0]
      if (blockType === 'CN') {
        if (blockID && blockItemIndex >= 0 && itemID >= 0) {
          const contentBlockId = current(state.blocks)[blockID].items.contents.split('.')[1]
          let tempContents = JSON.parse(JSON.stringify(current(state.contents)))
          let tempBlock = JSON.parse(JSON.stringify(tempContents[contentBlockId]))
          tempBlock.items[itemID].image = newImageData
          tempContents[contentBlockId] = tempBlock
          state.contents = tempContents
        }
      } else if (blockType === 'SM') {
        itemID = 0
      }

    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setNewBlockList: (state, action) => {
      state.newBlockList = action.payload
    }
  }
})

export const cssConverter = (cssObject, defaultCss) => {
  let convertedObject = {};
  // console.log(cssObject);
  if (cssObject !== null && cssObject !== undefined && cssObject !== "[]" && typeof(cssObject) !== "array") {
    Object.keys(cssObject).map((key, value) => {
      if (key.includes('-')) {
        const characterPosition = key.indexOf('-')
        const getCharacter = key.charAt(characterPosition + 1).toUpperCase()
        const a1 = key.substring(0, characterPosition) + getCharacter + key.substring(characterPosition + 2, key.length)
        convertedObject[a1] = cssObject[key]
        // console.log(convertedObject)
      } else {
        convertedObject[key] = cssObject[key]
      }
    })
  }
  if (defaultCss !== undefined && defaultCss !== null) {
    Object.keys(defaultCss).map((key, value) => {
      convertedObject[key] = defaultCss[key]
    })
  }
  return convertedObject
}

export const { setWebsiteData, setBlockRules, setInfoData, updateImageData, setLoading, setNewBlockList } = websiteDataSlice.actions;

export function formatBytes(bytes) {
  if (bytes === 0) return '0 MB';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const value = parseFloat((bytes / Math.pow(1024, i)).toFixed(1));

  return `${value} ${sizes[i]}`;
}

export default websiteDataSlice.reducer;
