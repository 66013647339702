import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
    showUploadFilesModal: false,
    FileList: null,
}

export const fileUploadSlice = createSlice({
  name: 'FileUploadModals',
  initialState,
  reducers: {
    setShowUploadFilesModal: (state, action ) => {
        state.showUploadFilesModal = action.payload;
    },
    setFileList: (state, action) => {
        state.FileList = action.payload;
    }
  }
})

export const { setShowUploadFilesModal, setFileList } = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
