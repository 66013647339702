import React from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { showOffcanvas, showButtonModal, newButtonData, showSwitchModal } from '../../features/modal/offcanvasSlice'
import { setWebsiteData } from '../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'

const SM_HEAD_FIX1 = ({
    items,
    websiteDataAppearance = { properties: {} },
    websiteDataData,
    blockID,
    showOffcanvas,
    setWebsiteData,
    showAppToast,
    showButtonModal,
    newButtonData,
    showSwitchModal
}) => {
    let navigate = useNavigate()
    let { properties: websiteDataAppearanceProperties, navigation } = websiteDataAppearance
    // console.log(navigation)

    const handleLogoUpdate = () => {
        // showOffcanvas({ isVisible:true, items:items, properties:websiteDataAppearance.properties, blockType: 'SM', blockID:blockID, msg:''})
        navigate(
          '/build?url=' + websiteDataAppearance.properties.defaultPage
        )
    }

    const handlePageSwitch = () => {
        showSwitchModal({isSwitchModalVisible: true, switchModalMessage : 'changePage', blockID: blockID})
    }

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false

    const switchPage = (pageID) => {
        if(tokenData.userInfo.RoleID === 'Moderator') {
          let categoryPages = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('CategoryPages')))))
        //   console.log(categoryPages)
          for (let i = 0; i < categoryPages.length; i++) {
            if(categoryPages[i].PageID == pageID) {
              let categoryPageJson = btoa(unescape(encodeURIComponent(categoryPages[i].StructureConfig)))
              categoryPages[i].StructureConfig = JSON.parse(categoryPages[i].StructureConfig)
              localStorage.setItem('website', categoryPageJson)
              localStorage.setItem('selectedPageID', categoryPages[i].ID)
              setWebsiteData(categoryPages[i].StructureConfig)
              navigate('/build?url=' + categoryPages[i].StructureConfig.appearance.pages['SM_PAGE1'].url)
            }
          }
        } else {
          if (websiteDataAppearance !== undefined) {
            {Object.keys(websiteDataAppearance.pages).map(key =>{
              if (pageID === key) {
                navigate('/build?url=' + websiteDataAppearance.pages[key].url)
              }
            })}
          }
        }
    }
    return (
        <div>
            <div className="appHeader position-relative shadow-none border" style={{ top: 'auto', zIndex: 0, minHeight: '90px'}}>
                <div className="left" style={{height: '100px'}}>
                    <div className="headerButton dropdown p-0">
                        {items[0].image.length > 0 &&
                        <>
                            <img
                            src={items[0].image[0].src}
                            alt={items[0].image[0].alt}
                            className="w-100"
                            style={{ maxWidth: '90px', cursor: 'pointer'}}
                            onClick={handleLogoUpdate}
                            />
                            {/* <span
                            className="badge badge-warning badge-empty"
                            style={{
                                position: 'absolute',
                                top: '70%',
                                right: '17%',
                                cursor: 'pointer'
                            }}
                            data-bs-toggle="dropdown"
                            >
                            <ion-icon
                                name="chevron-down-outline"
                                style={{ fontSize: '8px', position: 'absolute', left: 0 }}
                            ></ion-icon>
                            </span> */}
                        </>
                        }
                        {items[0].image.length === 0 &&
                        <>
                            <h3 className="mb-0" onClick={handleLogoUpdate} style={{cursor: 'pointer'}}>
                            {websiteDataData.BusinessName}
                            </h3>
                            {/* <span
                            className="badge badge-warning badge-empty"
                            style={{
                            position: 'absolute',
                            top: '55%',
                            right: '17%',
                            cursor: 'pointer'
                            }}
                            data-bs-toggle="dropdown"
                            >
                            <ion-icon
                                name="chevron-down-outline"
                                style={{ fontSize: '8px', position: 'absolute', left: 0 }}
                            ></ion-icon>
                            </span> */}
                        </>
                        }
                        
                        {/* <div className="webweb-dropdown-menu dropdown-menu">
                        <a className="dropdown-item" href="#!" onClick={handleLogoUpdate}>
                            <ion-icon
                            name="create-outline"
                            className="text-info"
                            ></ion-icon>
                            Update Logo
                        </a>
                        <a className="dropdown-item" href="#!" onClick={handleRemoveLogo}>
                            <ion-icon name="trash-outline" className="text-info"></ion-icon>
                            Remove Logo
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#!">
                            <ion-icon
                            name="add-outline"
                            className="text-success"
                            ></ion-icon>{' '}
                            Make Bigger
                        </a>
                        <a className="dropdown-item" href="#!">
                            <ion-icon
                            name="remove-outline"
                            className="text-danger"
                            ></ion-icon>{' '}
                            Make Smaller
                        </a>
                        </div> */}
                    </div>
                </div>
                <div className="center" style={{height: '100px'}}>
                </div>
                <div className="right" style={{height: '100px', marginRight: '25px', cursor: 'pointer'}}>
                    <ion-icon name="menu-outline" className="border rounded text-muted" data-bs-toggle="modal" data-bs-target="#ModalBasic"></ion-icon>
                </div>
            </div>

            <div className="modal fade modalbox" id="ModalBasic" data-bs-backdrop="static" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{borderBottom: 'none'}}>
                            <h5 className="modal-title"><div className="headerButton dropdown p-0">
                                {items[0].image.length > 0 &&
                                <>
                                    <img
                                    src={items[0].image[0].src}
                                    alt={items[0].image[0].alt}
                                    className="w-100"
                                    style={{ maxWidth: '120px', cursor: 'pointer'}}
                                    onClick={handleLogoUpdate}
                                    />
                                    {/* <span
                                    className="badge badge-warning badge-empty"
                                    style={{
                                        position: 'absolute',
                                        top: '70%',
                                        right: '17%',
                                        cursor: 'pointer'
                                    }}
                                    data-bs-toggle="dropdown"
                                    >
                                    <ion-icon
                                        name="chevron-down-outline"
                                        style={{ fontSize: '8px', position: 'absolute', left: 0 }}
                                    ></ion-icon>
                                    </span> */}
                                </>
                                }
                                {items[0].image.length === 0 &&
                                <>
                                    <h3 className="mb-0" onClick={handleLogoUpdate} style={{cursor: 'pointer'}}>
                                    {websiteDataData.BusinessName}
                                    </h3>
                                    {/* <span
                                    className="badge badge-warning badge-empty"
                                    style={{
                                    position: 'absolute',
                                    top: '55%',
                                    right: '17%',
                                    cursor: 'pointer'
                                    }}
                                    data-bs-toggle="dropdown"
                                    >
                                    <ion-icon
                                        name="chevron-down-outline"
                                        style={{ fontSize: '8px', position: 'absolute', left: 0 }}
                                    ></ion-icon>
                                    </span> */}
                                </>
                                }
                                
                                {/* <div className="webweb-dropdown-menu dropdown-menu">
                                <a className="dropdown-item" href="#!" onClick={handleLogoUpdate}>
                                    <ion-icon
                                    name="create-outline"
                                    className="text-info"
                                    ></ion-icon>
                                    Update Logo
                                </a>
                                <a className="dropdown-item" href="#!" onClick={handleRemoveLogo}>
                                    <ion-icon name="trash-outline" className="text-info"></ion-icon>
                                    Remove Logo
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#!">
                                    <ion-icon
                                    name="add-outline"
                                    className="text-success"
                                    ></ion-icon>{' '}
                                    Make Bigger
                                </a>
                                <a className="dropdown-item" href="#!">
                                    <ion-icon
                                    name="remove-outline"
                                    className="text-danger"
                                    ></ion-icon>{' '}
                                    Make Smaller
                                </a>
                                </div> */}
                            </div></h5>
                            <a href="!#" className="text-muted" data-bs-dismiss="modal"><ion-icon name="close-outline" className="fs-3"></ion-icon></a>
                        </div>
                        <div className="modal-body">
                            <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center',flexFlow: 'column', position: 'absolute',top: 0,left: 0,bottom: 0,right: 0}}>
                                {navigation.header.map((value, key) => {
                                    return <div> {(value.hasOwnProperty('children') && value.children.length !== 0) &&
                                        <div className="dropdown d-flex justify-content-center" onClick={handlePageSwitch}>
                                            <div
                                                className="headerButton dropdown-toggle justify-content-start flex-column align-items-start"
                                                data-bs-toggle="dropdown"
                                                style={{ cursor: 'pointer' }}
                                            >
                                            <a className="dropdown-item btn btn-text-dark d-flex align-items-center pe-1" href="javascript:void(0)" onClick={() => {switchPage(value['page'])}} style={{padding: '3px 12px', fontSize: '18px'}}>
                                                {value['title']}&nbsp;<ion-icon name="chevron-down-outline" className="me-0" style={{marginTop: '4px', fontSize: '12px'}}></ion-icon>
                                            </a>
                                            </div>
                                        </div>
                                        }

                                        {(value.hasOwnProperty('children') && value.children.length === 0) &&
                                        <a className="dropdown-item btn btn-text-dark" href="javascript:void(0)" onClick={() => {switchPage(value['page'])}} style={{padding: '3px 12px', fontSize: '18px'}}>
                                            {value['title']}
                                        </a>
                                        }

                                        {(!value.hasOwnProperty('children')) &&
                                        <a className="dropdown-item btn btn-text-dark" href="javascript:void(0)" onClick={() => {switchPage(value['page'])}} style={{padding: '3px 12px'}}>
                                            {value['title']}
                                        </a>
                                        }
                                    </div>
                                })}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ websiteData }) => {
    const { 
      appearance: websiteDataAppearance,
      data: websiteDataData
    } = websiteData
  
    return {
      websiteDataAppearance,
      websiteDataData
    }
  }
  
  const mapDispatchToProps = { 
    showOffcanvas,
    setWebsiteData,
    showAppToast,
    showButtonModal,
    newButtonData,
    showSwitchModal
   }

export default connect(mapStateToProps, mapDispatchToProps)(SM_HEAD_FIX1)